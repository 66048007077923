import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from 'components/Breadcrumbs'
import styles from './styles.module.css'

function HeroText({ breadcrumbs, desc, title }) {
  return (
    <div className={styles.my_project_header}>
      <div className={styles.container}>
        <Breadcrumbs data={breadcrumbs} />
        <h2 className={styles.my_project_header_title}>{title}</h2>
        <p className={styles.my_project_header_desc}>{desc}</p>
      </div>
    </div>
  )
}

HeroText.propTypes = {
  breadcrumbs: PropTypes.arrayOf().isRequired,
  desc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default memo(HeroText)
