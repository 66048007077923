import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Button, FormError, Input, Checkbox, FormBox, 
} from 'components'
import routesNames from 'routes/routesNames'
import shouldShowError from 'utils/helpers/form/shouldShowError'
import shouldDisableButton from 'utils/helpers/form/shouldDisableButton'
import { ReactComponent as IllustraCreateAccount } from 'assets/img/illustra-create-account.svg'
import { ReactComponent as ArrowRightMini } from 'assets/img/arrow-right-mini.svg'

import styles from './styles.module.css'

function CreateAccountTemplate({ formik, hasErrors }) {
  return (
    <div className={styles.page}>
      <div className={styles.illustration}>
        <IllustraCreateAccount />
        <a className={styles.ref} href="https://storyset.com/people">
          People illustrations by Storyset
        </a>
      </div>
      <main className={styles.main}>
        <div className={styles.container}>
          <FormError
            className={styles.alert}
            hasErrors={hasErrors.show}
            message={hasErrors.message}
          />
          <FormBox
            title="Vamos começar!"
            desc="Informe alguns dados para começar a desbravar novas possiblidades"
          >
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.form_half}>
                <Input
                  name="full_name"
                  label="Nome completo"
                  onChange={formik.handleChange}
                  placeholder="Informe seu nome completo"
                  type="text"
                  error={shouldShowError('full_name', formik)}
                  value={formik.values.full_name}
                  maxLength="60"
                  required
                />
                <Input
                  name="cpf"
                  label="CPF"
                  onChange={formik.handleChange}
                  placeholder="000.000.000-00"
                  type="text"
                  mask="999.999.999-99"
                  error={shouldShowError('cpf', formik)}
                  value={formik.values.cpf}
                  required
                />
              </div>
              <div className={styles.form_half}>
                <Input
                  name="email"
                  label="E-mail"
                  onChange={formik.handleChange}
                  placeholder="email@email.com"
                  type="email"
                  error={shouldShowError('email', formik)}
                  value={formik.values.email}
                  required
                />
                <Input
                  name="email_confirmation"
                  label="Confirme o e-mail"
                  onChange={formik.handleChange}
                  placeholder="email@email.com"
                  type="email"
                  error={shouldShowError('email_confirmation', formik)}
                  value={formik.values.email_confirmation}
                  required
                />
              </div>
              <div className={styles.form_half}>
                <Input
                  name="password"
                  label="Senha"
                  onChange={formik.handleChange}
                  placeholder="Mínimo de 8 digitos"
                  type="password"
                  error={shouldShowError('password', formik)}
                  value={formik.values.password}
                  required
                />
                <Input
                  name="password_confirmation"
                  label="Confirme a senha"
                  onChange={formik.handleChange}
                  placeholder="Mínimo de 8 digitos"
                  type="password"
                  error={shouldShowError('password_confirmation', formik)}
                  value={formik.values.password_confirmation}
                  required
                />
              </div>
              <div className={styles.form_half}>
                <Input
                  name="linkedin"
                  label="Perfil do Linkedin"
                  placeholder="https://www.linkedin.com/in/nome"
                  type="text"
                  value={formik.values.linkedin}
                  onChange={formik.handleChange}
                  error={shouldShowError('linkedin', formik)}
                  required
                />
                <Input
                  name="curriculum"
                  label="Currículo"
                  onChange={(event) => {
                    formik.setFieldValue(
                      'curriculum',
                      event.currentTarget.files[0],
                    )
                  }}
                  type="file"
                  error={shouldShowError('curriculum', formik)}
                  required
                />
              </div>
              <div className={styles.form_half}>
                <Checkbox
                  name="agree"
                  onChange={formik.handleChange}
                  error={shouldShowError('agree', formik)}
                  value={formik.values.agree}
                >
                  Li e concordo com as
                  {' '}
                  <a
                    href="https://expert.cognitivo.ai/ppd/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Politicas de Proteção de Dados
                  </a>
                </Checkbox>
                <div>
                  <Button
                    disabled={shouldDisableButton(formik)}
                    text="Concluir cadastro"
                    type="submit"
                    expand="x"
                  />
                </div>
              </div>
            </form>
          </FormBox>
          <div className={styles.links}>
            <p className={styles.text}>
              Já possui acesso?
              {' '}
              <Link to={routesNames.experts.login} className={styles.link}>
                Faça login
                <ArrowRightMini />
              </Link>
            </p>
          </div>
        </div>
      </main>
    </div>
  )
}

CreateAccountTemplate.propTypes = {
  formik: PropTypes.func.isRequired,
  hasErrors: PropTypes.bool.isRequired,
}

export default CreateAccountTemplate
