import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

export const FeedbackContextState = createContext()
const FeedbackDispatchContext = createContext()

function feedbackReducer(state, action) {
  switch (action.type) {
    case 'set': {
      return {
        message: action.payload.message,
        open: action.payload.open,
        severity: action.payload.severity,
      }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const initialState = {
  message: 'Ação realizada com sucesso.',
  open: false,
  severity: 'success',
}

function FeedbackProvider({ children }) {
  const [state, dispatch] = useReducer(feedbackReducer, initialState)

  return (
    <FeedbackContextState.Provider value={state}>
      <FeedbackDispatchContext.Provider value={dispatch}>
        {children}
      </FeedbackDispatchContext.Provider>
    </FeedbackContextState.Provider>
  )
}

function useFeedbackState() {
  const context = React.useContext(FeedbackContextState)

  if (context === undefined) {
    throw new Error('useFeedbackState must be used within a FeedbackProvider')
  }

  return context
}

function useFeedbackDispatch() {
  const context = React.useContext(FeedbackDispatchContext)

  if (context === undefined) {
    throw new Error(
      'useFeedbackDispatch must be used within a FeedbackProvider',
    )
  }

  return context
}

function useFeedback() {
  return {
    dispatch: useFeedbackDispatch(),
    state: useFeedbackState(),
  }
}

FeedbackProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { FeedbackProvider, useFeedbackDispatch, useFeedback }
