/* eslint-disable */
import React from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'

import styles from './styles.module.css'

const EllipseOutiline = ({ currentStep }) => (
  <motion.div
    className={classNames(
      styles.ellipse_outilne,
      styles[`ellipse_outilne_${currentStep}`]
    )}
    animate={{
      scale: [0.9, 1, 0.9, 0.8, 1],
    }}
    transition={{
      duration: 2,
      ease: 'easeInOut',
      times: [0, 0.2, 0.5, 0.8, 1],
      repeatDelay: 1,
    }}
  />
)

export default EllipseOutiline
