import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Box, Progress } from 'components'
import styles from './styles.module.css'

function BoxWithTitle({ children, progress, title }) {
  const [toggle, setToggle] = useState(false)

  function handleClick(event) {
    event.preventDefault()
    setToggle(!toggle)
  }

  return (
    <Box className={classNames(styles.box, toggle && styles.is_open)}>
      <div
        onClick={handleClick}
        role="button"
        tabIndex="0"
        aria-hidden="true"
        className={styles.box_toggle}
      >
        <h3 className={styles.title}>{title}</h3>
        <span className={styles.percent}>
          {progress}
          % concluído
        </span>
      </div>
      {children}
      <Progress percent={progress} />
    </Box>
  )
}

BoxWithTitle.propTypes = {
  children: PropTypes.node.isRequired,
  progress: PropTypes.number,
  title: PropTypes.string.isRequired,
}

BoxWithTitle.defaultProps = {
  progress: null,
}

export default BoxWithTitle
