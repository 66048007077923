import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import routesNames from 'routes/routesNames'

import { Box, Dashboard } from 'components'

import styles from './styles.module.css'

function Account({ onExit, user }) {
  return (
    <Dashboard onExit={onExit} username={user.full_name} type="experts">
      <div className={styles.wrapper}>
        <Box>
          <Link
            className={styles.link}
            to={routesNames.experts.accountChangePassword}
          >
            <span className={styles.text}>Alterar Senha</span>
          </Link>
        </Box>
        <Box>
          <Link
            className={styles.link}
            to={routesNames.experts.accountDeactivate}
          >
            <span className={styles.text}>Declarar Indisponibilidade</span>
          </Link>
        </Box>
      </div>
    </Dashboard>
  )
}

Account.propTypes = {
  onExit: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
}

export default Account
