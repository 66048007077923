import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import Button from 'components/Button'
import Condition from 'components/Condition'
import Tag from 'components/Tag'
import { statusTexts, statusAllocationTexts, statusTypes } from './helpers'
import styles from './styles.module.css'

function ProjectBox({
  buttonText,
  desc,
  domainArea,
  href,
  image,
  key,
  label,
  onClick,
  startDate,
  title,
  allocationStatus,
}) {
  const history = useHistory()

  function handleRedirect() {
    if (href.startsWith('http')) {
      return window?.open(href)
    }
    return history.push(href)
  }

  function handleClick(e) {
    return onClick(e)
  }

  return (
    <div
      key={key}
      className={classNames(styles.project_box, image && styles.has_image)}
    >
      <Condition condition={!!image}>
        <figure className={styles.project_box_figure}>
          <img
            className={styles.project_box_image}
            src={image}
            alt="Project name"
          />
        </figure>
      </Condition>
      <Condition condition={!!label}>
        <Tag text={statusTexts(label)} type={statusTypes(label)} />
      </Condition>
      <h2 className={styles.project_box_title}>{title}</h2>
      <Condition condition={!!desc}>
        <p className={styles.project_box_desc}>{desc}</p>
      </Condition>

      <Condition condition={!!domainArea}>
        <span className={styles.project_box_label}>Domínio</span>
        <p className={styles.project_box_desc}>{domainArea}</p>
      </Condition>

      <Condition condition={!!startDate}>
        <span className={styles.project_box_label}>Data Início</span>
        <p className={styles.project_box_desc}>{startDate}</p>
      </Condition>

      <Condition condition={!!allocationStatus}>
        {/* <span className={styles.project_box_label}>Alocação</span> */}
        <Tag text={statusAllocationTexts(allocationStatus)} type={statusTypes(allocationStatus)} wide />
      </Condition>

      <Condition condition={!!href}>
        <Button
          className={styles.project_box_btn}
          text={buttonText}
          fill="outline"
          expand="x"
          size="small"
          onClick={handleRedirect}
        />
      </Condition>
      <Condition condition={!!onClick}>
        <Button
          className={styles.project_box_btn}
          text={buttonText}
          fill="outline"
          expand="x"
          size="small"
          onClick={handleClick}
        />
      </Condition>
    </div>
  )
}

ProjectBox.propTypes = {
  buttonText: PropTypes.string,
  desc: PropTypes.string,
  domainArea: PropTypes.string,
  href: PropTypes.string,
  image: PropTypes.string,
  key: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  startDate: PropTypes.string,
  title: PropTypes.string.isRequired,
  allocationStatus: PropTypes.string,
}

ProjectBox.defaultProps = {
  buttonText: 'Mostrar mais detalhes',
  desc: null,
  domainArea: null,
  href: null,
  image: null,
  key: null,
  label: null,
  onClick: null,
  startDate: null,
  allocationStatus: null,
}

export default ProjectBox
