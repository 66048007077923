import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import { getIsAuth, getCustomersProjectId } from '../../utils/local-storage'
import routesNames from '../routesNames'

const validateAccess = (type) => {
  if (!getIsAuth()) {
    return <Redirect to={routesNames[type].login} />
  }

  if (!getCustomersProjectId() && type === 'customers') {
    return <Redirect to={routesNames[type].selectProject} />
  }

  return <Redirect to={routesNames[type].showcaseOpportunities} />
}

function DefaultRoute({ type, ...rest }) {
  return <Route {...rest}>{validateAccess(type)}</Route>
}

DefaultRoute.propTypes = {
  type: PropTypes.oneOf(['experts', 'customers']).isRequired,
}

export default DefaultRoute
