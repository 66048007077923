import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import routesNames from 'routes/routesNames'
import killSession from 'utils/helpers/killSession'
import { useUsername } from 'context/username'
import { useLoading } from 'context/loading'
import expertService from 'services/experts'

import {
  cleanFormValues,
  makeGetActivitiesConceptsRequest,
  makeGetBusinessSegmentsRequest,
  makeGetDataStoragesRequest,
  makeGetDomainAreasRequest,
  makeGetInfrasCloudsRequest,
  makeGetLanguagesRequest,
  makeGetLibrariesFrameworksRequest,
  makeGetProgressRequest,
  makeGetToolsPlatformsRequest,
  transformArrayToSelectValue,
} from '../helpers'

const useUpdateAccount = () => {
  const [hasErrors, setHasErrors] = useState(false)
  const [activitiesConcepts, setActivitiesConcepts] = useState([])
  const [businessSegments, setBusinessSegments] = useState([])
  const [dataStorages, setDataStorages] = useState([])
  const [domainAreas, setDomainAreas] = useState([])
  const [infrasClouds, setInfrasClouds] = useState([])
  const [languages, setLanguages] = useState([])
  const [librariesFrameworks, setLibrariesFrameworks] = useState([])
  const [progressForms, setProgressForms] = useState({})
  const [toolsPlatforms, setToolsPlatforms] = useState([])
  const { dispatch: dispatchUser, state } = useUsername()
  const { dispatch: dispatchLoading } = useLoading()
  const history = useHistory()

  function onSubmit(values, seErros) {
    const cleanedValues = cleanFormValues(values)
    const token = localStorage.getItem('cognitivo_plataforma_auth_token') //eslint-disable-line

    expertService
      .update(cleanedValues, token)
      .then((result) => {
        dispatchUser({
          payload: result,
          type: 'set',
        })
        history.push(routesNames.experts.updateAccount)
      })
      .catch(() => seErros(true))
  }

  function onExit() {
    killSession()

    history.push(routesNames.experts.login)
  }

  const getSkillsFromDomain = useCallback(async (id) => {
    const [
      languagesResults,
      librariesFrameworksResult,
      toolsPlatformsResult,
      dataStoragesResult,
      infrasCloudsResult,
      activitiesConceptsResult,
    ] = await Promise.all([
      makeGetLanguagesRequest(id),
      makeGetLibrariesFrameworksRequest(id),
      makeGetToolsPlatformsRequest(id),
      makeGetDataStoragesRequest(id),
      makeGetInfrasCloudsRequest(id),
      makeGetActivitiesConceptsRequest(id),
    ])

    setLanguages(languagesResults)
    setLibrariesFrameworks(librariesFrameworksResult)
    setToolsPlatforms(toolsPlatformsResult)
    setDataStorages(dataStoragesResult)
    setInfrasClouds(infrasCloudsResult)
    setActivitiesConcepts(activitiesConceptsResult)
  }, [])

  const getDomainAreas = useCallback(async () => {
    try {
      const domainAreasResult = await makeGetDomainAreasRequest()
      const businessSegmentsResult = await makeGetBusinessSegmentsRequest()

      setDomainAreas(domainAreasResult)
      setBusinessSegments(businessSegmentsResult)

      if (state.user.domain_area) {
        await getSkillsFromDomain(state.user.domain_area)
      }
    } catch (err) {
      dispatchLoading({
        payload: false,
        type: 'set',
      })
    } finally {
      dispatchLoading({
        payload: false,
        type: 'set',
      })
    }
  }, [dispatchLoading, getSkillsFromDomain, state.user])

  useEffect(() => {
    dispatchLoading({
      payload: true,
      type: 'set',
    })

    getDomainAreas()
  }, [dispatchLoading, getDomainAreas])

  useEffect(() => {
    const getAllProgress = async () => {
      const [
        personalProgress,
        professionalProgress,
        residentialProgress,
      ] = await Promise.all([
        makeGetProgressRequest('personal'),
        makeGetProgressRequest('professional'),
        makeGetProgressRequest('residential'),
      ])

      setProgressForms({
        personalProgress,
        professionalProgress,
        residentialProgress,
      })
    }
    getAllProgress()
  }, [])

  const getUserValuesFromState = (user = {}, selectOptions) => {
    const formatedValues = {
      activities_concepts: user.activities_concepts,
      address: user.address,
      business_segments:
        transformArrayToSelectValue(
          user.business_segments,
          selectOptions.businessSegments,
        ) || user.business_segments,
      cell_phone: user.cell_phone,
      city: user.city,
      cnpj: user.cnpj,
      company_name: user.company_name,
      complement: user.complement,
      current_company: user.current_company,
      current_position: user.current_position,
      data_storages: user.data_storages,
      domain_area: user.domain_area,
      infras_clouds: user.infras_clouds,
      languages: user.languages,
      libraries_frameworks: user.libraries_frameworks,
      linkedin: user.linkedin,
      marital_status: user.marital_status,
      neighborhood: user.neighborhood,
      number: user.number,
      postal_code: user.postal_code,
      state: user.state,
      tools_platforms: user.tools_platforms,
      weekly_availability: user.weekly_availability,
    }

    return formatedValues
  }

  const formatedUserValues = getUserValuesFromState(state.user, {
    activitiesConcepts,
    businessSegments,
    dataStorages,
    domainAreas,
    infrasClouds,
    languages,
    librariesFrameworks,
    toolsPlatforms,
  })

  return {
    activitiesConcepts,
    businessSegments,
    dataStorages,
    domainAreas,
    formatedUserValues,
    getSkillsFromDomain,
    hasErrors,
    infrasClouds,
    languages,
    librariesFrameworks,
    onExit,
    onSubmit,
    progressForms,
    setHasErrors,
    toolsPlatforms,
    user: state.user,
  }
}

export default useUpdateAccount
