import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import routesNames from 'routes/routesNames'

import {
  Button, Dashboard, FormError, Select, 
} from 'components'

import shouldShowError from 'utils/helpers/form/shouldShowError'
import shouldDisableButton from 'utils/helpers/form/shouldDisableButton'

import styles from './styles.module.css'
import Schema from './schema'

function AccountDeactivate({ onExit, onSubmit, user }) {
  const [hasErrors, setHasErrors] = useState(false)
  const formik = useFormik({
    initialValues: {
      how_long: '',
      reason: '',
    },
    onSubmit: (values) => onSubmit(values, setHasErrors),
    validationSchema: Schema,
  })

  const monthsOptions = [
    {
      label: 'Selecione',
      value: '',
    },
    {
      label: '1 mês',
      value: '1',
    },
    {
      label: '3 meses',
      value: '3',
    },
    {
      label: '6 meses',
      value: '6',
    },
  ]

  const reasonOptions = [
    {
      label: 'Selecione',
      value: '',
    },
    {
      label: 'No momento não possui disponibilidade para pegar projetos',
      value: '0',
    },
  ]

  return (
    <Dashboard
      backTo={routesNames.experts.account}
      onExit={onExit}
      title="Declarar Indisponibilidade"
      description="Ao confirmar, você deixa de receber propostas para novos
      projetos no período determinado por você. Mas relaxe, é possível atualizar
      sua disponibilidade a qualquer momento!"
      type="experts"
      username={user.full_name}
    >
      <div className={styles.wrapper}>
        <FormError
          hasErrors={hasErrors}
          message="Houve um problema ao desativar a conta. Tente novamente mais tarde."
        />
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <Select
            name="reason"
            label="Por que deseja pausar sua conta?"
            options={reasonOptions}
            onChange={formik.handleChange}
            placeholder="Selecione uma das opções"
            error={shouldShowError('reason', formik)}
            value={formik.values.reason}
            required
          />
          <Select
            name="how_long"
            label="Por quanto tempo?"
            options={monthsOptions}
            onChange={formik.handleChange}
            placeholder="Selecione uma das opções"
            error={shouldShowError('how_long', formik)}
            value={formik.values.how_long}
            required
          />
          <Button
            text="Confirmar"
            type="submit"
            disabled={shouldDisableButton(formik)}
          />
        </form>
      </div>
    </Dashboard>
  )
}

AccountDeactivate.propTypes = {
  onExit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
}

export default AccountDeactivate
