import { ReactComponent as GlobeIcon } from '../../assets/img/globe.svg'
import { ReactComponent as SettingsIcon } from '../../assets/img/settings.svg'
import { ReactComponent as ShieldCheck } from '../../assets/img/shield_check.svg'
import { ReactComponent as TasksIcon } from '../../assets/img/tasks.svg'
import { ReactComponent as FindJobsIcon } from '../../assets/img/find_jobs_mini.svg'
import { ReactComponent as PersonIcon } from '../../assets/img/person.svg'
import { ReactComponent as CalendarIcon } from '../../assets/img/calendar_mini.svg'
import { ReactComponent as ReferExpertIcon } from '../../assets/img/expert-indicate-2.svg'
import { ReactComponent as FolderIcon } from '../../assets/img/folder.svg'
import routesNames from '../../routes/routesNames'

const menuItems = {
  customers: [
    {
      alt: 'Globo terrestre',
      icon: GlobeIcon,
      title: 'Visão geral',
      to: routesNames.customers.home,
    },
    {
      alt: 'Escudo com um símbolo falando que está ok',
      icon: ShieldCheck,
      title: 'Acordos',
      to: routesNames.customers.agreements,
    },
    {
      alt: 'Uma pilha de papéis',
      icon: TasksIcon,
      title: 'Tarefas',
      to: routesNames.customers.tasks,
    },
  ],
  experts: [
    // {
    //   alt: 'Globo',
    //   icon: GlobeIcon,
    //   title: 'Visão geral',
    //   to: routesNames.experts.home,
    // },
    {
      alt: 'Pessoal',
      icon: PersonIcon,
      title: 'Minhas informações',
      to: routesNames.experts.updateAccount,
    },
    {
      alt: 'Calendário',
      icon: CalendarIcon,
      title: 'Disponibilidade',
      to: routesNames.experts.updateAvailability,
    },
    {
      alt: 'Maleta e Lupa',
      icon: FindJobsIcon,
      title: 'Vitrine',
      to: routesNames.experts.showcaseOpportunities,
    },
    {
      alt: 'Pasta',
      icon: FolderIcon,
      title: 'Meus projetos',
      to: routesNames.experts.myProjects,
    },
    {
      alt: 'Caixa de diálogo com uma pessoa dentro',
      icon: ReferExpertIcon,
      title: 'Indicação de experts',
      to: routesNames.experts.referExpert,
    },
    {
      alt: 'Configurações',
      icon: SettingsIcon,
      title: 'Conta',
      to: routesNames.experts.account,
    },
  ],
}

export default menuItems
