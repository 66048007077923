import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

import { Dashboard } from '../../../components'
import AgreementCard from './AgreementCard'

function Home({
  agreements, loadingAgreements, onExit, username, 
}) {
  return (
    <Dashboard onExit={onExit} username={username} type="customers">
      {loadingAgreements ? (
        <Skeleton count={3} height={200} />
      ) : (
        agreements.map((agreement) => (
          <AgreementCard
            key={agreement.title}
            createdAt={agreement.created_at}
            {...agreement}
          />
        ))
      )}
    </Dashboard>
  )
}

Home.propTypes = {
  agreements: PropTypes.arrayOf().isRequired,
  loadingAgreements: PropTypes.bool.isRequired,
  onExit: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
}

export default Home
