import React from 'react'
import { useHistory } from 'react-router-dom'

import killSession from 'utils/helpers/killSession'
import { useUsername } from 'context/username'
import routesNames from 'routes/routesNames'

import { ExpertsAccount as ExpertsAccountContainer } from 'containers'

function Account() {
  const { state } = useUsername()
  const history = useHistory()

  function onExit() {
    killSession()

    history.push(routesNames.experts.login)
  }

  return <ExpertsAccountContainer onExit={onExit} user={state.user} />
}

export default Account
