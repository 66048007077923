import * as Yup from 'yup'

import messages from 'utils/yup/messages'

const schema = {
  business_hours_availability: Yup.string().required(messages.string.required),
  weekly_availability: Yup.string().required(messages.string.required),
}

const yupValidator = Yup.object(schema)

export default yupValidator
