import * as Yup from 'yup'

import messages from 'utils/yup/messages'

Yup.addMethod(Yup.string, 'fullName', (message) => Yup.string().test('full-name', message, (value = '') => {
  if (value.length >= 4) {
    const valueToArray = value.split(' ')
    const arrSize = valueToArray.length

    return arrSize > 1 && valueToArray[1].length > 0
  }

  return true
}))

const schema = {
  cell_phone: Yup.string()
    .min(8, messages.string.min(8))
    .required(messages.string.required),
  comment: Yup.string()
    .min(15, messages.string.min(15))
    .required(messages.string.required),
  domain_area: Yup.array().required(messages.string.required),
  email: Yup.string()
    .min(5, messages.string.min(5))
    .email(messages.string.email)
    .required(messages.string.required),
  linkedin: Yup.string()
    .min(15, messages.string.min(15))
    .required(messages.string.required),
  name: Yup.string()
    .min(4, messages.string.min(4))
    .fullName('Você precisa inserir o sobrenome')
    .required(messages.string.required),
}

const yupValidator = Yup.object(schema)

export default yupValidator
