import React from 'react'
import { useHistory } from 'react-router-dom'
import routesNames from 'routes/routesNames'

import { Button } from 'components'
import { ReactComponent as IllustraSecureData } from 'assets/img/illustra-secure-data-cuate.svg'
import LoggedOutTemplate from 'templates/LoggedOutTemplate'
import styles from './styles.module.css'

function ResetPasswordSuccess() {
  const history = useHistory()

  function goToLogin() {
    history.replace(routesNames.experts.login)
  }

  return (
    <LoggedOutTemplate>
      <div className={styles.page}>
        <div className={styles.illustration}>
          <IllustraSecureData />
          <a className={styles.ref} href="https://storyset.com/people">
            People illustrations by Storyset
          </a>
        </div>
        <div className={styles.content}>
          <div className={styles.box}>
            <h1 className={styles.title}>Senha redefinida!</h1>
            <p className={styles.text}>
              Agora você já pode entrar em sua conta.
            </p>
            <Button
              className={styles.button}
              type="button"
              text="Fazer login"
              onClick={goToLogin}
            />
          </div>
        </div>
      </div>
    </LoggedOutTemplate>
  )
}

export default ResetPasswordSuccess
