import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import routesNames from 'routes/routesNames'

import { Button, Condition, Modal } from 'components'
import { disclaimerType, statusType } from '../helpers'
import styles from './styles.module.css'

function Detail({
  activitiesAndConcepts,
  businessSegment = [],
  dataStorage,
  description,
  domainArea,
  handleToggleInterest,
  infraAndCloud,
  languages,
  onHide,
  projectEndDate,
  projectExpertCanRegister,
  projectExpertInterested,
  projectId,
  projectInitialDate,
  projectPayForHour,
  projectRequiredWeeklyHours,
  projectStatus,
  projectWorkersNumbers,
  show,
  title,
  toolsAndPlataforms,
}) {
  function handleRegister() {
    handleToggleInterest(projectId, 'register')
  }

  function handleUnregister() {
    handleToggleInterest(projectId, 'unregister')
  }

  function isDisabledOpportunity() {
    const isFrozen = projectStatus === 'frozen'
    return isFrozen || (!projectExpertCanRegister && !projectExpertInterested)
  }
  // function isDisabled () {
  //   return projectStatus === 'frozen' || projectStatus === 'closed'
  // }

  return (
    <Modal onHide={onHide} show={show} title={title}>
      <div className={styles.business_informations}>
        <div>
          <span className={styles.title_description}>Descrição do projeto</span>
          <p className={styles.description}>{description}</p>
        </div>
        <div>
          <span className={styles.title_description}>Segmento de negócio</span>
          <p className={styles.description}>{businessSegment}</p>
        </div>
      </div>
      <hr className={styles.line} />
      <div className={styles.project_informations}>
        <div>
          <span className={styles.title_description}>Status</span>
          <p className={styles.description}>{statusType(projectStatus)}</p>
        </div>
        <div>
          <span className={styles.title_description}>Data de início</span>
          <p className={styles.description}>{projectInitialDate}</p>
        </div>
        <div>
          <span className={styles.title_description}>
            Previsão de conclusão
          </span>
          <p className={styles.description}>{projectEndDate}</p>
        </div>
        <div>
          <span className={styles.title_description}>Dedicação de horas</span>
          <p className={styles.description}>
            {projectRequiredWeeklyHours}
            {' '}
            hora(s) semanal(is)
          </p>
        </div>
        <div>
          <span className={styles.title_description}>Valor/hora</span>
          <p className={styles.description}>
            R$ 
            {' '}
            {projectPayForHour}
            /h
          </p>
        </div>
        <div>
          <span className={styles.title_description}>Número de vagas</span>
          <p className={styles.description}>{projectWorkersNumbers}</p>
        </div>
      </div>
      <hr className={styles.line} />
      <span className={styles.title}>Pré-requisitos necessários</span>
      <div className={styles.requirements}>
        <div>
          <span className={styles.title_description}>Área de domínio</span>
          <p className={styles.description}>{domainArea}</p>
        </div>
        <div>
          <span className={styles.title_description}>Linguagens</span>
          <p className={styles.description}>{languages}</p>
        </div>
        <div>
          <span className={styles.title_description}>
            Ferramentas e plataformas
          </span>
          <p className={styles.description}>{toolsAndPlataforms}</p>
        </div>
        <div>
          <span className={styles.title_description}>
            Armazenamento de dados
          </span>
          <p className={styles.description}>{dataStorage}</p>
        </div>
        <div>
          <span className={styles.title_description}>
            Infraestrutura e Cloud
          </span>
          <p className={styles.description}>{infraAndCloud}</p>
        </div>
        <div>
          <span className={styles.title_description}>
            Atividades e conceitos
          </span>
          <p className={styles.description}>{activitiesAndConcepts}</p>
        </div>
      </div>
      <Condition
        condition={!projectExpertInterested && !isDisabledOpportunity()}
      >
        <Button
          type="button"
          text="Demonstrar interesse"
          onClick={handleRegister}
        />
      </Condition>
      <Condition condition={projectExpertInterested}>
        <Button
          fill="outline"
          type="button"
          text="Desfazer candidatura"
          onClick={handleUnregister}
        />
      </Condition>
      <Condition condition={isDisabledOpportunity()}>
        <Button disabled type="button" text="Demonstrar interesse" />
      </Condition>
      <p className={styles.disclaimer}>
        {disclaimerType(projectStatus, projectExpertCanRegister)}
        <Condition
          condition={
            !projectExpertCanRegister
            && projectStatus !== 'frozen'
            && projectStatus !== 'closed'
          }
        >
          <Link className={styles.link} to={routesNames.experts.updateAccount}>
            Atualize suas informações.
          </Link>
        </Condition>
      </p>

      {/* <Condition
        condition={projectExpertCanRegister && !projectExpertInterested}
      >
        <Button
          type="button"
          text="Demonstrar interesse"
          onClick={handleRegister}
        />
      </Condition>
      <Condition
        condition={projectExpertInterested}
      >
        <Button
          fill="outline"
          type="button"
          text="Desfazer candidatura"
          onClick={handleUnregister}
        />
      </Condition>
      <Condition
        condition={isDisabledOpportunity()}
      >
        <Button
          disabled
          type="button"
          text="Demonstrar interesse"
        />
        <p className={styles.disclaimer}>
          {disclaimerType(projectStatus, projectExpertCanRegister)}
          <Condition
            condition={!projectExpertCanRegister && projectStatus !== 'frozen'}
          >
            <Link
              className={styles.link}
              to={routesNames.experts.updateAccount}
            >
              Atualize suas informações.
            </Link>
          </Condition>
        </p>
      </Condition> */}
    </Modal>
  )
}

Detail.propTypes = {
  activitiesAndConcepts: PropTypes.string.isRequired,
  businessSegment: PropTypes.string.isRequired,
  dataStorage: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  domainArea: PropTypes.string.isRequired,
  handleToggleInterest: PropTypes.func.isRequired,
  infraAndCloud: PropTypes.string.isRequired,
  languages: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  projectEndDate: PropTypes.string.isRequired,
  projectExpertCanRegister: PropTypes.bool.isRequired,
  projectExpertInterested: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  projectInitialDate: PropTypes.string.isRequired,
  projectPayForHour: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  projectRequiredWeeklyHours: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  projectStatus: PropTypes.string.isRequired,
  projectWorkersNumbers: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  toolsAndPlataforms: PropTypes.string.isRequired,
}

export default Detail
