import React from 'react'
import LoggedOutTemplate from 'templates/LoggedOutTemplate'
import ForgotPasswordTemplate from './components/ForgotPasswordTemplate'
import useForgotPassword from './hooks'

function ForgotPassword() {
  const { formik, hasErrors } = useForgotPassword()

  return (
    <LoggedOutTemplate>
      <ForgotPasswordTemplate formik={formik} hasErrors={hasErrors} />
    </LoggedOutTemplate>
  )
}

export default ForgotPassword
