import { GET, POST } from 'utils/helpers/request'
import account from './account'
import availability from './availability'
import opportunities from './opportunities'
import skills from './skills'

const experts = {
  account,
  availability,
  configuration: (id, token) => {
    const endpoint = `configuration/${id}`
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
  discover: (token) => {
    const endpoint = 'informations/'
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
  me: (token) => {
    const endpoint = 'experts/me/'
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
  opportunities,
  progress: (payload, token) => {
    const endpoint = `experts/progress/${payload}`
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    }
    return GET(endpoint, config)
  },
  recommendation: (payload, token) => {
    const endpoint = 'experts/recommendation/'
    const config = {
      body: JSON.stringify(payload),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return POST(endpoint, config)
  },
  recommendations: (token, pageNumber = 1, selectedDomain = '') => { // GET indicações de experts
    let endpoint = `experts/recommendations/`
    if (pageNumber > 1) {
      endpoint = `${endpoint}?page=${pageNumber}`
    }
    if (selectedDomain !== "") {
      // console.log("service. selectedDomain: ", selectedDomain)
      endpoint = `${endpoint}?domain_area=${selectedDomain}`
    }
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    }
    return GET(endpoint, config)
  },
  register: (payload) => {
    const endpoint = 'experts/register/'
    const config = {
      body: JSON.stringify(payload),
    }

    return POST(endpoint, config)
  },
  skills,
  update: (payload, token) => {
    const endpoint = 'experts/update/'
    const config = {
      body: JSON.stringify(payload),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return POST(endpoint, config)
  },
  uploadCv: (payload) => {
    const endpoint = 'experts/upload_cv/'
    const config = {
      body: payload,
      noContentType: true,
    }

    return POST(endpoint, config)
  },
}

export default experts
