import React from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { ReactComponent as CheckCircle } from 'assets/img/check_circle_blue.svg'
import Condition from 'components/Condition'
import Button from 'components/Button'
import Modal from 'components/Modal'
import { removeTagBr } from '../../helpers'
import Icon from './Icon'

import useSelectiveProcess from './hooks'

import styles from './styles.module.css'

function SelectiveProcess({ getProject, myProject, projectId }) {
  const {
    allocationProcess,
    modalAllocated,
    modalRefused,
    onClickButton,
    onCloseModal,
    redirectToOpportunities,
  } = useSelectiveProcess({ getProject, myProject, projectId })

  return (
    <div className={styles.selective_process}>
      <Modal onHide={onCloseModal} show={modalAllocated}>
        <div className={styles.modal}>
          <CheckCircle />
          <h2 className={styles.modal_title}>Parabéns!</h2>
          <p className={styles.modal_desc}>
            A alocação foi concluída com sucesso. Atualize a página para ver
            todas as informações detalhadas do projeto.
          </p>
          {/* <Button
            text="Atualizar a página"
            type="buttom"
            onClick={redirectToMyProjects}
          /> */}
        </div>
      </Modal>
      <Modal onHide={onCloseModal} show={modalRefused}>
        <div className={styles.modal}>
          <CheckCircle />
          <h2 className={styles.modal_title}>Ok!</h2>
          <p className={styles.modal_desc}>
            Poxa, é uma pena que tenha recusado. Veja a vitrine pra ver se tem
            outra oportunidade que te interessa!
          </p>
          <Button
            text="Ir para a vitrine"
            type="buttom"
            onClick={redirectToOpportunities}
          />
        </div>
      </Modal>

      <h2 className={styles.selective_process_title}>
        Status do Processo de Alocação
      </h2>
      {/*
      <Condition condition={isRefusedByExpert()}>
        <p className={styles.negative_info}>
          Você recusou esta vaga no dia {dateRefusedByExpert}&nbsp;
          <span role="img" aria-label="emoji triste">😟 </span> 
          Veja na&nbsp;
          <Link
            to={routesNames.experts.showcaseOpportunities}
          >
            Vitrine
          </Link>&nbsp;
          outras oportunidades que possam te interessar!
        </p>
      </Condition> */}
      <div className={styles.selective_process_list}>
        {allocationProcess?.map((item, idx) => (
          <SelectiveProcessItem
            data={item}
            id={idx - 0.6}
            key={item.id}
            status={myProject.status}
            onClickButton={onClickButton}
          />
        ))}
      </div>
    </div>
  )
}

function SelectiveProcessItem({
  data,
  id,
  key,
  onClickButton,
  // status,
}) {
  const classItem = classNames(
    styles.selective_process_item,
    data.status && styles[`status_${data.status}`],
    data.type && styles[`type_${data.type}`],
  )

  const isComplete = data.status === 'complete'
  const isRefused = data.status === 'refused'
  const isWaiting = data.status === 'waiting'
  const showBallon = isComplete || isWaiting

  return (
    <div className={classItem} key={`${key}-${id}-${data.status}`}>
      <Condition condition={isComplete}>
        <Icon.Bar delay={id + 0.2} />
      </Condition>
      <Icon delay={id} show={showBallon} type={data.type}>
        <Condition condition={isComplete}>
          <Icon.Check delay={id} />
        </Condition>
        <Condition condition={isRefused}>
          <Icon.Negative delay={id} />
        </Condition>
      </Icon>

      <motion.h3
        className={styles.selective_process_item_title}
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: id }}
      >
        {data.title}
      </motion.h3>
      <motion.p
        className={styles.selective_process_item_desc}
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: id + 0.1 }}
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{ __html: removeTagBr(data.desc) }}
      />

      <Condition condition={isWaiting && data.buttons?.length}>
        <motion.div
          className={styles.selective_process_item_buttons}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: id + 0.2 }}
        >
          {data?.buttons?.map((btn) => (
            <Button
              key={btn.text}
              text={btn.text}
              type="button"
              fill={btn.fill}
              onClick={onClickButton}
              data-request={btn.request}
            />
          ))}
        </motion.div>
      </Condition>
    </div>
  )
}

SelectiveProcess.propTypes = {
  getProject: PropTypes.func.isRequired,
  myProject: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  projectId: PropTypes.number.isRequired,
}

SelectiveProcessItem.propTypes = {
  data: PropTypes.shape().isRequired,
  id: PropTypes.number.isRequired,
  key: PropTypes.number.isRequired,
  onClickButton: PropTypes.func,
  status: PropTypes.string.isRequired,
}

SelectiveProcessItem.defaultProps = {
  onClickButton: null,
}

export default SelectiveProcess
