import React, { useEffect } from 'react'
import killSession from 'utils/helpers/killSession'
import LogoIcon from 'assets/img/logo.svg'
import styles from './styles.module.css'

function Maintenance() {
  useEffect(() => {
    killSession()
  }, [])

  return (
    <div className={styles.background}>
      <img
        className={styles.logo}
        src={LogoIcon}
        alt="Logo da Cognitivo com o texto Cognitivo"
      />
      <main className={styles.box}>
        <h1 className={styles.title}>Plataforma em manutenção!</h1>
        <p className={styles.text}>Tente novamente mais tarde.</p>
      </main>
    </div>
  )
}

export default Maintenance
