import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useUsername } from 'context/username'
import { useLoading } from 'context/loading'
import killSession from 'utils/helpers/killSession'
import routesNames from 'routes/routesNames'
import expertsService from 'services/experts'
import { getAuthToken } from 'utils/local-storage'

export default function useMyProjects() {
  const [isLoading, setLoading] = useState(true)
  const [myActiveProjectsList, setMyActiveProjectsList] = useState()
  const [myInactiveProjectsList, setMyInactiveProjectsList] = useState()
  const { dispatch: dispatchLoading } = useLoading()
  const { state } = useUsername()
  const history = useHistory()

  function onExit() {
    killSession()

    history.push(routesNames.experts.login)
  }

  const getProjects = useCallback(async () => {
    try {
      const token = getAuthToken()
      const response = await expertsService.opportunities.job_applications(
        token,
      )

      const reduceOpportunities = async (acc, curr) => {
        const data = await expertsService.opportunities.job_application(
          curr.id,
          token,
        )

        if (data.status) {
          const newCurr = {
            ...curr,
            status_allocation: data.status,
          }
          return [...(await acc), newCurr]
        }

        return [...acc, curr]
      }

      const format = await response.results?.reduce(reduceOpportunities, [])

      // Separating active and inactive projects
      const separatedArrays = format.reduce((acc, obj) => {
        if (obj.status !== 'closed' && obj.status !== 'frozen' && obj.status !== 'refused' && obj.status !== 'refused' && obj.status !== 'refused_expert') {
          acc.active.push(obj)
        } else {
          acc.inactive.push(obj)
        }
        return acc;
      }, { active: [], inactive: [] })

      setMyActiveProjectsList(separatedArrays.active)
      setMyInactiveProjectsList(separatedArrays.inactive)
    } catch (e) {
      dispatchLoading({
        payload: false,
        type: 'set',
      })
    } finally {
      setLoading(false)
      dispatchLoading({
        payload: false,
        type: 'set',
      })
    }
  }, [setMyActiveProjectsList, setMyInactiveProjectsList, dispatchLoading])

  useEffect(() => {
    setLoading(true)
    dispatchLoading({
      payload: true,
      type: 'set',
    })

    getProjects()
  }, [dispatchLoading, getProjects])

  return {
    isLoading,
    myActiveProjectsList,
    myInactiveProjectsList,
    onExit,
    state,
  }
}
