import React from 'react'
import PropTypes from 'prop-types'

import convertToReadableDate from '../../../../utils/helpers/convertToReadableDate'
import { Badge } from '../../../../components'
import styles from './styles.module.css'

function CustomerPendencies({
  accountable,
  createdAt,
  deadline,
  description,
  late,
  title,
}) {
  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <p className={styles.title}>{title}</p>
        {late && <Badge title="Atrasada" type="danger" />}
      </header>
      <p className={styles.description}>{description}</p>
      <footer className={styles.footer}>
        <p className={styles.footer_text}>
          Data de criação:
          <time dateTime={createdAt}>{convertToReadableDate(createdAt)}</time>
        </p>
        <p className={styles.footer_text}>
          Prazo:
          <time dateTime={deadline}>{convertToReadableDate(deadline)}</time>
        </p>
        <p className={styles.footer_text}>
          Responsável:
          {accountable}
        </p>
      </footer>
    </div>
  )
}

CustomerPendencies.propTypes = {
  accountable: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  deadline: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  late: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

CustomerPendencies.defaultProps = {
  late: false,
}

export default CustomerPendencies
