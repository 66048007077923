import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import routesNames from 'routes/routesNames'
import { getIsAuth } from 'utils/local-storage'
import { useUsername, useUsernameState } from 'context/username'

import data from './data'
import Modal from './components/Modal'
import Steps from './components/Steps'

function Welcome({ firstAccess }) {
  const [step, setStep] = useState(0)
  const [showWelcome, setShowWelcome] = useState(false)
  const history = useHistory()
  const { dispatch: userDispatch } = useUsername()
  const { user: stateUser } = useUsernameState()
  const { pathname } = history.location
  const { home } = routesNames.experts

  useEffect(() => {
    function handleShowWelcome() {
      if (firstAccess && getIsAuth() && pathname === home) {
        setShowWelcome(true)
      }
    }
    handleShowWelcome()
  }, [pathname, home, firstAccess])

  function progressValues() {
    return data.reduce((acc, curr) => {
      if (curr.id > 0) {
        acc[curr.id] = curr.id
      }
      return acc
    }, [])
  }

  function handleSetStep(type) {
    return () => {
      if (type === 'next') {
        setStep(step + 1)
      }

      if (type === 'prev') {
        setStep(step - 1)
      }

      if (type === 'finish') {
        history.push(routesNames.experts.profilePersonalData)
        setShowWelcome(false)
        userDispatch({
          payload: {
            ...stateUser,
            first_access: false,
          },
          type: 'set',
        })
      }

      return null
    }
  }

  return (
    <Modal showWelcome={showWelcome} getStepCurrent={step}>
      <Steps
        dataSteps={data}
        getStepCurrent={step}
        handleSetStep={handleSetStep}
        size={progressValues()}
      />
    </Modal>
  )
}

Welcome.propTypes = {
  firstAccess: PropTypes.bool,
}

Welcome.defaultProps = {
  firstAccess: null,
}

export default Welcome
