import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.css'
/* eslint-disable */
const getButtonClasses = ({
  className,
  color,
  disabled,
  expand,
  fill,
  size,
}) => {
  const colorStyle = !disabled && styles[color]
  const disabledStyle = disabled && styles.disabled
  const fillStyle = !disabled && styles[fill]
  const sizeStyle = !disabled && styles[size]
  const expandStyle = !!expand && styles[`expand-${expand}`]

  return classNames(
    className,
    colorStyle,
    disabledStyle,
    expandStyle,
    fillStyle,
    sizeStyle,
    styles.button
  )
}

const Button = ({
  className,
  color,
  disabled,
  expand,
  fill,
  icon,
  onClick,
  size,
  text,
  type,
  ...rest
}) => {
  const buttonClasses = getButtonClasses({
    className,
    color,
    disabled,
    expand,
    fill,
    size,
  })

  function handleClick(e) {
    if (onClick) {
      return !disabled && onClick(e)
    }

    return null
  }

  return (
    <button
      className={buttonClasses}
      onClick={handleClick}
      type={type}
      {...rest}
    >
      {text} {icon}
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.node,
  color: PropTypes.oneOf(['primary', 'secondary', 'white']),
  disabled: PropTypes.bool,
  expand: PropTypes.string,
  fill: PropTypes.oneOf(['flat', 'outline', 'clean']),
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
}

Button.defaultProps = {
  className: null,
  color: 'primary',
  disabled: false,
  expand: null,
  fill: 'flat',
  icon: null,
  onClick: null,
  type: 'button',
}

export default memo(Button)
