const messages = {
  number: {
    min: (length) => `Este campo precisa ser maior que ${length}`,
  },
  string: {
    agree: 'Leia e aceite o acordo com as politicas de proteção de dados',
    cnpj: 'Este CNPJ é inválido',
    cpf: 'Este CPF é inválido',
    email: 'Não contém um e-mail válido',
    equal_fields: (field) => `Os campos de ${field} não são iguais`,
    max: (length) => `Este campo aceita no máximo ${length} caracteres`,
    min: (length) => `Este campo precisa de pelo menos ${length} caracteres`,
    required: 'Este campo é obrigatório',
    url: 'Este campo não é uma url válida',
    filesizeMax: 'Este arquivo é muito grande',
  },
}

export default messages
