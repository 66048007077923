import expertService from 'services/experts'
import { getAuthToken } from 'utils/local-storage'
import formatOptionsFromRequest from 'utils/helpers/formatOptionsFromRequest'
import makeRequestMergingPagination from 'utils/helpers/request/makeRequestMergingPagination'

const makeGetDomainAreasRequest = async () => {
  const token = getAuthToken()
  const requestToDo = () => expertService.skills.domain_areas(token)

  const result = await makeRequestMergingPagination(requestToDo)
  const formatedResult = await formatOptionsFromRequest(result)

  return formatedResult
}

const makeGetLanguagesRequest = async (id) => {
  const token = getAuthToken()
  const requestToDo = () => expertService.skills.languages(token, id)

  const result = await makeRequestMergingPagination(requestToDo)
  const formatedResult = await formatOptionsFromRequest(result)

  return formatedResult
}

const makeGetLibrariesFrameworksRequest = async (id) => {
  const token = getAuthToken()
  const requestToDo = () => expertService.skills.libraries_frameworks(token, id)

  const result = await makeRequestMergingPagination(requestToDo)
  const formatedResult = await formatOptionsFromRequest(result)

  return formatedResult
}

const makeGetToolsPlatformsRequest = async (id) => {
  const token = getAuthToken()
  const requestToDo = () => expertService.skills.tools_platforms(token, id)

  const result = await makeRequestMergingPagination(requestToDo)
  const formatedResult = await formatOptionsFromRequest(result)

  return formatedResult
}

const makeGetDataStoragesRequest = async (id) => {
  const token = getAuthToken()
  const requestToDo = () => expertService.skills.data_storages(token, id)

  const result = await makeRequestMergingPagination(requestToDo)
  const formatedResult = await formatOptionsFromRequest(result)

  return formatedResult
}

const makeGetInfrasCloudsRequest = async (id) => {
  const token = getAuthToken()
  const requestToDo = () => expertService.skills.infras_clouds(token, id)

  const result = await makeRequestMergingPagination(requestToDo)
  const formatedResult = await formatOptionsFromRequest(result)

  return formatedResult
}

const makeGetActivitiesConceptsRequest = async (id) => {
  const token = getAuthToken()
  const requestToDo = () => expertService.skills.activities_concepts(token, id)

  const result = await makeRequestMergingPagination(requestToDo)
  const formatedResult = await formatOptionsFromRequest(result)

  return formatedResult
}

const makeGetBusinessSegmentsRequest = async () => {
  const token = getAuthToken()
  const requestToDo = () => expertService.skills.business_segments(token)

  const result = await makeRequestMergingPagination(requestToDo)
  const formatedResult = await formatOptionsFromRequest(result)

  return formatedResult
}

const makeGetProgressRequest = async (slug) => {
  const token = getAuthToken()
  const result = await expertService.progress(slug, token)

  return result
}

const findLabelByValue = (arr, value) => arr.find((item) => item.value === value)

const transformArrayToSelectValue = (arr = [], selectOptions = []) => {
  if (arr && selectOptions && arr.length > 0 && selectOptions.length > 0) {
    return arr.map((value) => {
      const arrItem = findLabelByValue(selectOptions, value)

      return {
        label: arrItem?.label,
        value: arrItem?.value,
      }
    })
  }

  return null
}

const cleanFormValues = (obj) => {
  /* eslint-disable */
  let newObj = {}

  for (var key in obj) {
    const keyContent = obj[key]

    if (Array.isArray(keyContent)) {
      newObj[key] = keyContent.map((item) => {
        if (item.label) {
          return item.value
        }

        return item
      })
    } else {
      newObj[key] = obj[key]
    }
  }
  /* eslint-enable */

  return newObj
}

export {
  cleanFormValues,
  makeGetActivitiesConceptsRequest,
  makeGetBusinessSegmentsRequest,
  makeGetDataStoragesRequest,
  makeGetDomainAreasRequest,
  makeGetInfrasCloudsRequest,
  makeGetLanguagesRequest,
  makeGetLibrariesFrameworksRequest,
  makeGetProgressRequest,
  makeGetToolsPlatformsRequest,
  transformArrayToSelectValue,
}
