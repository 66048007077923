import { useState } from 'react'
import { useFormik } from 'formik'
import { useUsername } from 'context/username'
import { useLoading } from 'context/loading'
import { useFeedback } from 'context/feedback'
import { setExpertEmail } from 'utils/local-storage'
import authService from 'services/auth'
import Schema from './schema'

const useForgotPassword = () => {
  const { state } = useUsername()
  const [hasErrors, setHasErrors] = useState(false)
  const { dispatch: dispatchLoading } = useLoading()
  const { dispatch: dispatchFeedback } = useFeedback()

  function onSubmit(value) {
    dispatchLoading({
      payload: true,
      type: 'set',
    })

    authService
      .reset_password(value)
      .then((result) => {
        if (result.status === 'OK') {
          setExpertEmail(value.email)
          dispatchFeedback({
            payload: {
              message: 'E-mail enviado com sucesso.',
              open: true,
              severity: 'success',
            },
            type: 'set',
          })
          dispatchLoading({
            payload: false,
            type: 'set',
          })
        }
      })
      .catch(() => {
        setHasErrors({
          message: 'Não foi possível enviar o e-mail.',
          show: true,
        })
        dispatchLoading({
          payload: false,
          type: 'set',
        })
      })
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => onSubmit(values),
    validationSchema: Schema,
  })

  return {
    formik,
    hasErrors,
    state,
  }
}

export default useForgotPassword
