import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'components/Button'
import routesNames from 'routes/routesNames'
import NoProject from 'assets/img/illustra-no-projects.svg'
import styles from './styles.module.css'

function NoProjectsBox() {
  const history = useHistory()
  function handleRedirect() {
    return history.push(routesNames.experts.showcaseOpportunities)
  }

  return (
    <div className={styles.no_project}>
      <figure className={styles.no_project_figure}>
        <img
          className={styles.no_project_image}
          src={NoProject}
          alt="Ilustra nenhum projeto encontrado"
        />
      </figure>
      <div>
        <h3 className={styles.no_project_title}>
          Você ainda não tem projetos :(
        </h3>
        <p className={styles.no_project_desc}>
          Acesse a Vitrine de Projetos para se candidatar.
        </p>
        <Button
          className={styles.no_project_button}
          text="Ir para a Vitrine"
          fill="outline"
          size="small"
          onClick={handleRedirect}
        />
      </div>
    </div>
  )
}

export default NoProjectsBox
