import React from 'react'
import { useHistory } from 'react-router-dom'
import { ExpertsUpdateAvailability } from 'containers'
import { useUsername } from 'context/username'
import killSession from 'utils/helpers/killSession'
import { getAuthToken } from 'utils/local-storage'
import routesNames from 'routes/routesNames'
import expertService from 'services/experts'

import { useLoading } from 'context/loading'
import { useFeedback } from 'context/feedback'

function UpdateAvailability() {
  const { dispatch: dispatchLoading } = useLoading()
  const { dispatch: dispatchUser, state } = useUsername()
  const { dispatch: dispatchFeedback } = useFeedback()
  const history = useHistory()

  function onExit() {
    killSession()

    history.push(routesNames.experts.login)
  }

  function onSubmit(values, setHasErrors) {
    const token = getAuthToken()
    const cleanValues = {
      business_hours_availability: values.business_hours_availability,
      weekly_availability: values.weekly_availability,
    }

    dispatchLoading({
      payload: true,
      type: 'set',
    })

    expertService
      .update(cleanValues, token)
      .then((result) => {
        dispatchUser({
          payload: result,
          type: 'set',
        })

        dispatchFeedback({
          payload: {
            message: 'Disponibilidade atualizada com sucesso!',
            open: true,
            severity: 'success',
          },
          type: 'set',
        })

        dispatchLoading({
          payload: false,
          type: 'set',
        })

        history.push(routesNames.experts.home)
      })
      .catch(() => {
        setHasErrors(true)

        dispatchLoading({
          payload: false,
          type: 'set',
        })
      })
  }

  return (
    <ExpertsUpdateAvailability
      onExit={onExit}
      onSubmit={onSubmit}
      user={state.user}
    />
  )
}

export default UpdateAvailability
