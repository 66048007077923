import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Condition from '../Condition'
import Header from '../Header'
import Menu from '../Menu'
import styles from './styles.module.css'
import menuItems from './menuItems'

function Dashboard({
  backTo,
  children,
  description,
  onExit,
  title,
  type,
  username,
}) {
  return (
    <div className={styles.dashboard}>
      <Header onExit={onExit} username={username} />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Menu type={type} items={menuItems} />
          <main className={styles.main}>
            <Condition condition={backTo}>
              <Link className={styles.back} to={backTo || '/'}>
                Voltar
              </Link>
            </Condition>
            <Condition condition={title}>
              <h2 className={styles.title}>{title}</h2>
            </Condition>
            <Condition condition={description}>
              <p className={styles.description}>{description}</p>
            </Condition>
            {children}
          </main>
        </div>
      </div>
    </div>
  )
}

Dashboard.propTypes = {
  backTo: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.node.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.node,
  ]),
  onExit: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  type: PropTypes.oneOf(['experts', 'customers']).isRequired,
  username: PropTypes.string,
}

Dashboard.defaultProps = {
  backTo: false,
  description: false,
  title: false,
  username: null,
}

export default memo(Dashboard)
