import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Dashboard } from 'components'
import { getAuthToken } from 'utils/local-storage'
import { useFeedback } from 'context/feedback'
import { useLoading } from 'context/loading'
import { useUsername } from 'context/username'
import expertService from 'services/experts'
import killSession from 'utils/helpers/killSession'
import routesNames from 'routes/routesNames'

function AccountActivate() {
  const history = useHistory()
  const { dispatch: dispatchUser, state } = useUsername()
  const { dispatch: dispatchLoading } = useLoading()
  const { dispatch: dispatchFeedback } = useFeedback()

  function onExit() {
    killSession()

    history.push(routesNames.experts.login)
  }

  function onSubmit(e) {
    e.preventDefault()
    const token = getAuthToken()

    dispatchLoading({
      payload: true,
      type: 'set',
    })

    expertService.account
      .active(token)
      .then((result) => {
        if (result.status === 'active') {
          dispatchUser({
            payload: {
              ...state.user,
              status_type: result.status,
            },
            type: 'set',
          })

          dispatchFeedback({
            payload: {
              message: 'Conta ativada com sucesso!',
              open: true,
              severity: 'success',
            },
            type: 'set',
          })

          dispatchLoading({
            payload: false,
            type: 'set',
          })

          history.push(routesNames.experts.account)
        }
      })
      .catch(() => {
        dispatchLoading({
          payload: false,
          type: 'set',
        })
      })
  }

  return (
    <Dashboard
      backTo={routesNames.experts.account}
      onExit={onExit}
      title="Ativar conta"
      description="Que bom te ver novamente por aqui! No momento, sua conta
      está desativada e precisamos que você confirme que está disponível
      para receber novos projetos. Você pode voltar a desativar e ativar
      sua conta quando quiser."
      username={state.user.full_name}
      type="experts"
    >
      <Button text="Estou disponível!" type="submit" onClick={onSubmit} />
    </Dashboard>
  )
}

export default AccountActivate
