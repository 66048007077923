import React from 'react'
import PropTypes from 'prop-types'

import styles from '../../styles.module.css'

function WelcomeUser({ domainArea, user }) {
  return (
    <div className={styles.welcome}>
      <h1 className={styles.welcome_name}>
        Olá,&nbsp;
        {user?.full_name}
        !
      </h1>
      <div className={styles.welcome_doamin}>
        <div className={styles.welcome_doamin_item}>
          <span className={styles.welcome_doamin_label}>Domínio:</span>
          <span>{domainArea?.label}</span>
        </div>
        <div className={styles.welcome_doamin_item}>
          <span className={styles.welcome_doamin_label}>Subdomínio:</span>
          <span>{user?.sub_domain?.title}</span>
        </div>
      </div>
    </div>
  )
}

WelcomeUser.propTypes = {
  domainArea: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
}

export default WelcomeUser
