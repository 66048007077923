import React from 'react'
import routesNames from 'routes/routesNames'
import ProjectSection from 'components/ProjectSection'
import ProjectBox from 'components/ProjectBox'
import ProjectModal from 'components/ProjectModal'
import LoggedInTemplate from 'templates/LoggedInTemplate'
import { Condition, Button } from 'components'
import MessageWarning from 'components/MessageWarning'
import WelcomeUser from './components/WelcomeUser'
import BannerRefer from './components/BannerRefer'
import NoProjectsBox from './components/NoProjectsBox'
import styles from './styles.module.css'
import useHome from './hooks'

function Home() {
  const {
    banner,
    domainArea,
    handleRedirect,
    handleCheckProfile,
    isOpenProjectModal,
    itensDescubraMais,
    myProjectsList,
    onClickCloseModal,
    onClickOpenModal,
    projectApplication,
    projectDetail,
    user,
    showUpdateWarning,
  } = useHome()

  // console.log("myProjectsList: ", myProjectsList)
  // console.log("showUpdateWarning: ", showUpdateWarning)

  return (
    <LoggedInTemplate>
      <main className={styles.main}>

        <Condition condition={showUpdateWarning}>
          <div className={styles.update_profile_warning_box}>
            <div className={styles.container}>
              <MessageWarning
                text="Aviso! Você está a 60 dias sem atualizar seu perfil. Verifique se as informações estão atualizadas para continuar recebendo oportunidades."
              />
              <br />
              <Button
                className={styles.project_box_btn}
                text="Verificar"
                fill="outline"
                expand="x"
                size="small"
                onClick={handleCheckProfile}
              />
            </div>
          </div>
          <br />
        </Condition>
        <div className={styles.section_profile}>
          <div className={styles.container}>
            <WelcomeUser user={user} domainArea={domainArea} />
            <BannerRefer data={banner} />
            <ProjectSection
              title="Meus projetos mais recentes"
              linkShowMore={routesNames.experts.myProjects}
              showQuantity
              dataSource={myProjectsList || []}
              perPage={4}
              renderItem={(item) => (
                <ProjectBox
                  key={item.id}
                  title={item.title}
                  label={item.status_allocation}
                  onClick={onClickOpenModal(item.id)}
                />
              )}
              renderElse={<NoProjectsBox />}
            />
            <ProjectModal
              projectApplication={projectApplication || {}}
              projectDetail={projectDetail || {}}
              show={isOpenProjectModal}
              onHide={onClickCloseModal}
              handleRedirect={handleRedirect(projectApplication?.opportunity)}
            />
          </div>
        </div>
        <div className={styles.section_links}>
          <div className={styles.container}>
            <ProjectSection
              title="Descubra mais"
              desc="Separamos alguns materiais e links úteis especialmente para você!"
              dataSource={itensDescubraMais}
              renderItem={(item) => (
                <ProjectBox
                  key={item.id}
                  title={item.title}
                  desc={item.desc}
                  buttonText="Saber mais"
                  image={item.image}
                  href={item.href}
                />
              )}
              renderElse={<div className={styles.blank} />}
            />
          </div>
        </div>
      </main>
    </LoggedInTemplate>
  )
}

export default Home
