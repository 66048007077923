import { GET } from '../../utils/helpers/request'

const customers = {
  me: (token) => {
    const endpoint = 'customers/me/'
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
}

export default customers
