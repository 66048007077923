import React from 'react'
import routesNames from 'routes/routesNames'
import Hero from 'components/Hero'
import ProjectBox from 'components/ProjectBox'
import ProjectSection from 'components/ProjectSection'
import LoggedInTemplate from 'templates/LoggedInTemplate'
import convertToReadableDate from 'utils/helpers/convertToReadableDate'
import { ReactComponent as IllustraMyProjects } from 'assets/img/illustra-my-projects.svg'
import useMyProjects from './hooks'
import styles from './styles.module.css'

function MyProjects() {
  const { myActiveProjectsList, myInactiveProjectsList } = useMyProjects()

  // console.log("myActiveProjectsList: ", myActiveProjectsList)
  // console.log("myInactiveProjectsList: ", myInactiveProjectsList)

  return (
    <LoggedInTemplate>
      <main>
        <Hero
          title="Meus Projetos"
          desc="Veja mais detalhes e acompanhe a sua candidatura
          em novas oportunidades."
          image={IllustraMyProjects}
        />
        <div className={styles.section}>
          <div className={styles.container}>
            <ProjectSection
              title="Candidatura Ativa"
              dataSource={myActiveProjectsList || []}
              renderItem={(item) => (
                <ProjectBox
                  buttonText="Acessar"
                  key={item.key}
                  title={item.title}
                  /* label={item.status_allocation} */
                  domainArea={item?.domain_area?.title}
                  href={`${routesNames.experts.myProject}/${item.id}`}
                  startDate={convertToReadableDate(item?.start_date) || '-'}
                  allocationStatus={item?.status_allocation || '-'}
                />
              )}
            />
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.container}>
            <ProjectSection
              title="Candidatura Concluída"
              dataSource={myInactiveProjectsList || []}
              renderItem={(item) => (
                <ProjectBox
                  buttonText="Acessar"
                  key={item.key}
                  title={item.title}
                  /* label={item.status_allocation} */
                  domainArea={item?.domain_area?.title}
                  href={`${routesNames.experts.myProject}/${item.id}`}
                  startDate={convertToReadableDate(item?.start_date) || '-'}
                  allocationStatus={item?.status_allocation || '-'}
                />
              )}
            />
          </div>
        </div>
      </main>
    </LoggedInTemplate>
  )
}

export default MyProjects
