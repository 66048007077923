import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { CustomersHome as CustomersHomeContainer } from '../../../containers'
import { useUsername } from '../../../context/username'
import killSession from '../../../utils/helpers/killSession'
import routesNames from '../../../routes/routesNames'
import makeRequestMergingPagination from '../../../utils/helpers/request/makeRequestMergingPagination'
import businessService from '../../../services/business'
import {
  getAuthToken,
  getCustomersProjectId,
} from '../../../utils/local-storage'

function Home() {
  const [loadingPendencies, setLoadingPendencies] = useState(true)
  const [pendencies, setPendencies] = useState([])
  const [loadingRoadmap, setLoadingRoadmap] = useState(true)
  const [roadmap, setRoadmap] = useState(null)
  const [loadingTasks, setLoadingTasks] = useState(true)
  const [tasks, setTasks] = useState([])
  const history = useHistory()
  const { state } = useUsername()

  useEffect(() => {
    const token = getAuthToken()
    const projectId = getCustomersProjectId()
    const getPendencies = async () => {
      const requestToDo = () => businessService.projects_id_dependences(token, projectId)
      const dependencies = await makeRequestMergingPagination(requestToDo)

      return dependencies
    }
    const getRoadmap = async () => {
      const { roadmap: roadmapFromRequest } = await businessService.projects_id(
        token,
        projectId,
      )

      return roadmapFromRequest
    }
    const getTasks = async () => {
      const requestToDo = () => businessService.projects_id_tasks(token, projectId)
      const tasksFromRequest = await makeRequestMergingPagination(requestToDo)
      const actualSprintTasks = tasksFromRequest.filter(
        (item) => item.sprint.state !== 'closed',
      )

      return actualSprintTasks
    }
    const makeRequests = async () => {
      getPendencies()
        .then((response) => setPendencies(response))
        .finally(() => setLoadingPendencies(false))

      getRoadmap()
        .then((response) => setRoadmap(response))
        .finally(() => setLoadingRoadmap(false))

      getTasks()
        .then((response) => setTasks(response))
        .finally(() => setLoadingTasks(false))
    }

    makeRequests()
  }, [state.user])

  function onExit() {
    killSession()

    history.push(routesNames.customers.login)
  }

  return (
    <CustomersHomeContainer
      username={state.user.full_name}
      onExit={onExit}
      loadingPendencies={loadingPendencies}
      pendencies={pendencies}
      loadingRoadmap={loadingRoadmap}
      roadmap={roadmap}
      loadingTasks={loadingTasks}
      tasks={tasks}
    />
  )
}

export default Home
