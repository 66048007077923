function statusType(value) {
  const mapper = {
    active: 'Em andamento',
    closed: 'Fechado',
    frozen: 'Congelado',
  }
  return mapper[value]
}

function buttonType(interested) {
  if (interested) {
    return 'flat'
  }

  return 'outline'
}

function styleType(status, interested) {
  if (status === 'frozen') {
    return 'frozen'
  }

  if (interested) {
    return 'interested'
  }

  return status
}

function disclaimerType(status, canRegister) {
  const mapper = {
    cannot:
      'Seu perfil cadastrado não possui os requisitos necessários para a vaga. ',
    closed: 'Esta vaga se encontra fechada no momento.',
    frozen: 'Esta vaga se encontra congelada no momento.',
  }

  if (status === 'closed') {
    return mapper.closed
  }

  if (status === 'frozen') {
    return mapper.frozen
  }

  if (!canRegister) {
    return mapper.cannot
  }

  return mapper[status]
}

function arrayToCommas(data) {
  if (!data) return null

  const toComma = (acc, curr) => {
    const key = curr.skill_title ? 'skill_title' : 'title'

    return acc.concat(curr[key] ? curr[key] : curr)
  }
  const result = data.reduce(toComma, [])

  return result.join(', ')
}

export {
  arrayToCommas, buttonType, disclaimerType, statusType, styleType, 
}
