import { useHistory } from 'react-router-dom'
import { useUsername } from 'context/username'
import killSession from 'utils/helpers/killSession'
import routesNames from 'routes/routesNames'

const useLoggedInTemplate = () => {
  const history = useHistory()
  const { state } = useUsername()

  function onExit(e) {
    e.preventDefault()
    killSession()

    history.push(routesNames.experts.login)
  }

  return {
    onExit,
    state,
  }
}

export default useLoggedInTemplate
