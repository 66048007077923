import React, { memo } from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.css'
import LogoIcon from '../../assets/img/logo.svg'

function Header({ onExit, username }) {
  return (
    <header className={styles.header}>
      <div className={styles.content}>
        <img
          className={styles.logo}
          src={LogoIcon}
          alt="Logo da Cognitivo escrito cognitivo"
        />
        <div>
          <p className={styles.username}>{username}</p>
          <span
            className={styles.exit_button}
            onClick={onExit}
            onKeyPress={onExit}
            role="button"
            tabIndex="0"
          >
            Sair
          </span>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  onExit: PropTypes.func.isRequired,
  username: PropTypes.string,
}

Header.defaultProps = {
  username: null,
}

export default memo(Header)
