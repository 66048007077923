import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { CustomersSelectProject as CustomersSelectProjectContainer } from 'containers'
import { useUsername } from 'context/username'
import { useLoading } from 'context/loading'
import { getAuthToken, setCustomersProjectId } from 'utils/local-storage'
import routesNames from 'routes/routesNames'
import makeRequestMergingPagination from 'utils/helpers/request/makeRequestMergingPagination'
import formatOptionsFromRequest from 'utils/helpers/formatOptionsFromRequest'
import businessService from 'services/business'

function SelectProject() {
  const history = useHistory()
  const [projects, setProjects] = useState([])
  const { dispatch: dispatchLoading } = useLoading()
  const { state } = useUsername()

  function onClick(value) {
    setCustomersProjectId(value)

    history.push(routesNames.customers.home)
  }

  useEffect(() => {
    const makeRequestGetProjects = async () => {
      dispatchLoading({
        payload: true,
        type: 'set',
      })

      try {
        const token = getAuthToken()
        const requestToDo = () => businessService.projects(token)
        const result = await makeRequestMergingPagination(requestToDo)
        const formatedResult = formatOptionsFromRequest(result)

        setProjects(formatedResult)

        dispatchLoading({
          payload: false,
          type: 'set',
        })
      } catch (err) {
        dispatchLoading({
          payload: false,
          type: 'set',
        })
      }
    }

    makeRequestGetProjects()
  }, [dispatchLoading])

  return (
    <CustomersSelectProjectContainer
      username={state.user.full_name}
      onClick={onClick}
      options={projects}
    />
  )
}

export default SelectProject
