// import md5 from 'crypto-js/md5'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import killSession from 'utils/helpers/killSession'
import routesNames from 'routes/routesNames'

const useNavUser = ({
  // email,
  state,
}) => {
  const [gravatar, setGravatar] = useState(null)
  const [initialsName, setInitialsName] = useState()
  // const hash = md5(email?.toLowerCase().trim())
  const history = useHistory()

  function onExit(e) {
    e.preventDefault()
    killSession()

    history.push(routesNames.customers.login)
  }

  // const makeRequestGravatar = useCallback(async () => (
  //   fetch(`https://gravatar.com/avatar/${hash}?size=40&d=404`)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setGravatar(res.url)
  //       }
  //     })
  //     .catch((err) => {
  //       throw new Error(`Error request gravatar image ${err}`)
  //     })
  // ), [hash])
  // async function makeRequestGravatar () {
  //   await fetch(`https://gravatar.com/avatar/${hash}?size=40&d=404`)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setGravatar(res.url)
  //       }
  //     })
  //     .catch((err) => {
  //       throw new Error(`Error request gravatar image ${err}`)
  //     })
  // }

  const getNameInitials = useCallback(() => {
    const fullName = state?.user?.full_name
    if (fullName) {
      const [name, secondName] = fullName.split(' ')
      setInitialsName(`${name?.charAt(0)}${secondName?.charAt()}`)
    }
  }, [state?.user?.full_name])

  useEffect(() => {
    setGravatar(state?.user?.profile_photo)
    // makeRequestGravatar()
  }, [state?.user?.profile_photo])

  useEffect(() => {
    getNameInitials()
  }, [getNameInitials])

  return {
    gravatar,
    initialsName,
    onExit,
  }
}

export default useNavUser
