import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Alert from '../Alert'

function FormError({ className, hasErrors, message }) {
  return (
    hasErrors && (
      <Alert className={className} severity="error">
        {message
          || 'Houve um problema ao salvar a conta. Tente novamente mais tarde.'}
      </Alert>
    )
  )
}

FormError.propTypes = {
  className: PropTypes.node,
  hasErrors: PropTypes.bool.isRequired,
  message: PropTypes.string,
}

FormError.defaultProps = {
  className: null,
  message: null,
}

export default memo(FormError)
