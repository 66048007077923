import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.css'

function Hero({ desc, image: ComponentImage, title }) {
  return (
    <div className={styles.hero}>
      <div className={styles.hero_container}>
        <figure className={styles.hero_figure}>
          <ComponentImage />
        </figure>
        <div className={styles.hero_text}>
          <h1 className={styles.hero_title}>{title}</h1>
          <p className={styles.hero_desc}>{desc}</p>
        </div>
      </div>
    </div>
  )
}

Hero.propTypes = {
  desc: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.shape(),
  ]).isRequired,
  title: PropTypes.string.isRequired,
}

export default memo(Hero)
