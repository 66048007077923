import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

import { getIsAuth, getCustomersProjectId } from '../../utils/local-storage'
import routesNames from '../routesNames'

const hasAccess = (Component, isAuth, props, type, path) => {
  if (!isAuth) {
    return <Redirect to={routesNames[type].login} />
  }

  if (
    !getCustomersProjectId()
    && type === 'customers'
    && path !== routesNames.customers.selectProject
  ) {
    return <Redirect to={routesNames[type].selectProject} />
  }

  return <Component {...props} />
}

function ProtectedRoute({ component: Component, type, ...rest }) {
  const isAuth = getIsAuth()

  return (
    <Route
      render={(props) => hasAccess(
        // eslint-disable-line react/jsx-no-bind
        Component,
        isAuth,
        props,
        type,
        rest.path,
      )}
      {...rest}
    />
  )
}

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  type: PropTypes.oneOf(['experts', 'customers']).isRequired,
}

export default ProtectedRoute
