import {
  GET, POST, PUT, DELETE, 
} from '../../utils/helpers/request'

const availability = {
  delete: (token, id) => {
    const endpoint = `experts/availability/${id}`
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return DELETE(endpoint, config)
  },
  get: (token) => {
    const endpoint = 'experts/availability/'
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
  post: (payload, token) => {
    const endpoint = 'experts/availability/'
    const config = {
      body: JSON.stringify(payload),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return POST(endpoint, config)
  },
  put: (payload, token, id) => {
    const endpoint = `experts/availability/${id}`
    const config = {
      body: JSON.stringify(payload),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return PUT(endpoint, config)
  },
}

export default availability
