import React from 'react'
import { useHistory } from 'react-router-dom'
import { useLoading } from 'context/loading'
import { useUsername } from 'context/username'
import expertService from 'services/experts'
import killSession from 'utils/helpers/killSession'
import routesNames from 'routes/routesNames'
import { ExpertsAccountDeactivate as ExpertsAccountDeactivateContainer } from 'containers'
import { getAuthToken } from 'utils/local-storage'
import { useFeedback } from 'context/feedback'

function AccountDeactivate() {
  const history = useHistory()
  const { dispatch: dispatchUser, state } = useUsername()
  const { dispatch: dispatchLoading } = useLoading()
  const { dispatch: dispatchFeedback } = useFeedback()

  function onExit() {
    killSession()

    history.push(routesNames.experts.login)
  }

  function onSubmit(values, setHasErrors) {
    const token = getAuthToken()
    const cleanValues = {
      how_long: values.how_long,
      reason: values.reason,
    }

    dispatchLoading({
      payload: true,
      type: 'set',
    })

    expertService.account
      .inactive(cleanValues, token)
      .then((result) => {
        if (result.status === 'inactive') {
          dispatchUser({
            payload: {
              ...state.user,
              status_type: result.status,
            },
            type: 'set',
          })

          dispatchFeedback({
            payload: {
              message: 'Conta desativada com sucesso!',
              open: true,
              severity: 'success',
            },
            type: 'set',
          })

          dispatchLoading({
            payload: false,
            type: 'set',
          })

          history.push(routesNames.experts.account)
        }
      })
      .catch(() => {
        setHasErrors({
          message: 'Não foi possível desativar a conta.',
          show: true,
        })
        dispatchLoading({
          payload: false,
          type: 'set',
        })
      })
  }

  return (
    <ExpertsAccountDeactivateContainer
      onExit={onExit}
      onSubmit={onSubmit}
      user={state.user}
    />
  )
}

export default AccountDeactivate
