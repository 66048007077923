const data = [
  {
    buttons: [{ text: 'Vamos', type: 'next' }],
    desc:
      'Esta é a plataforma Cognitivo! Como é a sua primeira vez nesse ambiente, vamos descobrir o que você pode fazer aqui?',
    id: 0,
    title: 'Boas-vindas!',
  },
  {
    buttons: [{ text: 'Próximo', type: 'next' }],
    desc:
      'Tudo que você precisa saber como expert <span>está no mesmo lugar!</span> Metodologias, ferramentas, contatos, entre outros.',
    id: 1,
    title: 'Onboarding na Cognitivo',
  },
  {
    buttons: [
      { text: 'Voltar', type: 'prev' },
      { text: 'Próximo', type: 'next' },
    ],
    desc:
      'Atualize a disponibilidade de tempo semanal de trabalho como expert. É um dos pontos importantes <span>para te indicarmos projetos.</span>',
    id: 2,
    title: 'Atualização da disponibilidade',
  },
  {
    buttons: [
      { text: 'Voltar', type: 'prev' },
      { text: 'Próximo', type: 'next' },
    ],
    desc:
      'Deixe suas informações de cadastro sempre atualizadas. Esse é outro fator fundamental para você receber as <span>melhores oportunidades!</span>',
    id: 3,
    title: 'Atualização do cadastro',
  },
  {
    buttons: [
      { text: 'Voltar', type: 'prev' },
      { text: 'Próximo', type: 'next' },
    ],
    desc:
      'Todos os projetos da Cognitivo estão na vitrine <span>pra você se candidatar</span>. Mas atenção: as vagas têm que dar match com o seu cadastro.',
    id: 4,
    title: 'Vitrine de oportunidades',
  },
  {
    buttons: [
      { text: 'Voltar', type: 'prev' },
      { text: 'Quero começar', type: 'finish' },
    ],
    desc:
      'Conhece pessoas que tenham interesse em ser um expert? Aqui você pode falar pra gente! <span>Sua indicação será prioridade no recrutamento</span>.',
    id: 5,
    title: 'Indicação de pessoas',
  },
]

export default data
