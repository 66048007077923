import { React } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import Modal from 'components/Modal'
import Tag from 'components/Tag'
import convertToReadableDate from 'utils/helpers/convertToReadableDate'
import Condition from 'components/Condition'
import { statusTexts, statusTypes } from 'components/ProjectBox/helpers'
import arrayToCommas from 'utils/helpers/arrayToCommas'
import { ReactComponent as ArrowRightMini } from 'assets/img/arrow-right-mini.svg'
import styles from './styles.module.css'
import parseHTML from './helpers'

function ProjectModal({
  handleApply,
  handleRedirect,
  onHide,
  projectApplication,
  projectDetail,
  show,
}) {
  return (
    <Modal onHide={onHide} show={show}>
      <div className={styles.project_modal}>
        <div className={styles.project_modal_header}>
          <Tag
            text={statusTexts(
              projectApplication?.status || projectDetail?.status,
            )}
            type={statusTypes(
              projectApplication?.status || projectDetail?.status,
            )}
          />
          <h2 className={styles.project_modal_title}>{projectDetail.title}</h2>
        </div>
        <div className={styles.project_modal_section}>
          <div className={styles.project_modal_item}>
            <span className={styles.project_modal_label}>Segmento</span>
            <p className={styles.project_modal_text}>
              {arrayToCommas(projectDetail.business_segments)}
            </p>
          </div>
          <div className={styles.project_modal_item}>
            <span className={styles.project_modal_label}>Data Início</span>
            <p className={styles.project_modal_text}>
              {convertToReadableDate(projectDetail?.start_date)}
            </p>
          </div>
          <div className={styles.project_modal_item}>
            <span className={styles.project_modal_label}>
              Previsão de conclusão
            </span>
            <p className={styles.project_modal_text}>
              {convertToReadableDate(projectDetail?.end_date)}
            </p>
          </div>
          <div className={styles.project_modal_item}>
            <span className={styles.project_modal_label}>
              Dedicação em horas
            </span>
            <p className={styles.project_modal_text}>
              {projectDetail.workload}
              {' '}
              hora(s) semanal(is)
            </p>
          </div>
          <div className={styles.project_modal_item}>
            <span className={styles.project_modal_label}>Valor/hora</span>
            <p className={styles.project_modal_text}>
              R$ 
              {' '}
              {projectDetail?.hourly_value}
              /h
            </p>
          </div>
          <div className={styles.project_modal_item}>
            <span className={styles.project_modal_label}>Número de vagas</span>
            <p className={styles.project_modal_text}>{projectDetail.qty}</p>
          </div>
          <div className={styles.project_modal_item}>
            <span className={styles.project_modal_label}>Sobre</span>
            {projectDetail && parseHTML(projectDetail?.description)}
          </div>
        </div>
        <div className={styles.project_modal_section}>
          <h3 className={styles.project_modal_subtitle}>Pré Requisitos</h3>
          <div className={styles.project_modal_item}>
            <span className={styles.project_modal_label}>Área de domínio</span>
            <p className={styles.project_modal_text}>
              {projectDetail?.domain_area?.title}
            </p>
          </div>
          <div className={styles.project_modal_item}>
            <span className={styles.project_modal_label}>Linguagens</span>
            <p className={styles.project_modal_text}>
              {arrayToCommas(projectDetail.languages)}
            </p>
          </div>
          <div className={styles.project_modal_item}>
            <span className={styles.project_modal_label}>
              Ferramentas e plataformas
            </span>
            <p className={styles.project_modal_text}>
              {arrayToCommas(projectDetail?.tools_platforms)}
            </p>
          </div>
          <div className={styles.project_modal_item}>
            <span className={styles.project_modal_label}>
              Armazenamento de dados
            </span>
            <p className={styles.project_modal_text}>
              {arrayToCommas(projectDetail?.data_storage)}
            </p>
          </div>
          <div className={styles.project_modal_item}>
            <span className={styles.project_modal_label}>
              Infraestrutura e Cloud
            </span>
            <p className={styles.project_modal_text}>
              {arrayToCommas(projectDetail?.infras_clouds)}
            </p>
          </div>
          <div className={styles.project_modal_item}>
            <span className={styles.project_modal_label}>
              Atividades e conceitos
            </span>
            <p className={styles.project_modal_text}>
              {arrayToCommas(projectDetail?.activities_concepts)}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.project_modal_buttons}>
        <Button
          fill="outline"
          type="button"
          text="Voltar"
          icon={<ArrowRightMini />}
          onClick={onHide}
        />
        <Condition condition={!!handleRedirect}>
          <Button
            fill="flat"
            type="button"
            text="Acompanhar"
            onClick={handleRedirect}
          />
        </Condition>
        <Condition condition={!!handleApply}>
          <Button
            fill="flat"
            type="button"
            disabled={projectDetail?.status === 'frozen'}
            text="Quero participar"
            onClick={handleApply}
          />
        </Condition>
      </div>
    </Modal>
  )
}

ProjectModal.propTypes = {
  handleApply: PropTypes.func,
  handleRedirect: PropTypes.func,
  onHide: PropTypes.func.isRequired,
  projectApplication: PropTypes.shape(),
  projectDetail: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape()),
  ]).isRequired,
  show: PropTypes.bool.isRequired,
}

ProjectModal.defaultProps = {
  handleApply: null,
  handleRedirect: null,
  projectApplication: {},
}

export default ProjectModal
