import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import styles from './styles.module.css'

export default function SuccessSent({ handleToggleSent }) {
  return (
    <div className={styles.success_sent}>
      <h2 className={styles.success_sent_title}>Indicação realizada :)</h2>
      <p className={styles.success_sent_desc}>
        Agora nossa equipe de Comunidade irá avaliar o perfil e entrará em
        contato com a pessoa indicada assim que surgir uma vaga para o perfil.
      </p>
      <Button
        className={styles.success_sent_btn}
        fill="outline"
        text="Indicar outro expert"
        type="button"
        onClick={handleToggleSent}
      />
    </div>
  )
}

SuccessSent.propTypes = {
  handleToggleSent: PropTypes.func.isRequired,
}
