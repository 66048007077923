import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { CustomersTasks as CustomersTasksContainer } from '../../../containers'
import { useUsername } from '../../../context/username'
import killSession from '../../../utils/helpers/killSession'
import routesNames from '../../../routes/routesNames'
import makeRequestMergingPagination from '../../../utils/helpers/request/makeRequestMergingPagination'
import businessService from '../../../services/business'
import {
  getAuthToken,
  getCustomersProjectId,
} from '../../../utils/local-storage'

function Tasks() {
  const [loading, setLoading] = useState(true)
  const [lastSprintTasks, setLastSprintTasks] = useState([])
  const [currentSprintTasks, setCurrentSprintTasks] = useState([])
  const history = useHistory()
  const { state } = useUsername()

  useEffect(() => {
    const getTasks = () => {
      const token = getAuthToken()
      const projectId = getCustomersProjectId()
      const requestToDo = () => businessService.projects_id_tasks(token, projectId)
      makeRequestMergingPagination(requestToDo)
        .then((response) => {
          const formatedCurrentSprintTasks = response.filter(
            (item) => item.sprint.state !== 'closed',
          )
          const formatedLastSprintTasks = response.filter(
            (item) => item.sprint.state === 'closed',
          )

          setCurrentSprintTasks(formatedCurrentSprintTasks)
          setLastSprintTasks(formatedLastSprintTasks)
        })
        .finally(() => setLoading(false))
    }

    getTasks()
  }, [state.user])

  function onExit() {
    killSession()

    history.push(routesNames.customers.login)
  }

  return (
    <CustomersTasksContainer
      username={state.user.full_name}
      onExit={onExit}
      currentSprintTasks={currentSprintTasks}
      lastSprintTasks={lastSprintTasks}
      loading={loading}
    />
  )
}

export default Tasks
