const baseName = 'cognitivo_plataforma_'

const IS_AUTH = `${baseName}is_auth`
const AUTH_TOKEN = `${baseName}auth_token`
const AUTH_SESSION_CREATED = `${baseName}auth_session_created`
const CUSTOMERS_PROJECT_ID = `${baseName}customers_project_id`
const EXPERT_EMAIL = `${baseName}expert_email`

export function getIsAuth() {
  return localStorage.getItem(IS_AUTH) === 'true'
}

export function setIsAuth(value) {
  return localStorage.setItem(IS_AUTH, String(value))
}

export function removeIsAuth() {
  return localStorage.removeItem(IS_AUTH)
}

export function getAuthToken() {
  return localStorage.getItem(AUTH_TOKEN)
}

export function setAuthToken(value) {
  return localStorage.setItem(AUTH_TOKEN, String(value))
}

export function removeAuthToken() {
  return localStorage.removeItem(AUTH_TOKEN)
}

export function setAuthSessionCreated(value) {
  return localStorage.setItem(AUTH_SESSION_CREATED, String(value))
}

export function getAuthSessionCreated() {
  return localStorage.getItem(AUTH_SESSION_CREATED)
}

export function removeAuthSessionCreated() {
  return localStorage.removeItem(AUTH_SESSION_CREATED)
}

export function getCustomersProjectId() {
  return localStorage.getItem(CUSTOMERS_PROJECT_ID)
}

export function setCustomersProjectId(value) {
  return localStorage.setItem(CUSTOMERS_PROJECT_ID, String(value))
}

export function removeCustomersProjectId() {
  return localStorage.removeItem(CUSTOMERS_PROJECT_ID)
}

export function setExpertEmail(value) {
  return localStorage.setItem(EXPERT_EMAIL, String(value))
}

export function getExpertEmail() {
  return localStorage.getItem(EXPERT_EMAIL)
}
