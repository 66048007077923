import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.css'

function Tag({ text, type, wide }) {
  if (wide) {
    return (
      <span className={classNames(styles.tag, styles.wide, styles[`tag_${type}`])}>
        {text}
      </span>
    )
  }
  return (
    <span className={classNames(styles.tag, styles[`tag_${type}`])}>
      {text}
    </span>
  )
}

Tag.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  wide: PropTypes.bool,
}

Tag.defaultProps = {
  text: '',
  type: '',
  wide: false,
}

export default Tag
