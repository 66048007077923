import { ReactComponent as IconHome } from 'assets/img/icon-home.svg'
import { ReactComponent as IconSearch } from 'assets/img/icon-search.svg'
import { ReactComponent as IconPersonPin } from 'assets/img/icon-person-pin.svg'
import { ReactComponent as IconFolder } from 'assets/img/icon-folder.svg'
import routesNames from 'routes/routesNames'

const items = [
  {
    icon: IconHome,
    link: routesNames.experts.home,
    name: 'Home',
  },
  {
    icon: IconSearch,
    link: routesNames.experts.showcaseOpportunities,
    name: 'Vitrine',
  },
  {
    icon: IconPersonPin,
    link: routesNames.experts.referExpert,
    name: 'Indicação',
  },
  {
    icon: IconFolder,
    link: routesNames.experts.myProjects,
    name: 'Meus Projetos',
  },
]

export default items
