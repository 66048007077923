import React from 'react'
import { removeTagBr } from 'pages/Experts/MyProject/helpers'

/**
 * Transforma uma string em uma árvore de elementos HTML.
 * @param {string} htmlString A string que será trasnformada em árvore.
 * @returns HTMLElement
 */

const parseHTML = (htmlString) => {
  const doc = new DOMParser().parseFromString(
    removeTagBr(htmlString),
    'text/html',
  )
  const children = Array.from(doc.body.childNodes)
  return children.map((child) => {
    if (child.nodeName === '#text') {
      return child.textContent
    }

    return React.createElement(
      child.tagName.toLowerCase(),
      { key: child.id ?? '' },
      parseHTML(child.innerHTML),
    )
  })
}

export default parseHTML
