import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles.module.css'

export default function Grid({ children, column }) {
  const classesGrid = classNames(styles.grid, styles[`grid_column_${column}`])

  return <div className={classesGrid}>{children}</div>
}

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  column: PropTypes.string,
}

Grid.defaultProps = {
  column: 'four',
}
