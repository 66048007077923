import { getAuthToken } from '../../local-storage'

const makeGetRequest = (url) => {
  const token = getAuthToken()
  const headers = {
    Authorization: `JWT ${token}`,
    'Content-type': 'application/json; charset=UTF-8',
  }

  return fetch(url, headers)
}

const makeRequestToNextUrl = async (url) => {
  const { results } = await makeGetRequest(url)
  let nextResults = []

  if (results.next) {
    nextResults = await makeRequestToNextUrl(results.next)
  }

  return [...results, nextResults]
}

const makeRequestToService = async (requestToDo) => {
  const { results } = await requestToDo()

  let nextResults = []

  if (results.next) {
    nextResults = await makeRequestToNextUrl(results.next)
  }

  const mergedResults = [...results, ...nextResults]

  return mergedResults
}

const makeRequestMergingPagination = async (requestToDo) => {
  const result = await makeRequestToService(requestToDo)

  return result
}

export default makeRequestMergingPagination
