import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'

import {
  // Deactivate,
  Loading,
  Feedback,
  Welcome,
} from '../components'
import { useLoading } from '../context/loading'
import { useFeedback } from '../context/feedback'
import { useUsernameState } from '../context/username'
import Root from './Root'

function Router() {
  const { state: stateLoading } = useLoading()
  const { state: stateFeedback } = useFeedback()
  const { user: stateUser } = useUsernameState()

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading isLoading />}>
        <Loading isLoading={stateLoading.loading}>
          {/* <Deactivate
            userState={stateUser?.id}
            userDomainArea={stateUser?.status_type && stateUser?.domain_area}
            userRole={stateUser?.status_type && stateUser?.role}
            userStatus={stateUser?.status_type}
          /> */}
          <Feedback
            isOpen={stateFeedback.open}
            severity={stateFeedback.severity}
            message={stateFeedback.message}
          />
          <Welcome firstAccess={stateUser?.first_access} />
          <Root />
        </Loading>
      </Suspense>
    </BrowserRouter>
  )
}

export default Router
