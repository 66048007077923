/* eslint-disable */
import React from 'react'
import { Link } from 'react-router-dom'
import routesNames from 'routes/routesNames'
import PropTypes from 'prop-types'

import { Button, Input, FormBox } from 'components'
import { DataPointsCuate } from '../Illustration'
import { ReactComponent as ArrowRightMini } from 'assets/img/arrow-right-mini.svg'
import styles from './styles.module.css'

const TemplateLogin = ({
  handleChange,
  handleKeyUp,
  handleOnSignin,
  handleOnSignup,
  loginError,
}) => (
  <div className={styles.page}>
    <div className={styles.illustration}>
      <DataPointsCuate />
      <a className={styles.ref} href="https://storyset.com/people">
        People illustrations by Storyset
      </a>
    </div>
    <main className={styles.main}>
      <FormBox title="Faça seu login">
        <div className={styles.form}>
          <Input
            error={!!loginError}
            name="username"
            label="E-mail"
            onChange={handleChange('username')}
            onKeyUp={handleKeyUp}
            placeholder="exemplo@email.com"
            type="text"
          />
          <p className={styles.text}>
            <Link
              to={routesNames.experts.forgotPassword}
              className={styles.link}
            >
              Esqueceu sua senha
              <ArrowRightMini />
            </Link>
          </p>
          <Input
            error={!!loginError}
            name="password"
            label="Senha"
            onChange={handleChange('password')}
            placeholder="Informe sua senha"
            onKeyUp={handleKeyUp}
            type="password"
          />
          {loginError && (
            <p className={styles.login_error}>
              Usuário ou senha inválidos.Tente novamente.
            </p>
          )}
          <div>
            <Button
              className={styles.signin}
              text="Entrar"
              type="button"
              expand="x"
              onClick={handleOnSignin}
            />
            <Button
              className={styles.signin}
              fill="outline"
              color="primary"
              text="Criar conta"
              type="button"
              expand="x"
              onClick={handleOnSignup}
            />
          </div>
        </div>
      </FormBox>
    </main>
  </div>
)

TemplateLogin.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleKeyUp: PropTypes.func.isRequired,
  handleOnSignin: PropTypes.func.isRequired,
  loginError: PropTypes.bool,
}

TemplateLogin.defaultProps = {
  loginError: false,
}

export default TemplateLogin
