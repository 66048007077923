import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import styles from './styles.module.css'

function Buttons({ handleSetStep, items }) {
  return (
    <div className={styles.buttons}>
      {items.map((item) => (
        <Button
          key={item.text}
          text={item.text}
          type="button"
          color="secondary"
          fill={item.type === 'prev' ? 'outline' : 'flat'}
          onClick={handleSetStep(item.type)}
          className={item.type}
        />
      ))}
    </div>
  )
}

Buttons.propTypes = {
  handleSetStep: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
}

export default Buttons
