import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Condition from 'components/Condition'
import { ReactComponent as ArrowRightMini } from 'assets/img/arrow-right-mini.svg'
import Pagination from 'components/Pagination'
import styles from './styles.module.css'

function ProjectSection({
  dataSource = [],
  desc,
  linkShowMore,
  perPage,
  renderElse,
  renderItem,
  showPagination,
  showQuantity,
  title,
}) {
  const [sources, setSources] = useState([])
  const [items, setItems] = useState([])
  const [hasItems, setHasItems] = useState(false)
  const [fakeItems, setFakeItems] = useState([])
  const [optionsPerPage, setOptionsPerPage] = useState([])
  const [initialIndex, setInitialIndex] = useState(0)
  const [page, setPage] = useState(1)

  function filterPerPage(value) {
    const sourcePerPage = dataSource.filter(
      (_, idx) => idx <= value * page - 1 && idx >= initialIndex,
    )

    return sourcePerPage
  }

  function renderItemsComponents(data) {
    const sourceItems = data.map((item, index) => renderItem(item, index))
    return sourceItems
  }

  function handleChangePerPage(e) {
    const { value } = e.target
    const sourcePerPage = filterPerPage(Number(value))
    const itemsPerPage = renderItemsComponents(sourcePerPage)
    setSources(sourcePerPage)
    setItems(itemsPerPage)
  }

  function toArray(value) {
    return new Array(value).fill('').map((_, i) => (i + 1) * 4)
  }

  function calculateInitialIndex() {
    const url = window.location.href
    const hasQuery = url.includes('?')
    const pageNumber = hasQuery ? Number(url.split('?page=')[1]) : 1
    setPage(pageNumber)

    if (pageNumber > 1) return perPage * (pageNumber - 1)
    return 0
  }

  useEffect(() => {
    setHasItems(dataSource.length > 0)
    setOptionsPerPage(toArray(dataSource.length % 4))
    setInitialIndex(calculateInitialIndex())
  }, [dataSource]) // eslint-disable-line

  useEffect(() => {
    const sourcePerPage = filterPerPage(perPage)
    const itemsPerPage = renderItemsComponents(sourcePerPage)
    setSources(sourcePerPage)
    setItems(itemsPerPage)

    if (showQuantity) {
      const fakeArr = toArray(perPage - sourcePerPage.length)
      setFakeItems(fakeArr)
    }
  }, [dataSource]) // eslint-disable-line

  return (
    <div className={styles.project_section}>
      <div className={styles.project_section_wrap}>
        <div className={styles.project_section_header}>
          <h2 className={styles.project_section_title}>{title}</h2>
          <Condition condition={!!desc}>
            <p className={styles.project_section_desc}>{desc}</p>
          </Condition>
          <Condition condition={!!showQuantity}>
            <p className={styles.project_section_quantity}>
              Mostrando 
              {' '}
              {sources.length}
              {' '}
              de 
              {' '}
              {dataSource.length}
            </p>
          </Condition>
        </div>
        <Condition condition={!!linkShowMore}>
          <Link to={linkShowMore} className={styles.project_section_link}>
            Mostrar todos 
            {' '}
            <ArrowRightMini />
          </Link>
        </Condition>
      </div>
      <div className={styles.project_section_wrap}>
        <Condition condition={!!hasItems} elseCondition={renderElse}>
          <div className={styles.project_section_list}>
            {items}
            <Condition condition={!!fakeItems?.length}>
              {fakeItems?.map(() => (
                // eslint-disable-next-line react/jsx-key
                <div></div>
              ))}
            </Condition>
          </div>
        </Condition>

        <Condition condition={!!dataSource.length && !!showPagination}>
          <div className={styles.project_section_pagination}>
            <nav className={styles.project_paginate_per_page}>
              <span className={styles.project_paginate_per_page_text}>
                Visualização
              </span>
              <select
                className={styles.project_paginate_per_page_select}
                name="opportunities-per-page"
                onChange={handleChangePerPage}
              >
                {optionsPerPage.map((item) => (
                  <option value={item} key={`${item}-${Math.random()}`}>
                    {item}
                  </option>
                ))}
              </select>
            </nav>
            <Pagination perPage={perPage} projectsAmount={dataSource.length} />
          </div>
        </Condition>
      </div>
    </div>
  )
}

ProjectSection.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string,
      href: PropTypes.string,
      image: PropTypes.node,
      title: PropTypes.string,
    }),
  ).isRequired,
  desc: PropTypes.string,
  linkShowMore: PropTypes.string,
  perPage: PropTypes.number,
  renderElse: PropTypes.node,
  renderItem: PropTypes.func.isRequired,
  showPagination: PropTypes.bool,
  showQuantity: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

ProjectSection.defaultProps = {
  desc: '',
  linkShowMore: '',
  perPage: 8,
  renderElse: null,
  showPagination: false,
  showQuantity: false,
}

export default ProjectSection
