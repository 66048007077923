import * as Yup from 'yup'

import messages from 'utils/yup/messages'
import { isCnpj } from 'utils/validators'

Yup.addMethod(Yup.string, 'cnpj', (message) => Yup.string().test('is-cnpj', message, (value) => {
  if (!value || (value && value.length === 0)) {
    return true
  }

  return isCnpj(value) === true
}))

const schema = {
  activities_concepts: Yup.array().required(messages.string.required),
  business_segments: Yup.array(),
  cnpj: Yup.string()
    .cnpj(messages.string.cnpj)
    .min(11, messages.string.min(14)),
  company_name: Yup.string().max(90, messages.string.max(90)),
  current_company: Yup.string().min(2, messages.number.min(2)),
  data_storages: Yup.array(),
  domain_area: Yup.string(),
  infras_clouds: Yup.array().required(messages.string.required),
  languages: Yup.array().required(messages.string.required),
  libraries_frameworks: Yup.array().required(messages.string.required),
  linkedin: Yup.string().min(15, messages.string.min(15)),
  tools_platforms: Yup.array(),
  weekly_availability: Yup.number().min(1, messages.number.min(1)),
}

const yupValidator = Yup.object(schema)

export default yupValidator
