import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import authService from 'services/auth'
import expertsService from 'services/experts'
import { useLoading } from 'context/loading'
import { useUsername } from 'context/username'
import routesNames from 'routes/routesNames'
import {
  getExpertEmail,
  setIsAuth,
  setAuthToken,
  setAuthSessionCreated,
} from 'utils/local-storage'

import Schema from './schema'

const useResetPassword = ({ location }) => {
  const [hasErrors, setHasErrors] = useState(false)
  const history = useHistory()
  const { dispatch: userDispatch } = useUsername()
  const { dispatch: dispatchLoading } = useLoading()
  const token = location.search.split('token=').pop()

  function onSubmit(values) {
    const cleanValues = {
      password: values.password,
      token,
    }

    dispatchLoading({
      payload: true,
      type: 'set',
    })

    authService
      .reset_password_validate_token({ token })
      .then(({ status }) => {
        if (status === 'OK') {
          authService
            .reset_password_confirm(cleanValues)
            .then(async (data) => {
              if (data.status === 'OK') {
                try {
                  const authResult = await authService.token(
                    getExpertEmail(),
                    values.password,
                  )

                  const userResult = await expertsService.me(
                    authResult.access_token,
                  )

                  if (authResult && userResult) {
                    setIsAuth(true)
                    setAuthToken(authResult.access_token)
                    setAuthSessionCreated(new Date())

                    userDispatch({
                      payload: {
                        ...userResult,
                        type: authResult.user_type,
                      },
                      type: 'set',
                    })

                    dispatchLoading({
                      payload: false,
                      type: 'set',
                    })

                    history.push(routesNames.experts.resetPasswordSuccess)
                  }
                } catch {
                  history.push(routesNames.experts.login)
                }
              }
            })
            .catch(async (err) => {
              const errorsFromRequest = err.json && (await err.json())

              if (
                errorsFromRequest
                && errorsFromRequest.password
                && errorsFromRequest.password.some(
                  (item) => item === 'This password is entirely numeric.',
                )
              ) {
                setHasErrors({
                  message: 'A senha não pode conter apenas números.',
                  show: true,
                })
              }

              return setHasErrors({
                message: null,
                show: true,
              })
            })
            .finally(() => dispatchLoading({
              payload: false,
              type: 'set',
            }))
        }
      })
      .catch(() => {
        setHasErrors({
          message:
            'O token expirou, Insira o endereço de email associado à sua conta para enviarmos um link de redefinição de senha.',
          show: true,
        })
      })
      .finally(() => dispatchLoading({
        payload: false,
        type: 'set',
      }))
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirm: '',
      token: '',
    },
    onSubmit: (values) => onSubmit(values),
    validationSchema: Schema,
  })

  return {
    formik,
    hasErrors,
  }
}

export default useResetPassword
