import React from 'react'
import PropTypes from 'prop-types'

import { Card } from '../../../../components'
import styles from './styles.module.css'
import convertToReadableDate from '../../../../utils/helpers/convertToReadableDate'

function AgreementCard({
  createdAt,
  description,
  eyewitness,
  motivation,
  title,
}) {
  return (
    <Card title={title} className={styles.card}>
      <div className={styles.content}>
        <div className={styles.line}>
          <span className={styles.topic}>Descrição</span>
          <p className={styles.text}>{description}</p>
        </div>
        <div className={styles.line}>
          <span className={styles.topic}>Motivação</span>
          <p className={styles.text}>{motivation}</p>
        </div>
        <div className={styles.two_columns}>
          <div className={styles.line}>
            <span className={styles.topic}>Data</span>
            <p className={styles.text}>
              Acordo criado em: 
              {' '}
              {convertToReadableDate(createdAt)}
            </p>
          </div>
          <div className={styles.line}>
            <span className={styles.topic}>Envolvidos</span>
            <p className={styles.text}>{eyewitness}</p>
          </div>
        </div>
      </div>
    </Card>
  )
}

AgreementCard.propTypes = {
  createdAt: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  eyewitness: PropTypes.string.isRequired,
  motivation: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default AgreementCard
