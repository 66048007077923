import * as Yup from 'yup'

import messages from 'utils/yup/messages'

const schema = {
  confirm_new_password: Yup.string()
    .min(8, messages.string.min(8))
    .required(messages.string.required)
    .oneOf(
      [Yup.ref('new_password'), null],
      messages.string.equal_fields('senha'),
    ),
  new_password: Yup.string()
    .min(8, messages.string.min(8))
    .required(messages.string.required),
  old_password: Yup.string()
    .min(6, messages.string.min(6))
    .required(messages.string.required),
}

const yupValidator = Yup.object(schema)

export default yupValidator
