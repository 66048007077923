import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Button, Select } from 'components'
import LogoIcon from 'assets/img/logo.svg'
import styles from './styles.module.css'

function SelectProject({ onClick, options, username }) {
  const [projectId, setProjectId] = useState(null)

  function handleOnClick() {
    onClick(projectId)
  }

  function handleChange(event) {
    setProjectId(event.target.value)
  }

  return (
    <div className={styles.background}>
      <img
        className={styles.logo}
        src={LogoIcon}
        alt="Logo da Cognitivo escrito cognitivo"
      />
      <main className={styles.box}>
        <h1 className={styles.title}>
          Bem vindo,
          {username}
        </h1>
        <p className={styles.description}>
          Por favor, selecione o projeto que deseja visualizar:
        </p>
        <Select onChange={handleChange} options={options} />
        <Button
          onClick={handleOnClick}
          text="Entrar"
          disabled={!projectId}
          type="button"
        />
      </main>
    </div>
  )
}

SelectProject.propTypes = {
  onClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf().isRequired,
  username: PropTypes.string.isRequired,
}

export default SelectProject
