/* eslint-disable */
import React from 'react'
import { motion } from 'framer-motion'
import { ReactComponent as WelcomeDocs } from '../../../../assets/img/welcome-docs.svg'

import styles from './styles.module.css'

const Docs = () => (
  <motion.div
    className={styles.docs}
    initial={{
      opacity: 0,
      y: -100,
    }}
    animate={{
      opacity: 1,
      y: 0,
    }}
  >
    <WelcomeDocs />
  </motion.div>
)

export default Docs
