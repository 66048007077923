/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as IconArrowDown } from 'assets/img/icon-arrow-down-white.svg'
import { ReactComponent as IconExit } from 'assets/img/icon-exit-white.svg'
import Condition from 'components/Condition'
import DropdownMenu from '../DropdownMenu'
// import {
//   ReactComponent as IconArrowUp
// } from 'assets/img/icon-arrow-up-white.svg'
import useNavUser from './hook'
import styles from './styles.module.css'

function NavUser({ onExit, state }) {
  const { gravatar, initialsName } = useNavUser({
    email: state?.user?.email,
    state,
  })

  return (
    <div className={styles.nav_user}>
      <div className={styles.nav_user_preview} data-menu>
        <IconArrowDown />
        <span className={styles.nav_user_name}>{state?.user?.full_name}</span>
        <span className={styles.nav_user_image}>
          <Condition condition={!!gravatar} elseCondition={initialsName}>
            <img src={gravatar} alt="Imagem perfil" />
          </Condition>
        </span>
        <DropdownMenu />
      </div>
      <a
        href="#logout"
        className={styles.nav_user_logout}
        onClick={onExit}
        onKeyPress={onExit}
        role="button"
        tabIndex="0"
      >
        <IconExit />
      </a>
    </div>
  )
}

NavUser.propTypes = {
  onExit: PropTypes.func.isRequired,
  state: PropTypes.shape().isRequired,
}

export default NavUser
