// import { ReactComponent as IconHome } from 'assets/img/icon-home.svg'
// import { ReactComponent as IconSearch } from 'assets/img/icon-search.svg'
import { ReactComponent as IconPersonPin } from 'assets/img/icon-person-pin.svg'
// import { ReactComponent as IconFolder } from 'assets/img/icon-folder.svg'
import routesNames from 'routes/routesNames'

const restrictedItems = [
  {
    icon: IconPersonPin,
    link: routesNames.experts.viewReferrals,
    name: 'Ver Indicações',
  },
]

export default restrictedItems
