import React from 'react'
import PropTypes from 'prop-types'
import LoggedOutTemplate from 'templates/LoggedOutTemplate'
import TemplateLogin from './components/TemplateLogin'
import useLogin from './hooks'

function Login({ type }) {
  const {
    handleChange,
    handleKeyUp,
    handleOnSignin,
    handleOnSignup,
    loginError,
  } = useLogin({ type })

  return (
    <LoggedOutTemplate>
      <TemplateLogin
        loginError={loginError}
        handleChange={handleChange}
        handleKeyUp={handleKeyUp}
        handleOnSignin={handleOnSignin}
        handleOnSignup={handleOnSignup}
      />
    </LoggedOutTemplate>
  )
}

Login.propTypes = {
  type: PropTypes.oneOf(['experts', 'customers']).isRequired,
}

export default Login
