import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  setIsAuth,
  setAuthToken,
  setAuthSessionCreated,
} from 'utils/local-storage'
import * as services from 'services'
import { useUsername } from 'context/username'
import { useLoading } from 'context/loading'
import routesNames from 'routes/routesNames'

export const useLogin = ({ type }) => {
  const history = useHistory()
  const { dispatch: userDispatch } = useUsername()
  const { dispatch: loadingDispatch } = useLoading()
  const [formValues, setFormValues] = useState({})
  const [loginError, setLoginError] = useState(false)

  async function makeRequest(values) {
    try {
      loadingDispatch({
        payload: true,
        type: 'set',
      })

      const authResult = await services.auth.token(
        values.username,
        values.password,
      )

      const userResult = await services[type].me(authResult.access_token)

      if (authResult.access_token) {
        setIsAuth(true)
        setAuthToken(authResult.access_token)
        setAuthSessionCreated(new Date())

        userDispatch({
          payload: {
            ...userResult,
            first_access: authResult.first_access,
            type: authResult.user_type,
          },
          type: 'set',
        })

        if (type === 'customers') {
          history.push(routesNames[type].selectProject)
        } else {
          history.push(routesNames[type].home)
        }

        loadingDispatch({
          payload: false,
          type: 'set',
        })
      }

      loadingDispatch({
        payload: false,
        type: 'set',
      })

      setLoginError(
        'Houve um problema ao tentar entrar na conta. Tente novamente mais tarde.',
      )
    } catch (error) {
      loadingDispatch({
        payload: false,
        type: 'set',
      })

      setLoginError(
        'Houve um problema ao tentar entrar na conta. Tente novamente mais tarde.',
      )
    }
  }

  function handleChange(key) {
    return (event) => {
      setFormValues({
        ...formValues,
        [key]: event.target.value,
      })
    }
  }

  function handleOnSignin() {
    makeRequest(formValues)
  }

  function handleOnSignup() {
    history.push(routesNames.experts.createAccount)
  }

  function handleKeyUp(event) {
    if (event.keyCode === 13) {
      event.preventDefault()
      handleOnSignin()
    }
  }

  return {
    handleChange,
    handleKeyUp,
    handleOnSignin,
    handleOnSignup,
    loginError,
    makeRequest,
  }
}

export default useLogin
