import React from 'react'
import PropTypes from 'prop-types'
import convertToReadableDate from 'utils/helpers/convertToReadableDate'
import MoreDetail from '../MoreDetail'
import styles from './styles.module.css'

/* eslint-disable */
const Detail = ({
  activitiesAndConcepts,
  businessSegment,
  dataStorage,
  description,
  domainArea,
  endDate,
  handleToggleDetails,
  handleToggleInterest,
  hourlyValue,
  infraAndCloud,
  languages,
  projectEndDate,
  projectId,
  projectInitialDate,
  projectPayForHour,
  projectRequiredWeeklyHours,
  projectStatus,
  projectWorkersNumbers,
  showDetails,
  startDate,
  title,
  toolsAndPlataforms,
  workload,
}) => (
  <div className={styles.detail}>
    <div className={styles.detail_section}>
      <div className={styles.detail_item}>
        <span className={styles.detail_label}>Descrição do projeto</span>
        <p className={styles.detail_text}>{description || '-'}</p>
        <a
          className={styles.detail_link}
          href="/#"
          onClick={handleToggleDetails}
        >
          Ver mais detalhes
        </a>
        <MoreDetail
          activitiesAndConcepts={activitiesAndConcepts}
          businessSegment={businessSegment}
          dataStorage={dataStorage}
          description={description}
          domainArea={domainArea}
          handleToggleInterest={handleToggleInterest}
          infraAndCloud={infraAndCloud}
          languages={languages}
          onHide={handleToggleDetails}
          projectEndDate={projectEndDate}
          projectId={projectId}
          projectInitialDate={projectInitialDate}
          projectPayForHour={projectPayForHour}
          projectRequiredWeeklyHours={projectRequiredWeeklyHours}
          projectStatus={projectStatus}
          projectWorkersNumbers={projectWorkersNumbers}
          show={showDetails}
          title={title}
          toolsAndPlataforms={toolsAndPlataforms}
        />
      </div>
      <div className={styles.detail_item}>
        <span className={styles.detail_label}>Data de início</span>
        <p className={styles.detail_text}>
          {convertToReadableDate(startDate) || '-'}
        </p>

        <span className={styles.detail_label}>Dedicação de horas</span>
        <p className={styles.detail_text}>
          {projectRequiredWeeklyHours} hora(s) semanal(is)
        </p>
      </div>
      <div className={styles.detail_item}>
        <span className={styles.detail_label}>Previsão de conclusão</span>
        <p className={styles.detail_text}>
          {convertToReadableDate(endDate) || '-'}
        </p>

        <span className={styles.detail_label}>Valor/hora</span>
        <p className={styles.detail_text}>R$ {hourlyValue}/h</p>
      </div>
    </div>
  </div>
)

Detail.propTypes = {
  // signatureDate: PropTypes.string.isRequired,
  businessSegment: PropTypes.string,
  description: PropTypes.string,
  endDate: PropTypes.string,
  hourlyValue: PropTypes.string,
  projectStatus: PropTypes.string,
  startDate: PropTypes.string,
  title: PropTypes.string,
  workload: PropTypes.string,
}

Detail.defaultProps = {
  businessSegment: null,
  description: null,
  endDate: null,
  hourlyValue: null,
  projectStatus: null,
  startDate: null,
  title: null,
  workload: null,
}

export default Detail
