/* eslint-disable */
import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Condition from 'components/Condition'

import Wave from '../Svgs/Wave'
import Ellipse from '../Svgs/Ellipse'
import EllipseOutiline from '../Svgs/EllipseOutiline'
import Girl from '../Svgs/Girl'
import Signs from '../Svgs/Signs'
import Calendar from '../Svgs/Calendar'
import Docs from '../Svgs/Docs'
import Vitrine from '../Svgs/Vitrine'
import Megafone from '../Svgs/Megafone'

import styles from './styles.module.css'

const Modal = ({ children, getStepCurrent, showWelcome }) => (
  <div
    className={classNames(styles.welcome, showWelcome && styles.welcome_active)}
  >
    <div className={styles.modal}>
      <Condition condition={getStepCurrent == 0}>
        <Girl />
        <Wave />
      </Condition>

      <Condition condition={getStepCurrent > 0}>
        <Ellipse key={getStepCurrent} />
      </Condition>

      <Condition condition={getStepCurrent > 0}>
        <EllipseOutiline key={getStepCurrent} currentStep={getStepCurrent} />
      </Condition>

      <Condition condition={getStepCurrent == 1}>
        <Signs />
      </Condition>

      <Condition condition={getStepCurrent == 2}>
        <Calendar />
      </Condition>

      <Condition condition={getStepCurrent == 3}>
        <Docs />
      </Condition>

      <Condition condition={getStepCurrent == 4}>
        <Vitrine />
      </Condition>

      <Condition condition={getStepCurrent == 5}>
        <Megafone />
      </Condition>

      {children}
    </div>
  </div>
)

Modal.propTypes = {
  showWelcome: PropTypes.bool.isRequired,
}

export default Modal
