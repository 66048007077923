import { useState } from 'react'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import expertService from 'services/experts'
import routesNames from 'routes/routesNames'
import { useLoading } from 'context/loading'

import CreateAccountSchema from './schema'

const useCreateAccount = () => {
  const history = useHistory()
  const { dispatch: loadingDispatch } = useLoading()
  const [hasErrors, setHasErrors] = useState({
    message: null,
    show: false,
  })

  async function onSubmit(rawPayload) {
    loadingDispatch({
      payload: true,
      type: 'set',
    })

    let registerExpertResponse = {}

    try {
      const registerPayload = { ...rawPayload }
      delete registerPayload.curriculum
      registerExpertResponse = await expertService.register(registerPayload)

      if (rawPayload.curriculum) {
        const formData = new FormData()
        formData.append('curriculum', rawPayload.curriculum)
        formData.append('expert_id', registerExpertResponse.id)

        await expertService.uploadCv(formData)
      }

      if (registerExpertResponse.id) {
        history.push(routesNames.experts.createAccountSuccess)
      }
    } catch (err) {
      if (registerExpertResponse.id) {
        history.push(routesNames.experts.createAccountSuccess)
      }

      const asyncHandler = async () => {
        const errorsFromRequest = err.json && (await err.json())

        if (
          errorsFromRequest
          && errorsFromRequest.email
          && errorsFromRequest.email.some(
            (item) => item === 'O e-mail deve ser unico',
          )
        ) {
          return setHasErrors({
            message: 'E-mail já cadastrado',
            show: true,
          })
        }

        return setHasErrors({
          message: null,
          show: true,
        })
      }

      asyncHandler()
    }

    loadingDispatch({
      payload: false,
      type: 'set',
    })
  }

  const formik = useFormik({
    initialValues: {
      agree: false,
      cpf: '',
      email: '',
      email_confirmation: '',
      full_name: '',
      password: '',
      password_confirmation: '',
      linkedin: '',
    },
    onSubmit: (values) => onSubmit(values),
    validationSchema: CreateAccountSchema,
  })

  return {
    formik,
    hasErrors,
  }
}

export default useCreateAccount
