import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Condition from 'components/Condition'
import styles from './styles.module.css'

function existOnValues(values = [], value) {
  return values && values.find((item) => item === value)
}

function RoundedCheckbox({
  description,
  error,
  hasCheckIcon,
  label,
  name,
  onChange,
  options,
  required,
  value: currentValue,
}) {
  const [values, setValues] = useState([])

  useEffect(() => {
    if (currentValue) {
      setValues([currentValue])
    }
  }, [currentValue])

  function handleChange(event) {
    const { value } = event.target

    if (existOnValues(values, value)) {
      const index = values.indexOf(value)
      const newArr = [...values]

      if (index > -1) {
        newArr.splice(index, 1)
      }

      setValues(newArr)
      onChange(newArr)

      return true
    }

    const newArr = [...values, value]

    setValues(newArr)
    onChange(newArr)

    return null
  }

  return (
    <fieldset className={styles.fieldset}>
      <Condition condition={label}>
        <label
          className={classNames(styles.label_main, error && styles.error)}
          htmlFor={name}
        >
          {label} 
          {' '}
          {required && '*'}
        </label>
      </Condition>
      <Condition condition={description}>
        <span className={styles.description}>{description}</span>
      </Condition>
      <div>
        {options.map((option) => (
          <Fragment key={option.value}>
            <input
              className={styles.checkbox}
              id={option.label}
              name={name}
              onChange={handleChange}
              type="checkbox"
              value={option.value}
            />
            <label
              htmlFor={option.label}
              className={classNames(
                styles.label,
                existOnValues(values, option.value) && styles.active,
                hasCheckIcon && styles.labelWithCheckIcon,
              )}
            >
              {option.label}
            </label>
          </Fragment>
        ))}
      </div>
      <span className={styles.error_message}>{error}</span>
    </fieldset>
  )
}

RoundedCheckbox.propTypes = {
  description: PropTypes.string,
  error: PropTypes.string,
  hasCheckIcon: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

RoundedCheckbox.defaultProps = {
  description: null,
  error: null,
  hasCheckIcon: false,
  label: false,
  required: false,
  value: null,
}

export default RoundedCheckbox
