import { GET } from '../../utils/helpers/request'

const business = {
  projects: (token) => {
    const endpoint = 'business/projects/'
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
  projects_id: (token, id) => {
    const endpoint = `business/projects/${id}/`
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
  projects_id_agreements: (token, id) => {
    const endpoint = `business/projects/${id}/agreements/`
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
  projects_id_dependences: (token, id) => {
    const endpoint = `business/projects/${id}/dependences/`
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
  projects_id_tasks: (token, id) => {
    const endpoint = `business/projects/${id}/tasks/`
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
}

export default business
