// import killSession from 'utils/helpers/killSession'

/* eslint-disable react-hooks/rules-of-hooks */
const toJSON = (response) => {
  if (response.status === 204) {
    return response
  }

  return response.json ? response.json() : response
}

const checkStatus = (response) => (response.ok ? response : Promise.reject(response))

// const globalError = async (error) => {
//   window.newrelic?.noticeError(error)
//   const response = await error.json()

//   const isMaintenance = response.error === 'cognitivo-maintanence'
//   if (isMaintenance) {
//     killSession()
//     window.location.href = '/manutencao'
//   }

//   return error
// }

const apiFetch = (endpoint, config = {}) => fetch(endpoint, config).then(checkStatus).then(toJSON)
// .catch(globalError)

const cleanConfig = (config) => {
  const newConfig = {
    ...config,
  }

  delete newConfig.headers

  return newConfig
}

const useMethod = (methodName) => (rawEndpoint, config) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}${rawEndpoint}`
  const contentType = config.noContentType
    ? {}
    : { 'Content-type': 'application/json; charset=UTF-8' }

  const headers = {
    Accept: 'application/json',
    ...contentType,
    ...config.headers,
  }
  const cleanedConfig = cleanConfig(config)

  return apiFetch(url, {
    headers,
    method: methodName,
    ...cleanedConfig,
  })
}

const GET = useMethod('GET')
const POST = useMethod('POST')
const PUT = useMethod('PUT')
const PATCH = useMethod('PATCH')
const DELETE = useMethod('DELETE')

export {
  GET, POST, PUT, PATCH, DELETE, 
}
