/* eslint-disable */
import React, { Fragment, memo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.css'

const Categories = ({
  desc,
  disabled,
  error,
  label,
  name,
  onChange,
  options,
  value: currentValue,
}) => {
  const [values, setValues] = useState([])

  useEffect(() => {
    setValues(currentValue)
  }, [])

  useEffect(() => {
    onChange(values)
  }, [handleChange])

  function handleChange(event) {
    const { type, value } = event.target
    const { id } = event.target.dataset

    const isCheckbox = type === 'checkbox'
    const currentId = isCheckbox ? value : id
    const currentTime = isCheckbox ? '' : value

    const filtered = values.filter((item) => item.skill !== currentId)
    const containValue = values.findIndex((item) => item.skill === currentId)

    if (isCheckbox && containValue !== -1) {
      setValues(filtered)
    } else {
      setValues([
        ...filtered,
        {
          skill: currentId,
          time_experience: currentTime,
        },
      ])
    }
  }

  function handleKeyPress(event) {
    const isNumber = event.charCode >= 48 && event.charCode <= 57

    if (!isNumber) {
      event.preventDefault()
    }
  }

  function isActive(value) {
    return values && values.find((item) => item && item.skill === value)
  }

  return (
    <fieldset
      className={classNames(styles.fieldset, disabled && styles.disabled)}
    >
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>

      <span className={styles.desc}>{desc}</span>

      <div className={styles.skills}>
        {options.map(({ label: skill, value }) => (
          <Fragment key={`${value}_${Math.random()}`}>
            <input
              className={styles.skills_input}
              id={`${name}_${skill}`}
              name={`${name}_${skill}`}
              onChange={handleChange}
              type="checkbox"
              value={value}
            />
            <label
              htmlFor={`${name}_${skill}`}
              className={classNames(
                styles.skills_label,
                isActive(value) && styles.skills_label_active
              )}
            >
              {skill}
            </label>
          </Fragment>
        ))}
      </div>

      <div className={styles.skills_time}>
        {options.map(
          ({ label: skill, value: id }) =>
            isActive(id) && (
              <Fragment key={`${id}_${Math.random()}`}>
                <label
                  htmlFor={`${name}_${skill}_time`}
                  className={styles.skills_time_label}
                >
                  {skill}
                </label>
                <input
                  className={styles.skills_time_input}
                  data-id={id}
                  data-skill={skill}
                  defaultValue={isActive(id).time_experience}
                  id={`${name}_${skill}_time`}
                  maxLength="2"
                  name={`${name}_${skill}_time`}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  placeholder="Inclua o número de anos de experiência"
                  type="tel"
                />
              </Fragment>
            )
        )}
      </div>

      <span className={styles.error_message}>{error}</span>
    </fieldset>
  )
}

Categories.propTypes = {
  desc: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
}

Categories.defaultProps = {
  desc: null,
  disabled: false,
  error: null,
  onChange: null,
  value: null,
}

export default memo(Categories)
