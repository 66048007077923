import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Button } from 'components'
import styles from './styles.module.css'
import { buttonType, statusType, styleType } from '../helpers'

function Project({
  domainArea, interested, name, onClick, status, 
}) {
  return (
    <div
      className={classNames(
        styles.project,
        styles[styleType(status, interested)],
      )}
    >
      <div className={styles.top}>
        <span className={styles.label}>Projeto</span>
        <p className={styles.text}>{name}</p>
        <span className={styles.label}>Área de domínio</span>
        <p className={styles.text}>{domainArea}</p>
        <span className={styles.label}>Status</span>
        <p className={styles.text}>{statusType(status)}</p>
      </div>
      <div className={styles.bottom}>
        <Button
          fill={buttonType(interested)}
          text="ver detalhes"
          onClick={onClick}
          type="button"
        />
      </div>
    </div>
  )
}

Project.propTypes = {
  domainArea: PropTypes.string.isRequired,
  interested: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  status: PropTypes.string.isRequired,
}

Project.defaultProps = {
  interested: null,
  onClick: null,
}

export default Project
