import React from 'react'
import PropTypes from 'prop-types'
// import { useHistory } from 'react-router-dom'
// import routesNames from 'routes/routesNames'
// import Button from 'components/Button'
import styles from '../../styles.module.css'

function BannerRefer({ data }) {
  return (
    <div className={styles.banner_photo}>
      <a href={data?.bannerUrl}>
        <img src={data?.bannerPhoto} alt="Banner em destaque na home" />
      </a>
    </div>
  )
}

BannerRefer.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default BannerRefer

// const BannerRefer = ({ data }) => {
//   const history = useHistory()

//   function handleRedirect () {
//     return history.push(routesNames.experts.referExpert)
//   }

//   return (
//     <div className={styles.banner_refer}>
//       <div>
//         <h2 className={styles.banner_refer_title}>Indique um amigo</h2>
//         <p className={styles.banner_refer_domains}>
//           Projetos em BI, Design e Gestão.
//         </p>
//       </div>

//       <Button
//         text="Saiba mais"
//         fill="outline"
//         color="white"
//         onClick={handleRedirect}
//       />
//     </div>
//   )
// }

// export default BannerRefer
