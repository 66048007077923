import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { CustomersAgreements as CustomersAgreementsContainer } from '../../../containers'
import { useUsername } from '../../../context/username'
import killSession from '../../../utils/helpers/killSession'
import routesNames from '../../../routes/routesNames'
import makeRequestMergingPagination from '../../../utils/helpers/request/makeRequestMergingPagination'
import businessService from '../../../services/business'
import {
  getAuthToken,
  getCustomersProjectId,
} from '../../../utils/local-storage'

function Agreements() {
  const [loadingAgreements, setLoadingAgreements] = useState(true)
  const [agreements, setAgreements] = useState([])
  const history = useHistory()
  const { state } = useUsername()

  useEffect(() => {
    const getAgreements = () => {
      const token = getAuthToken()
      const projectId = getCustomersProjectId()
      const requestToDo = () => businessService.projects_id_agreements(token, projectId)
      makeRequestMergingPagination(requestToDo)
        .then((response) => setAgreements(response))
        .finally(() => setLoadingAgreements(false))
    }

    getAgreements()
  }, [state.user])

  function onExit() {
    killSession()

    history.push(routesNames.customers.login)
  }

  return (
    <CustomersAgreementsContainer
      username={state.user.full_name}
      onExit={onExit}
      agreements={agreements}
      loadingAgreements={loadingAgreements}
    />
  )
}

export default Agreements
