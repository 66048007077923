import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useUsername } from 'context/username'
import { useLoading } from 'context/loading'
import expertsService from 'services/experts'
import { getAuthToken } from 'utils/local-storage'
import routesNames from 'routes/routesNames'

import {
  // makeGetActivitiesConceptsRequest,
  // makeGetDataStoragesRequest,
  makeGetDomainAreasRequest,
  // makeGetInfrasCloudsRequest,
  // makeGetLanguagesRequest,
  // makeGetLibrariesFrameworksRequest,
  // makeGetToolsPlatformsRequest,
} from '../helpers'

const useHome = () => {
  const { state } = useUsername()
  const [banner, setBanner] = useState({})
  const [projectApplication, setProjectApplication] = useState({})
  const [projectDetail, setProjectDetail] = useState({})
  const [myProjectsList, setMyProjectsList] = useState()
  const [domainArea, setDomainArea] = useState({})
  const [itensDescubraMais, setItensDescubraMais] = useState([])
  const [isOpenProjectModal, setProjectModal] = useState(false)
  const { dispatch: dispatchLoading } = useLoading()
  const history = useHistory()
  const { user } = state
  const token = getAuthToken()

  const isDateOver30DaysAgo = (dateString) => { // formato "YYYY-MM-DD"
    const inputDate = new Date(dateString)
    const currentDate = new Date()
    const differenceInMillis = currentDate.getTime() - inputDate.getTime()
    const differenceInDays = differenceInMillis / (1000 * 3600 * 24)
    return differenceInDays > 30
  }

  const isDateOver60DaysAgo = (dateString) => { // formato "YYYY-MM-DD" ?
    const inputDate = new Date(dateString)
    const currentDate = new Date()
    const differenceInMillis = currentDate.getTime() - inputDate.getTime()
    const differenceInDays = differenceInMillis / (1000 * 3600 * 24)
    return differenceInDays > 60
  }

  const showUpdateWarning = isDateOver60DaysAgo(user.updated_at)

  const getProjects = useCallback(async () => {
    try {
      const response = await expertsService.opportunities.job_applications(
        token,
      )

      const reduceOpportunities = async (acc, curr) => {
        const data = await expertsService.opportunities.job_application(
          curr.id,
          token,
        )

        if (data.status) {
          const newCurr = {
            ...curr,
            status_allocation: data.status,
          }
          return [...(await acc), newCurr]
        }

        return [...acc, curr]
      }

      const format = await response.results?.reduce(reduceOpportunities, [])

      // filtering out projects with status "closed", "frozen", "refused", or "refused_expert"
      const format2 = format.filter((item) => (item.status !== 'closed' && item.status !== 'frozen' && item.status !== 'refused' && item.status !== 'refused_expert' && !isDateOver30DaysAgo(item.end_date)))
      // filtering date

      setMyProjectsList(format2)
    } catch (e) {
      dispatchLoading({
        payload: false,
        type: 'set',
      })
    } finally {
      dispatchLoading({
        payload: false,
        type: 'set',
      })
    }
  }, [token, setMyProjectsList, dispatchLoading])

  const getBanner = useCallback(async () => {
    const response = await expertsService.configuration(1, token)
    const { banner_photo: bannerPhoto, banner_url: bannerUrl } = response
    setBanner({
      bannerPhoto,
      bannerUrl,
    })
  }, [token])

  async function getProject(id) {
    dispatchLoading({
      payload: true,
      type: 'set',
    })

    try {
      const [myJobApplication, myOpportunity] = await Promise.all([
        expertsService.opportunities.job_application(id, token),
        expertsService.opportunities.get({
          id,
          token,
        }),
      ])

      setProjectApplication(myJobApplication)
      setProjectDetail(myOpportunity)
    } catch (err) {
      // log
    } finally {
      dispatchLoading({
        payload: false,
        type: 'set',
      })
    }
  }

  const getDomainAreas = useCallback(async () => {
    try {
      const domainAreasResult = await makeGetDomainAreasRequest()

      setDomainArea(
        domainAreasResult.find((item) => item.value === user.domain_area),
      )
    } catch (err) {
      dispatchLoading({
        payload: false,
        type: 'set',
      })
    } finally {
      dispatchLoading({
        payload: false,
        type: 'set',
      })
    }
  }, [dispatchLoading, user.domain_area])

  function onClickOpenModal(id) {
    return (e) => {
      e.preventDefault()
      getProject(id)
      setProjectModal(true)
    }
  }

  function onClickCloseModal(e) {
    e.preventDefault()
    setProjectModal(false)
  }

  function handleRedirect(id) {
    return (e) => {
      e.preventDefault()
      history.push(`${routesNames.experts.myProject}/${id}`)
    }
  }

  function handleCheckProfile() {
    history.push(routesNames.experts.profileProfessionalData)
  }

  const getDescubraMais = useCallback(async () => {
    dispatchLoading({
      payload: true,
      type: 'set',
    })
    try {
      const response = await expertsService.discover(token)
      setItensDescubraMais(response)
    } catch (e) {
      dispatchLoading({
        payload: false,
        type: 'set',
      })
    } finally {
      dispatchLoading({
        payload: false,
        type: 'set',
      })
    }
  }, [dispatchLoading, token])

  useEffect(() => {
    getBanner()
  }, [getBanner])

  useEffect(() => {
    getProjects()
  }, [getProjects])

  useEffect(() => {
    getDomainAreas()
  }, [getDomainAreas])

  useEffect(() => {
    getDescubraMais()
  }, [getDescubraMais])

  return {
    banner,
    domainArea,
    getProject,
    handleRedirect,
    handleCheckProfile,
    isOpenProjectModal,
    itensDescubraMais,
    myProjectsList,
    onClickCloseModal,
    onClickOpenModal,
    projectApplication,
    projectDetail,
    user,
    showUpdateWarning,
  }
}

export default useHome
