import React, { Fragment, memo } from 'react'
import PropTypes from 'prop-types'
import ReactLoading from 'react-loading'
import styles from './styles.module.css'

function Loading({ children, isLoading }) {
  return (
    <>
      {isLoading && (
        <div className={styles.background}>
          <ReactLoading type="bars" color="#fcfcfc" />
        </div>
      )}
      {children}
    </>
  )
}

Loading.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
}

Loading.defaultProps = {
  children: null,
  isLoading: false,
}

export default memo(Loading)
