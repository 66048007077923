import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles.module.css'

function Progress({ getStepCurrent, size }) {
  return (
    <div className={styles.progress}>
      {size.map((point) => (
        <div
          key={point}
          className={classNames(
            styles.progress_disabled,
            point === getStepCurrent && styles.progress_active,
          )}
        >
          {point}
        </div>
      ))}
    </div>
  )
}

Progress.propTypes = {
  getStepCurrent: PropTypes.number.isRequired,
  size: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default Progress
