import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Condition from 'components/Condition'
import { ReactComponent as VisibilityOffIcon } from 'assets/img/visibility-off.svg'
import { ReactComponent as VisibilityIcon } from 'assets/img/visibility.svg'
import styles from '../styles.module.css'

function DefaultInput({
  error, onChange, type, ...rest 
}) {
  const [showPass, setShowPass] = useState(false)
  const selectedType = type !== 'password' ? type : 'password'

  const isPassword = type === 'password'

  function handleChange(event) {
    onChange(event)
  }

  function handleToggleIcon(event) {
    event.preventDefault()
    setShowPass(!showPass)
  }

  return (
    <Condition
      condition={isPassword}
      elseCondition={(
        <input
          className={classNames(styles.input, error && styles.error)}
          onChange={handleChange}
          type={selectedType}
          {...rest}
        />
      )}
    >
      <div className={styles.with_icon}>
        <input
          className={classNames(styles.input, error && styles.error)}
          onChange={handleChange}
          type={showPass ? 'text' : selectedType}
          {...rest}
        />
        <button
          className={styles.button}
          onClick={handleToggleIcon}
          type="button"
          tabIndex={0}
        >
          {showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </button>
      </div>
    </Condition>
  )
}

DefaultInput.propTypes = {
  error: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'password', 'number']),
}

DefaultInput.defaultProps = {
  error: null,
  id: null,
  type: 'text',
}

export default memo(DefaultInput)
