import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useFeedback } from 'context/feedback'

import ErrorIcon from 'assets/img/error.svg'
import SuccessIcon from 'assets/img/success-circle.svg'
import CloseIcon from 'assets/img/close.svg'
import styles from './styles.module.css'

const icon = {
  close: CloseIcon,
  error: ErrorIcon,
  success: SuccessIcon,
}

function Feedback({
  duration, isOpen, message, severity, 
}) {
  const { dispatch: dispatchFeedback } = useFeedback()

  function handleClick() {
    dispatchFeedback({
      payload: {
        open: false,
      },
      type: 'set',
    })
  }

  if (isOpen && duration) {
    setTimeout(() => {
      dispatchFeedback({
        payload: {
          open: false,
        },
        type: 'set',
      })
    }, duration)
  }

  return (
    <div
      className={classNames(
        styles.feedback,
        styles[severity],
        isOpen && styles.is_open,
        !isOpen && styles.is_closed,
      )}
    >
      <img
        className={styles.icon}
        src={icon[severity] || icon.success}
        alt="Logo de feedback"
      />
      <p className={classNames(styles.text, styles[severity])}>{message}</p>
      <button className={styles.close} onClick={handleClick} type="button">
        <img src={icon.close} alt="Button Close" />
      </button>
    </div>
  )
}

Feedback.propTypes = {
  duration: PropTypes.number,
  isOpen: PropTypes.bool,
  message: PropTypes.node.isRequired,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']).isRequired,
}

Feedback.defaultProps = {
  duration: 5000,
  isOpen: false,
}

export default memo(Feedback)
