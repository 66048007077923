import React from 'react'
import classNames from 'classnames'
import { Link, useHistory } from 'react-router-dom'
import { useUsername } from 'context/username'
import items from './items'
import restrictedItems from './restricted-items' // Apenas usuários internos podem acessar.

import styles from '../../styles.module.css'

const contain = (location, to) => {
  const arrL = location.split('/').filter((i) => !!i)
  const arrTo = to.split('/').filter((i) => !!i)
  const include = arrTo.every((v) => arrL.includes(v))

  return include
}

const classNameActivity = (location, to) => {
  if (contain(location, to)) {
    return styles.active
  }

  return styles.unactive
}

function Nav() {
  const history = useHistory()
  const { state } = useUsername()

  return (
    <nav className={styles.header_nav}>
      {items.map((item) => (
        <Link
          className={classNames(
            styles.header_nav_item,
            classNameActivity(history.location.pathname, item.link),
          )}
          to={item.link}
          key={`${item.name}_${Math.random()}`}
        >
          <item.icon />
          {item.name}
        </Link>
      ))}
      {state?.user?.is_internal && restrictedItems.map((item) => (
        <Link
          className={classNames(
            styles.header_nav_item,
            classNameActivity(history.location.pathname, item.link),
          )}
          to={item.link}
          key={`${item.name}_${Math.random()}`}
        >
          <item.icon />
          {item.name}
        </Link>
      ))}
    </nav>
  )
}
export default Nav
