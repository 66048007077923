import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.css'
import { Badge } from '../../../../components'
import convertToReadableDate from '../../../../utils/helpers/convertToReadableDate'

const statusType = {
  Concluído: 'success',
  'Em andamento': 'in_progress',
  'Tarefas pendentes': 'danger',
}

function Task({ task }) {
  return (
    <div className={styles.task}>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <p className={styles.title}>{task.summary}</p>
          <Badge type={statusType[task.status]} title={task.status} />
        </header>
        <p className={styles.description}>{task.description}</p>
        <p className={styles.date}>
          Iniciada em:
          {' '}
          {task.created_at
            ? convertToReadableDate(task.created_at)
            : 'A definir'}
          {task.duedate
            ? `, concluída em: ${convertToReadableDate(task.duedate)}`
            : ''}
        </p>
      </div>
    </div>
  )
}

Task.propTypes = {
  task: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    duedate: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export default Task
