import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.css'
import DefaultInput from './Default'
import MaskInput from './Mask'

function Input({
  error,
  label,
  mask,
  name,
  onChange,
  placeholder,
  required,
  tooltip,
  type,
  ...rest
}) {
  function handleChange(event) {
    if (onChange) {
      onChange(event)
    }
  }

  return (
    <fieldset className={styles.fieldset}>
      {label && (
        <label
          className={classNames(styles.label, error && styles.error)}
          htmlFor={`input_${name}`}
        >
          {label} 
          {' '}
          {required && '*'}
        </label>
      )}
      {!mask ? (
        <DefaultInput
          id={`input_${name}`}
          error={error}
          tooltip={tooltip}
          name={name}
          onChange={handleChange}
          placeholder={placeholder}
          type={type}
          {...rest}
        />
      ) : (
        <MaskInput
          id={`input_${name}`}
          error={error}
          tooltip={tooltip}
          mask={mask}
          name={name}
          onChange={handleChange}
          placeholder={placeholder}
          {...rest}
        />
      )}
      {tooltip ? (
        <span className={styles.tooltip}>{tooltip}</span>
      ) : (
        <span className={styles.error_message}>{error}</span>
      )}
    </fieldset>
  )
}

Input.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  mask: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  type: PropTypes.oneOf(['number', 'text', 'password']),
}

Input.defaultProps = {
  error: null,
  mask: null,
  label: null,
  onChange: null,
  required: false,
  tooltip: null,
  type: 'text',
}

export default memo(Input)
