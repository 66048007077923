import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'

import { Card, Dashboard } from '../../../components'
import CustomerPendencies from './CustomerPendencies'
import Tasks from './Tasks'
import styles from './styles.module.css'

function isLate(strDate) {
  return new Date(strDate) < new Date()
}

function Home({
  loadingPendencies,
  loadingRoadmap,
  loadingTasks,
  onExit,
  pendencies,
  roadmap,
  tasks,
  username,
}) {
  return (
    <Dashboard onExit={onExit} username={username} type="customers">
      <Card title="Acompanhamento" className={styles.card}>
        {loadingRoadmap ? (
          <Skeleton height={250} />
        ) : (
          <img
            src={roadmap}
            alt="Roadmap do projeto"
            className={styles.roadmap}
          />
        )}
      </Card>
      <div className={styles.wrapper}>
        <Card title="Tarefas" className={styles.tasks} headerLine={false}>
          {loadingTasks ? <Skeleton height={280} /> : <Tasks tasks={tasks} />}
        </Card>
        <Card
          title="Pendências do cliente"
          className={classNames(styles.card, styles.pendencies)}
        >
          {loadingPendencies ? (
            <Skeleton height={280} />
          ) : (
            pendencies.map((pendency) => (
              <CustomerPendencies
                key={pendency.title}
                title={pendency.title}
                description={pendency.description}
                late={isLate(pendency.deadline)}
                createdAt={pendency.created_at}
                deadline={pendency.deadline}
                accountable={pendency.accountable}
              />
            ))
          )}
        </Card>
      </div>
    </Dashboard>
  )
}

Home.propTypes = {
  loadingPendencies: PropTypes.bool.isRequired,
  loadingRoadmap: PropTypes.bool.isRequired,
  loadingTasks: PropTypes.bool.isRequired,
  onExit: PropTypes.func.isRequired,
  pendencies: PropTypes.arrayOf().isRequired,
  roadmap: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf().isRequired,
  username: PropTypes.string.isRequired,
}

export default Home
