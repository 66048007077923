import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles.module.css'

function CheckboxGroup({
  description,
  error,
  label,
  name: model,
  onChange,
  options,
  required,
}) {
  const [values, setValues] = useState([])

  function existOnValues(arr = [], value) {
    return arr && values.find((item) => item === value)
  }

  function handleChange(event) {
    const { name, value } = event.target

    if (existOnValues(values, value)) {
      const index = values.indexOf(value)
      const newArr = [...values]

      if (index > -1) {
        newArr.splice(index, 1)
      }

      setValues(newArr)
      onChange(name, newArr)

      return true
    }

    const newArr = [...values, value]

    setValues(newArr)
    onChange(name, newArr)

    return null
  }

  return (
    <fieldset className={styles.fieldset}>
      <div className={classNames(styles.label_main, error && styles.error)}>
        {label} 
        {' '}
        {required && '*'}
      </div>
      <span className={styles.description}>{description}</span>
      <div>
        {options.map((option) => (
          <Fragment key={option.value}>
            <input
              className={styles.checkbox}
              id={option.value}
              name={model}
              onChange={handleChange}
              type="checkbox"
              value={option.value}
            />
            <label
              htmlFor={option.value}
              className={classNames(
                styles.label,
                existOnValues(values, option.value) && styles.active,
              )}
            >
              {option.label}
            </label>
          </Fragment>
        ))}
      </div>
      <span className={styles.error_message}>{error}</span>
    </fieldset>
  )
}

CheckboxGroup.propTypes = {
  description: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  required: PropTypes.bool,
}

CheckboxGroup.defaultProps = {
  description: null,
  error: null,
  label: false,
  required: false,
}

export default CheckboxGroup
