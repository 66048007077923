import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useLoading } from 'context/loading'
import { useFeedback } from 'context/feedback'
import routesNames from 'routes/routesNames'
import expertsService from 'services/experts'
import { getAuthToken } from 'utils/local-storage'
import { makePostUnregisterOpportunity } from 'pages/Experts/Opportunities/helpers'

const useMyProject = () => {
  const [myProject, setMyProject] = useState({})
  const [myProjectDetail, setMyProjectDetail] = useState({})
  const [showDetails, toggleShowDetails] = useState(false)
  const history = useHistory()
  const params = useParams()
  const { dispatch: dispatchLoading } = useLoading()
  const { dispatch: dispatchFeedback } = useFeedback()
  const token = getAuthToken()

  async function handleToggleInterest(id, type) {
    try {
      if (type === 'unregister') {
        await makePostUnregisterOpportunity(id)
        toggleShowDetails(!showDetails)
        dispatchFeedback({
          payload: {
            message: 'Candidatura desfeita com sucesso!',
            open: true,
            severity: 'success',
          },
          type: 'set',
        })
        history.push(routesNames.experts.myProjects)
      }
    } catch (err) {
      dispatchLoading({
        payload: false,
        type: 'set',
      })
    }
  }

  function handleToggleDetails(e) {
    e.preventDefault()
    toggleShowDetails(!showDetails)
  }

  const getProject = useCallback(async () => {
    dispatchLoading({
      payload: true,
      type: 'set',
    })

    try {
      if (params?.id) {
        const [myJobApplication, projectDetail] = await Promise.all([
          expertsService.opportunities.job_application(params?.id, token),
          expertsService.opportunities.get({
            id: params?.id,
            token,
          }),
        ])

        setMyProject(myJobApplication)
        setMyProjectDetail(projectDetail)
      }
    } catch (err) {
      // log
    } finally {
      dispatchLoading({
        payload: false,
        type: 'set',
      })
    }
  }, [dispatchLoading, params?.id, token])

  useEffect(() => {
    getProject()
  }, [getProject])

  return {
    getProject,
    handleToggleDetails,
    handleToggleInterest,
    myProject,
    myProjectDetail,
    showDetails,
  }
}

export default useMyProject
