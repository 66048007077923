import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.css'

function Texarea({
  error, label, name, onChange, placeholder, required, 
}) {
  function handleChange(event) {
    if (onChange) {
      onChange(event)
    }
  }

  return (
    <fieldset className={styles.fieldset}>
      <label
        className={classNames(styles.label, error && styles.error)}
        htmlFor={`textarea_${name}`}
      >
        {label}
        {required && '*'}
      </label>
      <textarea
        className={classNames(styles.textarea, error && styles.error)}
        id={`textarea_${name}`}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        rows="2"
      />
      <span className={styles.error_message}>{error}</span>
    </fieldset>
  )
}

Texarea.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
}

Texarea.defaultProps = {
  error: null,
  onChange: null,
  required: false,
}

export default memo(Texarea)
