import React from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'

import {
  Button, Input, Select, Categories, Condition, 
} from 'components'

import shouldShowError from 'utils/helpers/form/shouldShowError'
import shouldDisableButton from 'utils/helpers/form/shouldDisableButton'
import BoxWithTitle from '../BoxWithTitle'
import styles from '../../styles.module.css'
import ProfessionalSchema from './schema'

function ProfessionalInfo({
  activitiesConcepts = [],
  businessSegments = [],
  dataStorages = [],
  domainAreas = [],
  formatedUserValues,
  getSkillsFromDomain,
  infrasClouds = [],
  languages = [],
  librariesFrameworks = [],
  onSubmit,
  progressForms,
  setHasErrors,
  toolsPlatforms,
}) {
  const getBusiness = formatedUserValues?.business_segments

  const formik = useFormik({
    initialValues: {
      activities_concepts: formatedUserValues?.activities_concepts || [],
      business_segments: formatedUserValues?.business_segments || [],
      cnpj: formatedUserValues?.cnpj || '',
      company_name: formatedUserValues?.company_name || '',
      current_company: formatedUserValues?.current_company || '',
      current_position: formatedUserValues?.current_position || '',
      data_storages: formatedUserValues?.data_storages || [],
      domain_area: formatedUserValues?.domain_area || [],
      infras_clouds: formatedUserValues?.infras_clouds || [],
      languages: formatedUserValues?.languages || [],
      libraries_frameworks: formatedUserValues?.libraries_frameworks || [],
      linkedin: formatedUserValues?.linkedin || '',
      tools_platforms: formatedUserValues?.tools_platforms || [],
      weekly_availability: formatedUserValues?.weekly_availability || '',
    },
    onSubmit: (values) => onSubmit(values, setHasErrors),
    validationSchema: ProfessionalSchema,
  })

  /* eslint-disable */
  function handleCategoriesMulti(name) {
    return (values) => {
      if (values.length > 0) {
        // eslint-disable-line
        formik.values[name] = values
        return values
      }

      formik.values[name] = []

      return null
    }
  }

  function domainAreasHandleChange(input) {
    getSkillsFromDomain(input.target.value)
    formik.handleChange(input)
  }

  function handleSelectMuti(name) {
    return (input) => {
      if (input.length > 0) {
        // eslint-disable-line
        const values = input.map(({ value }) => value)
        formik.values[name] = values
        return null
      }

      return null
    }
  }

  return (
    <BoxWithTitle
      progress={progressForms.progress}
      title="Informações profissionais"
    >
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <Input
          name="cnpj"
          label="CNPJ (se tiver)"
          onChange={formik.handleChange}
          placeholder="Digite seu cnpj"
          type="text"
          mask="99.999.999/9999-99"
          error={shouldShowError('cnpj', formik)}
          value={formik.values.cnpj}
        />
        <Input
          name="company_name"
          label="Razão social"
          onChange={formik.handleChange}
          placeholder="Digite a razão social"
          type="text"
          error={shouldShowError('company_name', formik)}
          value={formik.values.company_name}
        />
        <Input
          name="linkedin"
          label="Linkedin"
          placeholder="https://www.linkedin.com/nome"
          type="text"
          value={formik.values.linkedin}
          onChange={formik.handleChange}
          error={shouldShowError('linkedin', formik)}
        />
        <Input
          name="weekly_availability"
          label="Disponibilidade semanal (em horas)"
          placeholder="Digite a disponibilidade semanal"
          type="number"
          value={formik.values.weekly_availability}
          onChange={formik.handleChange}
          error={shouldShowError('weekly_availability', formik)}
          tooltip="Horas mínimas sugeridas: 13 horas para o expert, 7 horas para Project Lead e Tech Lead"
        />
        <Select
          name="domain_area"
          label="Área de atuação"
          options={domainAreas}
          onChange={domainAreasHandleChange}
          error={shouldShowError('domain_area', formik)}
          value={formik.values.domain_area}
        />
        <Condition condition={languages.length > 0}>
          <Categories
            disabled={!formik.values.domain_area}
            name="languages"
            label="Linguagens"
            desc="Selecione até 3 opções e escreva o tempo de experiência em cada."
            options={languages}
            onChange={handleCategoriesMulti('languages')}
            error={shouldShowError('languages', formik)}
            value={formik.values.languages}
          />
        </Condition>
        <Condition condition={librariesFrameworks.length > 0}>
          <Categories
            disabled={!formik.values.domain_area}
            name="libraries_frameworks"
            label="Bibliotecas e frameworks"
            desc="Selecione até 3 opções e escreva o tempo de experiência em cada."
            options={librariesFrameworks}
            onChange={handleCategoriesMulti('libraries_frameworks')}
            error={shouldShowError('libraries_frameworks', formik)}
            value={formik.values.libraries_frameworks}
          />
        </Condition>
        <Condition condition={toolsPlatforms.length > 0}>
          <Categories
            disabled={!formik.values.domain_area}
            name="tools_platforms"
            label="Ferramentas e plataformas"
            desc="Selecione até 3 opções e escreva o tempo de experiência em cada."
            options={toolsPlatforms}
            onChange={handleCategoriesMulti('tools_platforms')}
            error={shouldShowError('tools_platforms', formik)}
            value={formik.values.tools_platforms}
          />
        </Condition>
        <Condition condition={dataStorages.length > 0}>
          <Categories
            disabled={!formik.values.domain_area}
            name="data_storages"
            label="Armazenamento de dados"
            desc="Selecione até 3 opções e escreva o tempo de experiência em cada."
            options={dataStorages}
            onChange={handleCategoriesMulti('data_storages')}
            error={shouldShowError('data_storages', formik)}
            value={formik.values.data_storages}
          />
        </Condition>
        <Condition condition={infrasClouds.length > 0}>
          <Categories
            disabled={!formik.values.domain_area}
            name="infras_clouds"
            label="Infraestrutura e Cloud"
            desc="Selecione até 3 opções e escreva o tempo de experiência em cada."
            options={infrasClouds}
            onChange={handleCategoriesMulti('infras_clouds')}
            error={shouldShowError('infras_clouds', formik)}
            value={formik.values.infras_clouds}
          />
        </Condition>
        <Condition condition={activitiesConcepts.length > 0}>
          <Categories
            disabled={!formik.values.domain_area}
            name="activities_concepts"
            label="Atividades e conceitos"
            desc="Selecione até 3 opções e escreva o tempo de experiência em cada."
            options={activitiesConcepts}
            onChange={handleCategoriesMulti('activities_concepts')}
            error={shouldShowError('activities_concepts', formik)}
            value={formik.values.activities_concepts}
          />
        </Condition>
        <Input
          name="current_company"
          label="Empresa onde trabalha atualmente"
          placeholder="Nome da empresa"
          type="text"
          value={formik.values.current_company}
          onChange={formik.handleChange}
          error={shouldShowError('current_company', formik)}
        />

        <Condition condition={businessSegments.length > 0}>
          <Select
            name="business_segments"
            label="Segmento de negócio da empresa atual"
            options={businessSegments}
            onChange={handleSelectMuti('business_segments')}
            error={shouldShowError('business_segments', formik)}
            value={getBusiness}
            isMulti
          />
        </Condition>

        <Input
          name="current_position"
          label="Cargo que atua na empresa atualmente"
          placeholder="Nome do cargo"
          type="text"
          value={formik.values.current_position}
          onChange={formik.handleChange}
          error={shouldShowError('current_position', formik)}
        />
        <Button
          text="Atualizar"
          type="submit"
          disabled={shouldDisableButton(formik)}
        />
      </form>
    </BoxWithTitle>
  )
}

ProfessionalInfo.propTypes = {
  activitiesConcepts: PropTypes.arrayOf(PropTypes.object),
  businessSegments: PropTypes.arrayOf(PropTypes.object),
  dataStorages: PropTypes.arrayOf(PropTypes.object),
  domainAreas: PropTypes.arrayOf(PropTypes.object),
  formatedUserValues: PropTypes.shape({
    activities_concepts: PropTypes.arrayOf(PropTypes.string),
    business_segments: PropTypes.arrayOf(PropTypes.string),
    cnpj: PropTypes.string,
    company_name: PropTypes.string,
    current_company: PropTypes.string,
    current_position: PropTypes.string,
    data_storages: PropTypes.arrayOf(PropTypes.string),
    domain_area: PropTypes.string,
    infras_clouds: PropTypes.arrayOf(PropTypes.string),
    languages: PropTypes.arrayOf(PropTypes.string),
    libraries_frameworks: PropTypes.arrayOf(PropTypes.string),
    linkedin: PropTypes.string,
    tools_platforms: PropTypes.arrayOf(PropTypes.string),
    weekly_availability: PropTypes.string,
  }),
  getSkillsFromDomain: PropTypes.func.isRequired,
  infrasClouds: PropTypes.arrayOf(PropTypes.object),
  languages: PropTypes.arrayOf(PropTypes.object),
  librariesFrameworks: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func.isRequired,
  progressForms: PropTypes.shape({
    progress: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  setHasErrors: PropTypes.func.isRequired,
  toolsPlatforms: PropTypes.arrayOf(PropTypes.object),
}

ProfessionalInfo.defaultProps = {
  activitiesConcepts: [],
  businessSegments: [],
  dataStorages: [],
  domainAreas: [],
  formatedUserValues: PropTypes.shape({
    activities_concepts: [],
    business_segments: [],
    cnpj: null,
    company_name: null,
    current_company: null,
    current_position: null,
    data_storages: [],
    domain_area: null,
    infras_clouds: [],
    languages: [],
    libraries_frameworks: [],
    linkedin: null,
    tools_platforms: [],
    weekly_availability: null,
  }),
  infrasClouds: [],
  languages: [],
  librariesFrameworks: [],
  progressForms: {
    progress: 0,
  },
  toolsPlatforms: [],
}

export default ProfessionalInfo
