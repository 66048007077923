/* eslint-disable */
import React, { useState } from 'react'
import { motion } from 'framer-motion'

import styles from './styles.module.css'

const iconCheck = {
  hidden: {
    scale: 3,
    opacity: 0,
    pathLength: 0,
    fill: 'rgba(0, 0, 0, 0)',
  },
  visible: {
    scale: 1,
    opacity: 1,
    pathLength: 1,
    fill: 'rgba(255, 255, 255, 255)',
  },
}

const iconRotate = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: 'rgba(255, 255, 255, 255)',
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: 'rgba(255, 255, 255, 255)',
    rotate: [0, 720],
  },
}

const Check = ({ delay }) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 22"
    className={styles.icon_check}
  >
    <motion.path
      d="M9.19253 16.8134L2.93753 10.5584L0.807526 12.6734L9.19253 21.0584L27.1925 3.05836L25.0775 0.943359L9.19253 16.8134Z"
      variants={iconCheck}
      initial="hidden"
      animate="visible"
      transition={{
        default: { delay, duration: 0.7, ease: 'easeIn' },
        fill: { delay, duration: 1.4, ease: 'linear' },
      }}
    />
  </motion.svg>
)

const Rotate = ({ delay }) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={styles.icon_rotate}
  >
    <motion.path
      d="M13.3949 3.03C17.6549 3.75 20.8949 7.44 20.8949 11.895C20.8949 16.35 17.6549 20.04 13.3949 20.76V23.79C19.3199 23.055 23.8949 18.015 23.8949 11.895C23.8949 5.775 19.3199 0.735 13.3949 0V3.03ZM2.44497 4.56005L4.55997 6.69005C3.76497 7.81505 3.23997 9.09006 3.02997 10.3951H-3.05176e-05C0.254969 8.31005 1.09497 6.30005 2.44497 4.56005ZM-3.05176e-05 13.395H3.02997C3.23997 14.715 3.76497 15.975 4.54497 17.1L2.42997 19.23C1.07997 17.49 0.254969 15.48 -3.05176e-05 13.395ZM4.54498 21.3751C6.28498 22.7251 8.30999 23.5351 10.395 23.7901V20.7451C9.08998 20.5201 7.82998 20.0101 6.70498 19.2001L4.54498 21.3751Z"
      variants={iconRotate}
      initial="hidden"
      animate="visible"
      transition={{
        default: { delay, duration: 0.7, ease: 'linear' },
        fill: { delay, duration: 0.7, ease: 'linear' },
        rotate: { delay, duration: 0.5, ease: 'linear' },
      }}
    />
  </motion.svg>
)

const Bar = ({ delay = 0.7 }) => (
  <motion.div
    className={styles.bar}
    initial={{ height: 0 }}
    animate={{ height: '100%' }}
    transition={{ delay }}
  />
)

const Icon = ({ children, delay, show, type = '' }) => {
  const isGrouped = type.includes('grouped')

  return (
    <motion.div
      className={styles.icons}
      animate={{ background: show ? '#3e3e5c' : 'auto' }}
      transition={{ delay: isGrouped ? 2 : delay }}
    >
      {children}
    </motion.div>
  )
}

Icon.Check = Check
Icon.Rotate = Rotate
Icon.Bar = Bar

export default Icon
