import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as IconAlert } from 'assets/img/icon-alert.svg'
import styles from './styles.module.css'

function Message({ text }) {
  return (
    <div className={styles.message}>
      <div className={styles.message_icon}>
        <IconAlert />
      </div>
      <div className={styles.message_text}>{text}</div>
    </div>
  )
}

Message.propTypes = {
  text: PropTypes.string.isRequired,
}

export default memo(Message)
