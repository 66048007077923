/* eslint-disable */
import React from 'react'
import { motion } from 'framer-motion'
import { ReactComponent as WelcomeGirl } from '../../../../assets/img/welcome-girl.svg'

import styles from './styles.module.css'

const Girl = () => (
  <motion.div
    className={styles.girl}
    initial={{
      x: 10,
    }}
    animate={{
      opacity: 1,
      x: 0,
    }}
    transition={{
      delay: 1.5,
    }}
  >
    <WelcomeGirl />
  </motion.div>
)

export default Girl
