import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Default from './Default'
import Multi from './Multi'

import styles from './styles.module.css'

function Select({
  error,
  isMulti,
  label,
  name,
  onChange,
  options,
  placeholder,
  required,
  value,
}) {
  return (
    <fieldset className={styles.fieldset}>
      <label
        className={classNames(styles.label, error && styles.error)}
        htmlFor={`input_${name}`}
      >
        {label} 
        {' '}
        {required && '*'}
      </label>
      {!isMulti ? (
        <Default
          onChange={onChange}
          id={`input_${name}`}
          error={error}
          options={options}
          placeholder={placeholder}
          name={name}
          value={value}
        />
      ) : (
        <Multi
          onChange={onChange}
          id={`input_${name}`}
          error={error}
          options={options}
          name={name}
          value={value}
        />
      )}
    </fieldset>
  )
}

Select.propTypes = {
  error: PropTypes.string,
  isMulti: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
}

Select.defaultProps = {
  error: null,
  isMulti: false,
  onChange: null,
  placeholder: null,
  required: false,
  value: null,
}

export default memo(Select)
