/* eslint-disable */
import React from 'react'
import { motion } from 'framer-motion'
import { ReactComponent as WelcomeMegafone } from '../../../../assets/img/welcome-megafone.svg'

import styles from './styles.module.css'

const Megafone = () => (
  <motion.div
    className={styles.megafone}
    initial={{
      opacity: 0,
      y: -100,
    }}
    animate={{
      opacity: 1,
      y: 0,
    }}
  >
    <WelcomeMegafone />
  </motion.div>
)

export default Megafone
