import { GET } from '../../utils/helpers/request'

const skills = {
  activities_concepts: (token, id) => {
    const endpoint = `experts/skills/domain-areas/${id}/activities-concepts/`
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
  business_segments: (token) => {
    const endpoint = 'experts/skills/business-segments/'
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
  data_storages: (token, id) => {
    const endpoint = `experts/skills/domain-areas/${id}/data-storages/`
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
  domain_areas: (token) => {
    const endpoint = 'experts/skills/domain-areas/'
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
  domain_areas_recommendations: (token) => {
    const endpoint = 'experts/skills/domain-areas-recommendations/'
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
  infras_clouds: (token, id) => {
    const endpoint = `experts/skills/domain-areas/${id}/infras-clouds/`
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
  languages: (token, id) => {
    const endpoint = `experts/skills/domain-areas/${id}/languages/`
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
  libraries_frameworks: (token, id) => {
    const endpoint = `experts/skills/domain-areas/${id}/libraries-frameworks/`
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
  tools_platforms: (token, id) => {
    const endpoint = `experts/skills/domain-areas/${id}/tools-platforms/`
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
}

export default skills
