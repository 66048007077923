import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.css'

function Checkbox({
  children, error, name, onChange, ...rest 
}) {
  function handleChange(event) {
    onChange(event)
  }

  return (
    <fieldset className={styles.fieldset}>
      <input
        className={styles.input}
        onChange={handleChange}
        id={`input_${name}`}
        type="checkbox"
        name={name}
        {...rest}
      />
      <label htmlFor={`input_${name}`} className={styles.label}>
        {children}
      </label>

      <span className={styles.error_message}>{error}</span>
    </fieldset>
  )
}

Checkbox.propTypes = {
  children: PropTypes.node,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['checkbox']),
}

Checkbox.defaultProps = {
  children: null,
  error: null,
  onChange: null,
  required: false,
  type: 'checkbox',
}

export default memo(Checkbox)
