import * as React from 'react'
import PropTypes from 'prop-types'

const UsernameStateContext = React.createContext()
const UsernameDispatchContext = React.createContext()

function usernameReducer(state, action) {
  switch (action.type) {
    case 'set': {
      return {
        user: {
          ...state.user,
          ...action.payload,
        },
      }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const initialState = {
  user: {},
}

function UsernameProvider({ children }) {
  const [state, dispatch] = React.useReducer(usernameReducer, initialState)

  return (
    <UsernameStateContext.Provider value={state}>
      <UsernameDispatchContext.Provider value={dispatch}>
        {children}
      </UsernameDispatchContext.Provider>
    </UsernameStateContext.Provider>
  )
}

UsernameProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

function useUsernameState() {
  const context = React.useContext(UsernameStateContext)

  if (context === undefined) {
    throw new Error('useUsernameState must be used within a UsernameProvider')
  }

  return context
}

function useUsernameDispatch() {
  const context = React.useContext(UsernameDispatchContext)

  if (context === undefined) {
    throw new Error(
      'useUsernameDispatch must be used within a UsernameProvider',
    )
  }

  return context
}

function useUsername() {
  return {
    dispatch: useUsernameDispatch(),
    state: useUsernameState(),
  }
}

export {
  UsernameProvider, useUsernameState, useUsernameDispatch, useUsername, 
}
