import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import convertToReadableDate from 'utils/helpers/convertToReadableDate'

import { Button, Condition, Dashboard } from 'components'

import routesNames from 'routes/routesNames'
import Detail from './Detail'
import Project from './Project'
// import ConfirmInterest from './ConfirmInterest'
import { arrayToCommas } from './helpers'

import styles from './styles.module.css'

function ShowcaseOpportunities({
  handleToggleInterest,
  onExit,
  opportunities,
  opportunitiesActive,
  opportunitiesRecommended,
  user,
}) {
  const history = useHistory()
  const [toggleDetail, setToggleDetail] = useState(false)
  const [detail, setDetail] = useState({})

  function handleOpenProfile() {
    history.push(routesNames.experts.updateAccount)
  }

  function handleCloseDetail() {
    setToggleDetail(!toggleDetail)
  }

  function handleToggleDetail(props) {
    return () => {
      setToggleDetail(!toggleDetail)
      setDetail(props)
    }
  }

  useEffect(() => {
    if (toggleDetail) {
      setToggleDetail(false)
    }
  }, [handleToggleInterest]) // eslint-disable-line

  return (
    <Dashboard
      backTo={routesNames.experts.showcaseOpportunities}
      onExit={onExit}
      username={user.full_name}
      type="experts"
      title="Vitrine de Oportunidades"
      description="Aqui você encontra todas as vagas de projeto disponíveis na
      Cognitivo. Você pode se candidatar em até duas oportunidades simultaneamente."
    >
      <div className={styles.wrapper}>
        <Condition condition={opportunitiesRecommended.length > 0}>
          <div className={styles.section}>
            <div className={styles.head}>
              <h2 className={styles.title}>Recomendados para seu perfil</h2>
              <Button
                className={styles.button}
                fill="outline"
                text="Atualizar perfil"
                type="button"
                onClick={handleOpenProfile}
              />
            </div>
            <div className={styles.boxes}>
              {opportunitiesRecommended.map(({ ...rest }) => (
                <Project
                  key={rest.id}
                  name={rest.title}
                  domainArea={rest.domain_area?.title}
                  status={rest.status}
                  onClick={handleToggleDetail(rest)}
                  interested={rest.expert_interested}
                />
              ))}
            </div>
          </div>
        </Condition>
        <Condition condition={opportunitiesActive.length > 0}>
          <div className={styles.section}>
            <div className={styles.head}>
              <h2 className={styles.title}>Oportunidades em andamento</h2>
            </div>
            <div className={styles.boxes}>
              {opportunitiesActive.map(({ ...rest }) => (
                <Project
                  key={rest.id}
                  name={rest.title}
                  domainArea={rest.domain_area?.title}
                  status={rest.status}
                  onClick={handleToggleDetail(rest)}
                  interested={rest.expert_interested}
                />
              ))}
            </div>
          </div>
        </Condition>
        <Condition condition={opportunities.length > 0}>
          <div className={styles.section}>
            <div className={styles.head}>
              <h2 className={styles.title}>Todas as oportunidades</h2>
              {/* <Button
                fill="outline"
                text="Filtrar"
                type="button"
                onClick={handleOpenFilter}
              /> */}
            </div>
            <div className={styles.boxes}>
              {opportunities.map(({ ...rest }) => (
                <Project
                  key={rest.id}
                  name={rest.title}
                  domainArea={rest.domain_area?.title}
                  status={rest.status}
                  onClick={handleToggleDetail(rest)}
                  interested={rest.expert_interested}
                />
              ))}
            </div>
          </div>
        </Condition>
      </div>
      <Condition condition={detail && toggleDetail}>
        <Detail
          activitiesAndConcepts={arrayToCommas(detail.activities_concepts)}
          businessSegment={arrayToCommas(detail.business_segments)}
          dataStorage={arrayToCommas(detail.data_storages)}
          description={detail.description}
          domainArea={detail.domain_area?.title}
          handleToggleInterest={handleToggleInterest}
          infraAndCloud={arrayToCommas(detail.infras_clouds)}
          languages={arrayToCommas(detail.languages)}
          onHide={handleCloseDetail}
          projectEndDate={convertToReadableDate(
            detail.end_date ? detail.end_date : '',
          )}
          projectExpertCanRegister={detail.expert_can_register}
          projectExpertInterested={detail.expert_interested}
          projectId={detail.id}
          projectInitialDate={convertToReadableDate(
            detail.start_date ? detail.start_date : '',
          )}
          projectPayForHour={detail.hourly_value}
          projectRequiredWeeklyHours={detail.workload}
          projectStatus={detail.status}
          projectWorkersNumbers={detail.qty}
          show={toggleDetail}
          title={detail.title}
          toolsAndPlataforms={arrayToCommas(detail.tools_platforms)}
        />
      </Condition>
      {/* <ConfirmInterest
        description="Responda as perguntas abaixo para entendermos melhor
        a aplicação do seu perfil para esta vaga e facilitar a nossa triagem!"
        show={toggleInterest}
        onHide={handleCloseInterest}
        title="Confirmar interesse"
      /> */}
    </Dashboard>
  )
}

ShowcaseOpportunities.propTypes = {
  handleToggleInterest: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  opportunities: PropTypes
    .arrayOf(),
  opportunitiesActive: PropTypes
    .arrayOf(),
  opportunitiesRecommended: PropTypes
    .arrayOf(),
  user: PropTypes.shape().isRequired,
}

ShowcaseOpportunities.defaultProps = {
  opportunities: [],
  opportunitiesActive: [],
  opportunitiesRecommended: [],
}

export default ShowcaseOpportunities
