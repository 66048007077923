import * as React from 'react'
import PropTypes from 'prop-types'

const LoadingStateContext = React.createContext()
const LoadingDispatchContext = React.createContext()

function loadingReducer(state, action) {
  switch (action.type) {
    case 'set': {
      return { loading: action.payload }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const initialState = {
  loading: false,
}

function LoadingProvider({ children }) {
  const [state, dispatch] = React.useReducer(loadingReducer, initialState)

  return (
    <LoadingStateContext.Provider value={state}>
      <LoadingDispatchContext.Provider value={dispatch}>
        {children}
      </LoadingDispatchContext.Provider>
    </LoadingStateContext.Provider>
  )
}

LoadingProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

function useLoadingState() {
  const context = React.useContext(LoadingStateContext)

  if (context === undefined) {
    throw new Error('useLoadingState must be used within a LoadingProvider')
  }

  return context
}

function useLoadingDispatch() {
  const context = React.useContext(LoadingDispatchContext)

  if (context === undefined) {
    throw new Error('useLoadingDispatch must be used within a LoadingProvider')
  }

  return context
}

function useLoading() {
  return {
    dispatch: useLoadingDispatch(),
    state: useLoadingState(),
  }
}

export {
  LoadingProvider, useLoadingState, useLoadingDispatch, useLoading, 
}
