import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import routesNames from 'routes/routesNames'

import {
  Button, Dashboard, FormError, Input, Select, 
} from 'components'

import shouldShowError from 'utils/helpers/form/shouldShowError'
import shouldDisableButton from 'utils/helpers/form/shouldDisableButton'

import styles from './styles.module.css'
import Schema from './schema'

const getUserValuesFromState = (user) => {
  const formatedValues = {
    business_hours_availability: user.business_hours_availability,
    weekly_availability: user.weekly_availability,
  }

  return formatedValues
}

function UpdateAvailability({ onExit, onSubmit, user = {} }) {
  const formatedUserValues = getUserValuesFromState(user)
  const [hasErrors, setHasErrors] = useState(false)

  const formik = useFormik({
    initialValues: {
      business_hours_availability:
        formatedUserValues.business_hours_availability || '',
      weekly_availability: formatedUserValues.weekly_availability || '',
    },
    onSubmit: (values) => onSubmit(values, setHasErrors),
    validationSchema: Schema,
  })

  const businnesHoursAvailability = [
    {
      label: 'Sim',
      value: 'yes',
    },
    {
      label: 'Não',
      value: 'no',
    },
    {
      label: 'Parcial',
      value: 'partially',
    },
  ]

  return (
    <Dashboard
      backTo={routesNames.experts.showcaseOpportunities}
      onExit={onExit}
      username={user.full_name}
      type="experts"
      title="Atualizar disponibilidade"
      description="Indique a disponibilidade de tempo que você terá para trabalhar como expert na semana."
    >
      <p className={styles.description}>
        Você pode também
        <Link to={routesNames.experts.accountDeactivate}>
          declarar indisponibilidade
        </Link>
        {' '}
        para pausar temporariamente o recebimento de propostas para novos
        projetos.
      </p>
      <div className={styles.wrapper}>
        <FormError
          hasErrors={hasErrors}
          message="Houve um problema ao atualizar disponibilidade. Tente novamente mais tarde."
        />
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <Input
            name="weekly_availability"
            label="dddDisponibilidade semanal (em horas)"
            onChange={formik.handleChange}
            placeholder="13 horas"
            type="number"
            error={shouldShowError('weekly_availability', formik)}
            value={formik.values.weekly_availability}
            tooltip="Horas mínimas sugeridas: 13 horas para o expert, 7 horas para Project Lead e Tech Lead"
            required
          />
          <Select
            name="business_hours_availability"
            label="Tem disponibilidade em horário comercial?"
            options={businnesHoursAvailability}
            onChange={formik.handleChange}
            placeholder="Selecione uma das opções"
            error={shouldShowError('business_hours_availability', formik)}
            value={formik.values.business_hours_availability}
            required
          />
          <Button
            text="Atualizar"
            type="submit"
            disabled={shouldDisableButton(formik)}
          />
        </form>
      </div>
    </Dashboard>
  )
}

UpdateAvailability.propTypes = {
  onExit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
}

export default UpdateAvailability
