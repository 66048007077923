export const statusTypes = (status) => {
  switch (status) {
    case 'allocated':
      return 'active'

    case 'active':
      return 'open'

    case 'refused':
    case 'refused_expert':
      return 'refused'

    case 'frozen':
      return 'frozen'

    case 'closed':
      return 'closed'

    default:
      return 'in_progress'
  }
}

export const statusTexts = (status) => {
  switch (status) {
    case 'allocated':
      return 'projeto ativo'

    case 'active':
      return 'aberto'

    case 'refused':
    case 'refused_expert':
      return 'finalizada'

    case 'frozen':
      return 'temporariamente congelada'

    case 'closed':
      return 'cancelada'

    default:
      return 'em processo de alocação'
  }
}

export const statusAllocationTexts = (status) => {
  switch (status) {
    case 'allocated':
      return 'Alocado'

    case 'active':
      return 'Aberto'

    case 'refused':
    case 'refused_expert':
      return 'Alocação encerrada'

    case 'frozen':
      return 'Temporariamente congelada'

    case 'closed':
      return 'Cancelada'

    default:
      return 'Alocação encerrada'
  }
}
