/* eslint-disable */
import React from 'react'
import LoggedInTemplate from 'templates/LoggedInTemplate'
import convertToReadableDate from 'utils/helpers/convertToReadableDate'
import routesNames from 'routes/routesNames'
import HeroText from 'components/HeroText'
import Detail from './components/Detail'
import SelectiveProcess from './components/SelectiveProcess'
import useMyProject from './hooks'
import { arrayToCommas } from './helpers'
import styles from './styles.module.css'

const MyProject = () => {
  const {
    getProject,
    handleToggleDetails,
    handleToggleInterest,
    myProject,
    myProjectDetail,
    showDetails,
  } = useMyProject()

  return (
    <LoggedInTemplate>
      <main className={styles.main}>
        <HeroText
          title="Acompanhamento"
          desc="Fique por dentro de sua candidatura!"
          breadcrumbs={[
            {
              text: 'meus projetos',
              to: routesNames.experts.myProjects,
            },
            {
              text: 'acompanhamento',
            },
          ]}
        />
        <div className={styles.my_project_content}>
          <div className={styles.container}>
            <Detail
              activitiesAndConcepts={arrayToCommas(
                myProjectDetail?.activities_concepts
              )}
              businessSegment={arrayToCommas(
                myProjectDetail?.business_segments
              )}
              dataStorage={arrayToCommas(myProjectDetail?.data_storages)}
              description={myProjectDetail?.description}
              domainArea={myProjectDetail?.domain_area?.title}
              endDate={myProjectDetail?.end_date}
              handleToggleDetails={handleToggleDetails}
              handleToggleInterest={handleToggleInterest}
              hourlyValue={myProjectDetail?.hourly_value}
              infraAndCloud={arrayToCommas(myProjectDetail?.infras_clouds)}
              languages={arrayToCommas(myProjectDetail.languages)}
              projectEndDate={convertToReadableDate(myProjectDetail?.end_date)}
              projectId={myProjectDetail?.id}
              projectInitialDate={convertToReadableDate(
                myProjectDetail?.start_date
              )}
              projectPayForHour={myProjectDetail?.hourly_value}
              projectRequiredWeeklyHours={myProjectDetail?.workload}
              projectStatus={myProject?.status}
              projectWorkersNumbers={myProjectDetail?.qty}
              showDetails={showDetails}
              signatureDate={myProjectDetail?.signature_date}
              startDate={myProjectDetail?.start_date}
              title={myProjectDetail?.title}
              toolsAndPlataforms={arrayToCommas(
                myProjectDetail?.tools_platforms
              )}
            />
            <SelectiveProcess
              getProject={getProject}
              myProject={myProject}
              projectId={myProjectDetail?.id}
            />
          </div>
        </div>
      </main>
    </LoggedInTemplate>
  )
}

export default MyProject
