import * as Yup from 'yup'

import messages from 'utils/yup/messages'
import { isCpf } from 'utils/validators'

Yup.addMethod(Yup.string, 'cpf', (message) => Yup.string().test('is-cpf', message, (value) => isCpf(value) === true))

Yup.addMethod(Yup.string, 'fullName', (message) => Yup.string().test('full-name', message, (value = '') => {
  if (value.length >= 4) {
    const valueToArray = value.split(' ')
    const arrSize = valueToArray.length

    return arrSize > 1 && valueToArray[1].length > 0
  }

  return true
}))

const schema = {
  agree: Yup.boolean()
    .oneOf([true], messages.string.agree)
    .required(messages.string.required),
  cpf: Yup.string()
    .cpf(messages.string.cpf)
    .min(11, messages.string.min(11))
    .required(messages.string.required),
  email: Yup.string()
    .min(5, messages.string.min(5))
    .email(messages.string.email)
    .required(messages.string.required),
  email_confirmation: Yup.string()
    .min(5, messages.string.min(5))
    .email(messages.string.email)
    .required(messages.string.required)
    .oneOf([Yup.ref('email'), null], messages.string.equal_fields('email')),
  full_name: Yup.string()
    .max(60, messages.string.max(60))
    .min(4, messages.string.min(4))
    .fullName(messages.string.required)
    .required(messages.string.required),
  password: Yup.string()
    .min(8, messages.string.min(8))
    .required(messages.string.required),
  password_confirmation: Yup.string()
    .min(8, messages.string.min(8))
    .required(messages.string.required)
    .oneOf([Yup.ref('password'), null], messages.string.equal_fields('senha')),
  linkedin: Yup.string()
    .min(17, messages.string.min(17))
    .matches(
      /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+/,
      messages.string.url,
    )
    .required(messages.string.required),
  curriculum: Yup.mixed().required(messages.string.required),
}

const yupValidator = Yup.object(schema)

export default yupValidator
