import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link, useHistory } from 'react-router-dom'

import styles from './styles.module.css'

const contain = (location, to) => {
  const arrL = location.split('/').filter((i) => !!i)
  const arrTo = to.split('/').filter((i) => !!i)
  const include = arrTo.every((v) => arrL.includes(v))

  return include
}
const classNameActivity = (location, to) => {
  if (contain(location, to)) {
    return styles.active
  }

  return styles.unactive
}

const iconColorActivity = (location, to) => {
  if (contain(location, to)) {
    return '#3E3E5C'
  }

  return '#A6A6A5'
}

function Menu({ items, type }) {
  const history = useHistory()

  return (
    <aside className={styles.menu}>
      {items[type].map((item) => (
        <Link key={item.title} className={styles.item} to={item.to}>
          <item.icon
            fill={iconColorActivity(history.location.pathname, item.to)}
          />
          <span
            className={classNames(
              styles.title,
              classNameActivity(history.location.pathname, item.to),
            )}
          >
            {item.title}
          </span>
        </Link>
      ))}
    </aside>
  )
}

Menu.propTypes = {
  items: PropTypes.shape(),
  type: PropTypes.oneOf(['experts', 'customers']).isRequired,
}

Menu.defaultProps = {
  items: null,
}

export default memo(Menu)
