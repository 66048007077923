import React from 'react'
import { ReactComponent as LogoLetterLight } from 'assets/img/logo-letter-light.svg'
import styles from '../../styles.module.css'

function Logo() {
  return (
    <div className={styles.header_logo}>
      <LogoLetterLight />
      <h1 className={styles.header_title}>Plataforma Experts</h1>
    </div>
  )
}

export default Logo
