import { React } from 'react'
import PropTypes from 'prop-types'
import HeaderLoggedin from 'components/HeaderLoggedin'
import useLoggedInTemplate from './hooks'
import styles from './styles.module.css'

function LoggedInTemplate({ children }) {
  const { onExit, state } = useLoggedInTemplate()

  return (
    <div className={styles.layout_loggedin}>
      <HeaderLoggedin onExit={onExit} state={state} />
      {children}
      <footer className={styles.footer}>
        <span>People illustrations by Storyset</span>
      </footer>
    </div>
  )
}

LoggedInTemplate.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LoggedInTemplate
