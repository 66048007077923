import { useEffect, useState } from 'react'
// import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import expertService from 'services/experts'
import { useUsername } from 'context/username'
import { useLoading } from 'context/loading'
import { getAuthToken } from 'utils/local-storage'
import makeGetDomainAreasRequest from '../helpers'
import Schema from '../schema'

const useIndicateExpert = () => {
  const [hasErrors, setHasErrors] = useState(false)
  const [expertSent, setExpertSent] = useState(false)
  const { state } = useUsername()
  const { dispatch: dispatchLoading } = useLoading()
  const [domainArea, setDomainArea] = useState([])

  function onSubmit({ resetForm, values }) {
    const token = getAuthToken()
    const cleanValues = {
      cell_phone: values.cell_phone,
      comment: values.comment,
      domain_area: values.domain_area,
      email: values.email,
      expert: state.user.id,
      linkedin: values.linkedin,
      name: values.name,
    }

    dispatchLoading({
      payload: true,
      type: 'set',
    })

    expertService
      .recommendation(cleanValues, token)
      .then((result) => {
        if (result) {
          dispatchLoading({
            payload: false,
            type: 'set',
          })
          setExpertSent(true)
        }
      })
      .catch(() => {
        setHasErrors({
          message: 'Não foi possível indicar o expert.',
          show: true,
        })
        dispatchLoading({
          payload: false,
          type: 'set',
        })
      })
      .finally(() => {
        resetForm()
        window.scroll({ behavior: 'smooth', top: 0 })
      })
  }

  const formik = useFormik({
    initialValues: {
      cell_phone: '',
      comment: null,
      domain_area: '',
      email: '',
      linkedin: '',
      name: '',
    },
    onSubmit: (values, props) => {
      onSubmit({
        setExpertSent,
        setHasErrors,
        values,
        ...props,
      })
    },
    validationSchema: Schema,
  })

  function handleToggleSent() {
    setExpertSent(!expertSent)
  }

  useEffect(() => {
    const getDomainArea = async () => {
      const response = await makeGetDomainAreasRequest()

      if (response) {
        setDomainArea(response)
      }
    }

    getDomainArea()
  }, [])

  return {
    domainArea,
    expertSent,
    formik,
    handleToggleSent,
    hasErrors,
    onSubmit,
    user: state.user,
  }
}

export default useIndicateExpert
