import * as Yup from 'yup'

import messages from 'utils/yup/messages'

const schema = {
  address: Yup.string(),
  city: Yup.string().min(2, messages.string.min(2)),
  complement: Yup.string().min(1, messages.string.min(1)),
  neighborhood: Yup.string().min(1, messages.string.min(1)),
  number: Yup.number().min(1, messages.number.min(1)),
  postal_code: Yup.string().min(8, messages.string.min(8)),
  state: Yup.string(),
}

const yupValidator = Yup.object(schema)

export default yupValidator
