import * as Yup from 'yup'

import messages from 'utils/yup/messages'

const schema = {
  cell_phone: Yup.string().min(12, messages.string.min(12)),
  marital_status: Yup.string(),
}

const yupValidator = Yup.object(schema)

export default yupValidator
