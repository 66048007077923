import { getAuthSessionCreated } from '../utils/local-storage'
import killSession from '../utils/helpers/killSession'

function verifyLastLogin() {
  const intervalTime = process.env.REACT_APP_API_TOKEN_EXPIRES_IN
  const authSessionCreated = getAuthSessionCreated()

  if (!authSessionCreated) {
    return true
  }

  const oldDate = new Date(authSessionCreated)
  const newDate = new Date()
  const diffDays = newDate.getDate() - oldDate.getDate()

  if (diffDays > 0) {
    killSession()

    return false
  }

  const diffTime = newDate - oldDate
  const diffMinutes = diffTime / 1000 / 60

  if (diffMinutes > intervalTime / 60) {
    killSession()

    return false
  }

  return true
}

export default verifyLastLogin
