import {
  removeAuthToken,
  removeAuthSessionCreated,
  setIsAuth,
} from '../local-storage'

function killSession() {
  setIsAuth(false)
  removeAuthToken()
  removeAuthSessionCreated()
}

export default killSession
