/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'

import { Badge } from '../../../../components'
import styles from './styles.module.css'

const statusType = {
  Concluído: 'success',
  'Em andamento': 'in_progress',
  'Tarefas pendentes': 'danger',
}

function Tasks({ tasks }) {
  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.head_line}>
          <th className={styles.head_column}>Nome</th>
          <th className={styles.head_column}>Status</th>
        </tr>
      </thead>
      <tbody>
        {tasks.map((task) => (
          <tr key={task.name}>
            <td className={styles.body_summary}>{task.summary}</td>
            <td className={styles.body_badge}>
              <Badge title={task.status} type={statusType[task.status]} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

Tasks.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      status: PropTypes.oneOf([
        'Concluído',
        'Em andamento',
        'Tarefas pendentes',
      ]).isRequired,
      summary: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default Tasks
