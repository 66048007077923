import { GET, POST } from 'utils/helpers/request'

const opportunities = {
  get: ({ id, perPage, token }) => {
    const path = 'experts/opportunities/'
    const hasPagination = perPage ? `?page=${perPage}` : ''
    const endpoint = id ? `${path}${id}` : `${path}${hasPagination}`
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
  job_application: (id, token) => {
    const path = `experts/opportunities/${id}/job_application/`
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(path, config)
  },
  job_application_update: (id, token, payload) => {
    const path = `experts/opportunities/${id}/job_application_update/`
    const config = {
      body: JSON.stringify(payload),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return POST(path, config)
  },
  job_applications: (token) => {
    const path = 'experts/opportunities/job_applications/'
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(path, config)
  },
  recommended: (id, token) => {
    const path = 'experts/opportunities/recommended/'
    const endpoint = id ? `${path}${id}` : path
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return GET(endpoint, config)
  },
  register: (id, payload, token) => {
    const endpoint = `experts/opportunities/${id}/register/`
    const config = {
      body: JSON.stringify(payload),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return POST(endpoint, config)
  },
  saveQuestions: (id, token) => {
    const endpoint = ''
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return POST(endpoint, config)
  },
  unregister: (id, token) => {
    const endpoint = `experts/opportunities/${id}/unregister/`
    const config = {
      body: JSON.stringify(),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return POST(endpoint, config)
  },
}

export default opportunities
