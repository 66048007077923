/* eslint-disable */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ArrowRightMini } from 'assets/img/arrow-right-mini.svg'
import styles from './styles.module.css'

function Pagination({ perPage, projectsAmount }) {
  const [pages, setPages] = useState(1)
  const [pageNumbers, setPageNumbers] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [prevLink, setPrevLink] = useState('')
  const [nextLink, setNextLink] = useState('')

  useEffect(() => {
    const link = '?page='
    const pLink =
      currentPage >= 2 ? link + (currentPage - 1) : '/experts/ver-indicacoes'
    const nLink = currentPage + 1 >= pages ? '' : link + (currentPage + 1)

    setPrevLink(pLink)
    setNextLink(nLink)
  }, [currentPage, pages])

  useEffect(() => {
    const url = window.location.href

    if (url.includes('?')) {
      const queryParams = url.split('?')[1]
      const page = Number(queryParams.split('=')[1])
      setCurrentPage(page)
    } else {
      setCurrentPage(1)
    }
  }, [pages])

  // set max page number
  useEffect(() => {
    const maxPages = Math.ceil(projectsAmount / perPage)
    setPages(maxPages)
  }, [perPage, projectsAmount])

  // set page numbers to render links
  useEffect(() => {
    const numbers = []
    for (let i = 1; i < pages + 1; i += 1) {
      numbers.push({
        active: currentPage === i,
        page: i,
        url: `?page=${i}`,
      })
    }
    setPageNumbers(numbers)
  }, [pages, currentPage])

  return (
    <nav className={styles.project_paginate_number}>
      <a
        href={prevLink}
        disabled={!prevLink.includes('?')}
        className={styles.prev}
      >
        <ArrowRightMini />
      </a>
      {pageNumbers.map((number) => (
        <a
          href={number.url}
          className={number.active ? styles.active : ''}
          key={`${number.page}-${Math.random()}`}
        >
          {number.page}
        </a>
      ))}
      <a
        href={nextLink}
        disabled={currentPage === pages}
        className={styles.next}
      >
        <ArrowRightMini />
      </a>
    </nav>
  )
}

Pagination.propTypes = {
  perPage: PropTypes.number.isRequired,
  projectsAmount: PropTypes.number.isRequired,
}

export default Pagination
