import React from 'react'
import LoggedOutTemplate from 'templates/LoggedOutTemplate'
import CreateAccountTemplate from './components/CreateAccountTemplate'
import useCreateAccount from './hooks'

function CreateAccount() {
  const { formik, hasErrors } = useCreateAccount()

  return (
    <LoggedOutTemplate>
      <CreateAccountTemplate formik={formik} hasErrors={hasErrors} />
    </LoggedOutTemplate>
  )
}

export default CreateAccount
