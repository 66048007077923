import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import expertsService from 'services/experts'
import { getAuthToken } from 'utils/local-storage'
import routesNames from 'routes/routesNames'
import { useLoading } from 'context/loading'
import convertToReadableDate from 'utils/helpers/convertToReadableDate'
import delay from 'utils'

const initialValues = [
  {
    desc: 'Candidatura enviada',
    id: 1,
    status: 'complete',
    title: 'Candidatura',
  },
  {
    desc: 'Aguardando análise',
    id: 2,
    status: 'waiting',
    title: 'Análise do perfil pela Comunidade',
  },
  {
    buttons: [
      { fill: 'outline', request: 'refused_expert', text: 'Recusar' },
      { fill: 'flat', request: 'confirmed_expert', text: 'Confirmar' },
    ],
    desc:
      'Você deve <span>confirmar</span> abaixo o seu interesse <span>em até 24 horas</span> para enviarmos a proposta.',
    id: 3,
    title: 'Formalização do Interesse',
    // type: 'grouped_bottom',
  },
  {
    // buttons: [
    //   {
    //     fill: 'flat',
    //     request: 'read',
    //     text: 'Ler e Assinar',
    //   },
    // ],
    desc:
      'Em breve você receberá o contrato por e-mail. Assine-o para concluir a alocação',
    id: 4,
    status: '',
    title: 'Assinatura da proposta comercial',
    // type: 'grouped_top',
  },
]

export default function useSelectiveProcess({
  getProject,
  myProject,
  projectId,
}) {
  const [allocationProcess, setAllocationProcess] = useState(initialValues)
  const [modalAllocated, setModalAllocated] = useState(false)
  const [modalRefused, setModalRefused] = useState(false)
  const { dispatch: dispatchLoading } = useLoading()
  const token = getAuthToken()
  const history = useHistory()
  const dateRefusedByExpert = convertToReadableDate(myProject.updated_at)

  function isRefused() {
    return myProject.status === 'refused'
  }

  function isRefusedByExpert() {
    return myProject.status === 'refused_expert'
  }

  async function onCloseModal(e) {
    e.preventDefault()
    setModalAllocated(false)
    setModalRefused(false)
  }

  async function openModalAllocated() {
    await delay(3000)
    setModalAllocated(true)
  }

  async function openModalRefused() {
    setModalRefused(true)
  }

  function redirectToMyProjects() {
    history.push(routesNames.experts.myProjects)
  }

  function redirectToOpportunities() {
    history.push(routesNames.experts.showcaseOpportunities)
  }

  const updateStatus = useCallback(
    (items) => {
      const newAllocation = allocationProcess?.reduce((acc, curr) => {
        const values = items?.find((item) => item.id === curr.id)
        return acc.concat(
          curr.id === values?.id
            ? {
              ...curr,
              desc: values.desc || curr.desc,
              status: values?.status,
            }
            : curr,
        )
      }, [])
      if (
        allocationProcess
        && JSON.stringify(allocationProcess) !== JSON.stringify(newAllocation)
      ) {
        setAllocationProcess(newAllocation)
      }
    },
    [allocationProcess],
  )

  const updateAllocation = useCallback(
    (data) => {
      updateStatus(data)
    },
    [updateStatus],
  )

  const onClickButton = useCallback(
    async (e) => {
      const typeRequest = e.target.dataset.request

      if (['confirmed_expert', 'refused_expert'].includes(typeRequest)) {
        dispatchLoading({
          payload: true,
          type: 'set',
        })
        expertsService.opportunities
          .job_application_update(projectId, token, { status: typeRequest })
          .then(() => {
            getProject()
          })
          .finally(() => {
            dispatchLoading({
              payload: false,
              type: 'set',
            })
          })
      }
    },
    [projectId, token, dispatchLoading, getProject],
  )

  useEffect(() => {
    if (myProject.status === 'refused') {
      updateAllocation([
        {
          desc:
            '😟 Não foi dessa vez. Infelizmente você não foi selecionado para este projeto. Cheque seu email para entender mais sobre a recusa. Se tiver mais dúvidas entre em contato com a comunidade!',
          id: 2,
          status: 'refused',
        },
      ])
    }

    if (myProject.status === 'confirmed') {
      updateAllocation([
        { desc: 'Análise concluída', id: 2, status: 'complete' },
        { id: 3, status: 'waiting' },
      ])
    }

    if (
      [
        'confirmed_expert',
        'submit_proposal',
        'proposal_send',
        'sign_proposal',
      ].includes(myProject.status)
    ) {
      updateAllocation([
        { desc: 'Análise concluída', id: 2, status: 'complete' },
        { desc: 'Confirmado', id: 3, status: 'complete' },
        { id: 4, status: 'waiting' },
      ])
    }

    if (myProject.status === 'allocated') {
      updateAllocation([
        { desc: 'Análise concluída', id: 2, status: 'complete' },
        { desc: 'Confirmado', id: 3, status: 'complete' },
        {
          desc: 'Proposta assinada. Aguarde o início do projeto.',
          id: 4,
          status: 'complete',
        },
      ])
      openModalAllocated()
    }
    if (myProject.status === 'refused_expert') {
      openModalRefused()
    }
  }, [myProject, myProject.status, updateAllocation, onClickButton])

  return {
    allocationProcess,
    dateRefusedByExpert,
    isRefused,
    isRefusedByExpert,
    modalAllocated,
    modalRefused,
    onClickButton,
    onCloseModal,
    redirectToMyProjects,
    redirectToOpportunities,
    setAllocationProcess,
    setModalAllocated,
  }
}
