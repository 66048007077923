import PropTypes from 'prop-types'

function Condition({ children, condition, elseCondition }) {
  return condition ? children : elseCondition
}

Condition.propTypes = {
  children: PropTypes.node.isRequired,
  condition: PropTypes.bool.isRequired,
  elseCondition: PropTypes.node,
}

Condition.defaultProps = {
  elseCondition: null,
}

export default Condition
