import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import InputMask from 'react-input-mask'

import styles from '../styles.module.css'

function MaskInput({
  error, mask, onChange, ...rest 
}) {
  function handleChange(event) {
    event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, '') // eslint-disable-line
    onChange(event)
  }

  return (
    <InputMask
      className={classNames(styles.input, error && styles.error)}
      mask={mask}
      onChange={handleChange}
      {...rest}
    />
  )
}

MaskInput.propTypes = {
  error: PropTypes.string,
  id: PropTypes.string,
  mask: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
}

MaskInput.defaultProps = {
  error: null,
  id: null,
  mask: null,
}

export default memo(MaskInput)
