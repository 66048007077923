import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link, withRouter } from 'react-router-dom'
import routesNames from 'routes/routesNames'
import Condition from 'components/Condition'
import { getIsAuth } from 'utils/local-storage'
import styles from './styles.module.css'

/* eslint-disable */
const Deactivate = ({ userDomainArea, userRole, userState, userStatus }) => {
  const [clickClose, setClickClose] = useState(false)
  const isAuth = getIsAuth()

  const isUserInactive = useCallback(
    () => isAuth && userState && userStatus === 'inactive',
    [isAuth, userState, userStatus]
  )

  const isNoRoleAndDomainArea = useCallback(
    () => isAuth && userState && (userDomainArea === null || userRole === null),
    [isAuth, userState, userDomainArea, userRole]
  )

  function handleClickClose(e) {
    console.log(e)
    e.preventDefault()
    setClickClose(true)
  }

  return (
    <div
      className={classNames(
        styles.bar,
        (isUserInactive() || isNoRoleAndDomainArea()) && styles.open,
        clickClose && styles.is_close
      )}
    >
      <div className={styles.hold}>
        <Condition condition={isUserInactive()}>
          <Inactive />
        </Condition>
        <Condition condition={isNoRoleAndDomainArea() && !isUserInactive()}>
          <NoRoleAndDomainArea />
        </Condition>
        <button
          type="button"
          className={styles.close}
          onClick={handleClickClose}
        >
          X
        </button>
      </div>
    </div>
  )
}

const Inactive = () => (
  <p className={styles.text}>
    Sua conta está inativa. Para trabalhar em novos projetos, você deve
    <Link className={styles.link} to={routesNames.experts.accountActivate}>
      ativar sua conta
    </Link>
    .
  </p>
)

const NoRoleAndDomainArea = () => (
  <p className={styles.text}>
    Seja bem vindo a plataforma Cognitivo.ai. Após a criação da sua conta, a
    equipe de comunidade irá validar seu cadastro com as informações do processo
    seletivo. Durante este período, as funcionalidades da vitrine estarão
    desativadas para você.
  </p>
)

Deactivate.propTypes = {
  userDomainArea: PropTypes.string,
  userRole: PropTypes.string,
  userState: PropTypes.shape(PropTypes.object),
  userStatus: PropTypes.string,
}

Deactivate.defaultProps = {
  userDomainArea: null,
  userRole: null,
  userState: null,
  userStatus: 'active',
}

export default withRouter(Deactivate)
