import expertService from 'services/experts'
import { getAuthToken } from 'utils/local-storage'

const makeGetOpportunitiesRequest = async ({ id, perPage } = {}) => {
  const token = getAuthToken()
  const result = await expertService.opportunities.get({
    id,
    perPage,
    token,
  })

  return result
}

const makeGetOpportunitiesRecommendedRequest = async (id) => {
  const token = getAuthToken()
  const result = await expertService.opportunities.recommended(id, token)

  return result
}

const makePostRegisterOpportunity = async (id, payload) => {
  const token = getAuthToken()
  const result = await expertService.opportunities.register(id, payload, token)

  return result
}

const makePostUnregisterOpportunity = async (id) => {
  const token = getAuthToken()
  const result = await expertService.opportunities.unregister(id, token)

  return result
}

export {
  makeGetOpportunitiesRecommendedRequest,
  makeGetOpportunitiesRequest,
  makePostRegisterOpportunity,
  makePostUnregisterOpportunity,
}
