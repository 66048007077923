/* eslint-disable */
import React from 'react'
import { motion } from 'framer-motion'

import styles from './styles.module.css'

const Ellipse = () => (
  <motion.div
    className={styles.ellipse}
    animate={{
      scale: [0.8, 1, 1, 0.8, 1],
    }}
    transition={{
      duration: 2,
      ease: 'easeInOut',
      times: [0, 0.2, 0.5, 0.8, 1],
      repeatDelay: 1,
    }}
  />
)

export default Ellipse
