import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Condition from 'components/Condition'
import Pagination from 'components/ReferralPagination'
import styles from './styles.module.css'

function ReferralSection({
  dataSource = [],
  desc,
  perPage,
  renderElse,
  renderItem,
  showPagination,
  showQuantity,
  title,
  totalCount,
}) {
  const [sources, setSources] = useState([])
  const [items, setItems] = useState([])
  const [hasItems, setHasItems] = useState(false)
  const [fakeItems, setFakeItems] = useState([])

  function renderItemsComponents(data) {
    const sourceItems = data.map((item, index) => renderItem(item, index))
    return sourceItems
  }

  function toArray(value) {
    return new Array(value).fill('').map((_, i) => (i + 1) * 4)
  }

  useEffect(() => {
    // const sourcePerPage = filterPerPage(perPage)
    const itemsPerPage = renderItemsComponents(dataSource) // renderItemsComponents(sourcePerPage)
    setSources(dataSource)
    setItems(itemsPerPage)
    setHasItems(dataSource.length > 0)
    if (showQuantity) {
      const fakeArr = toArray(perPage - dataSource.length)
      setFakeItems(fakeArr)
    }
  }, [dataSource]) // eslint-disable-line

  return (
    <div className={styles.project_section}>
      <div className={styles.project_section_wrap}>
        <div className={styles.project_section_header}>
          <h2 className={styles.project_section_title}>{title}</h2>
          <Condition condition={!!desc}>
            <p className={styles.project_section_desc}>{desc}</p>
          </Condition>
          <Condition condition={!!showQuantity}>
            <p className={styles.project_section_quantity}>
              Mostrando
              {' '}
              {sources.length}
              {' '}
              de
              {' '}
              {totalCount}
            </p>
          </Condition>
        </div>
      </div>
      <div className={styles.project_section_wrap}>
        <Condition condition={!!hasItems} elseCondition={renderElse}>
          <div className={styles.project_section_list}>
            {items}
            <Condition condition={!!fakeItems?.length}>
              {fakeItems?.map((obj) => (
                // eslint-disable-next-line react/jsx-key
                <div key={obj}></div>
              ))}
            </Condition>
          </div>
        </Condition>

        <Condition condition={!!dataSource.length && !!showPagination}>
          <div className={styles.project_section_pagination}>

            <Pagination perPage={perPage} projectsAmount={totalCount} />
          </div>
        </Condition>
      </div>
    </div>
  )
}

ReferralSection.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string,
      href: PropTypes.string,
      image: PropTypes.node,
      title: PropTypes.string,
    }),
  ).isRequired,
  desc: PropTypes.string,
  perPage: PropTypes.number,
  renderElse: PropTypes.node,
  renderItem: PropTypes.func.isRequired,
  showPagination: PropTypes.bool,
  showQuantity: PropTypes.bool,
  title: PropTypes.string.isRequired,
  totalCount: PropTypes.number,
}

ReferralSection.defaultProps = {
  desc: '',
  perPage: 8,
  renderElse: null,
  showPagination: false,
  showQuantity: false,
  totalCount: 8,
}

export default ReferralSection
