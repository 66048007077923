/* eslint-disable */
import React, { Fragment, memo, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactSelect from 'react-select'

import styles from './styles.module.css'
import selectStyles from '../styles.module.css'

const MultiSelect = ({
  error,
  id,
  name,
  onChange,
  options,
  value: currentValue,
}) => {
  const [values, setValues] = useState([])
  const selectRef = useRef()

  useEffect(() => {
    setValues(currentValue)
  }, [])

  function handleChange(e) {
    if (onChange) {
      return onChange(e)
    }
    return null
  }

  return (
    <Fragment>
      <ReactSelect
        ref={selectRef}
        className={classNames(styles.select, error && styles.error)}
        defaultValue={currentValue || values}
        id={id}
        isMulti
        key={id}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        name={name}
        onChange={handleChange}
        options={options}
        placeholder="Escolha uma opção"
      />
      <span className={selectStyles.error_message}>{error}</span>
    </Fragment>
  )
}

MultiSelect.propTypes = {
  error: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
}

MultiSelect.defaultProps = {
  error: null,
  id: null,
  onChange: null,
  value: null,
}

export default memo(MultiSelect)
