import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.css'

function Badge({ title, type }) {
  return <span className={classNames(styles.title, styles[type])}>{title}</span>
}

Badge.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['danger', 'in_progress', 'success']).isRequired,
}

export default memo(Badge)
