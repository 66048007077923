import React from 'react'
import routesNames from 'routes/routesNames'
import { Dashboard, FormError } from 'components'
import PersonalInfo from './components/PersonalInfo'
import ProfessionalInfo from './components/ProfessionalInfo'
import ResidentialInfo from './components/ResidentialInfo'
import useUpdateAccount from './hooks/useUpdateAccount'
import styles from './styles.module.css'

function UpdateAccount() {
  const {
    activitiesConcepts,
    businessSegments,
    dataStorages,
    domainAreas,
    formatedUserValues,
    getSkillsFromDomain,
    hasErrors,
    infrasClouds,
    languages,
    librariesFrameworks,
    onExit,
    onSubmit,
    progressForms,
    setHasErrors,
    toolsPlatforms,
    user,
  } = useUpdateAccount()

  return (
    <Dashboard
      backTo={routesNames.experts.showcaseOpportunities}
      onExit={onExit}
      username={user.full_name}
      type="experts"
      title="Atualizar informações"
      description="Todos os seus dados cadastrados estão aqui.
      Eles são utilizados pela Área de Comunidade, apenas para o
      recrutamento e seleção da rede de experts Cognitivo.ai."
    >
      <div className={styles.wrapper}>
        <FormError hasErrors={hasErrors} />
        <PersonalInfo
          key="personal-info"
          formatedUserValues={formatedUserValues}
          onSubmit={onSubmit}
          progressForms={progressForms.personalProgress}
          setHasErrors={setHasErrors}
          user={user}
        />
        <ProfessionalInfo
          key="professional-info"
          formatedUserValues={formatedUserValues}
          getSkillsFromDomain={getSkillsFromDomain}
          onSubmit={onSubmit}
          progressForms={progressForms.professionalProgress}
          setHasErrors={setHasErrors}
          activitiesConcepts={activitiesConcepts}
          businessSegments={businessSegments}
          dataStorages={dataStorages}
          domainAreas={domainAreas}
          infrasClouds={infrasClouds}
          languages={languages}
          librariesFrameworks={librariesFrameworks}
          toolsPlatforms={toolsPlatforms}
        />
        <ResidentialInfo
          key="residential-info"
          formatedUserValues={formatedUserValues}
          onSubmit={onSubmit}
          progressForms={progressForms.residentialProgress}
        />
      </div>
    </Dashboard>
  )
}

export default UpdateAccount
