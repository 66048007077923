function shouldShowError(key, formik) {
  // if (formik.values[key] && formik.values[key].length > 0) {
  //   return formik.values[key] && formik.errors[key]
  // }

  // return formik.touched[key] && formik.errors[key]

  return formik.errors[key]
}

export default shouldShowError
