import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.css'

function Progress({ percent }) {
  return (
    <div className={styles.progress}>
      <div className={styles.progress_bar} style={{ width: `${percent}%` }}>
        {percent}
      </div>
    </div>
  )
}

Progress.propTypes = {
  percent: PropTypes.number,
}

Progress.defaultProps = {
  percent: 0,
}

export default Progress
