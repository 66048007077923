import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, FormBox, FormError, Input, 
} from 'components'
import LoggedOutTemplate from 'templates/LoggedOutTemplate'

import shouldShowError from 'utils/helpers/form/shouldShowError'
import shouldDisableButton from 'utils/helpers/form/shouldDisableButton'

import styles from './styles.module.css'
import useResetPassword from './hooks'

function ResetPassword({ location }) {
  const { formik, hasErrors } = useResetPassword({
    location,
  })

  return (
    <LoggedOutTemplate>
      <div className={styles.page}>
        <main className={styles.main}>
          <FormError
            className={styles.alert}
            hasErrors={hasErrors}
            message={hasErrors.message}
          />
          <FormBox title="Redefinir senha">
            <form onSubmit={formik.handleSubmit} className={styles.form}>
              <Input
                name="password"
                label="Nova senha"
                placeholder="Digite uma nova senha"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={shouldShowError('password', formik)}
              />
              <Input
                name="password_confirm"
                label="Confirmar nova senha"
                placeholder="Confirme sua nova senha"
                type="password"
                value={formik.values.password_confirm}
                onChange={formik.handleChange}
                error={shouldShowError('password_confirm', formik)}
              />
              <Button
                text="Concluir"
                type="submit"
                disabled={shouldDisableButton(formik)}
                expand="x"
              />
            </form>
            <p className={styles.disclaimer}>
              A senha deve ter, no mínimo, 8 caracteres.
            </p>
          </FormBox>
        </main>
      </div>
    </LoggedOutTemplate>
  )
}

ResetPassword.propTypes = {
  location: PropTypes.func.isRequired,
}

export default ResetPassword
