import React from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'

import { Button, Input, Select } from 'components'
import { dataMaritalStatus } from 'data'
import shouldShowError from 'utils/helpers/form/shouldShowError'
import shouldDisableButton from 'utils/helpers/form/shouldDisableButton'
import BoxWithTitle from '../BoxWithTitle'
import styles from '../../styles.module.css'
import PersonaSchema from './schema'

function PersonalInfo({
  formatedUserValues,
  onSubmit,
  progressForms,
  setHasErrors,
  user,
}) {
  const formik = useFormik({
    initialValues: {
      cell_phone: formatedUserValues.cell_phone || '',
      marital_status: formatedUserValues.marital_status || '',
    },
    onSubmit: (values) => onSubmit(values, setHasErrors),
    validationSchema: PersonaSchema,
  })

  return (
    <BoxWithTitle
      progress={progressForms.progress}
      title="Informações pessoais"
    >
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <Input
          name="full_name"
          label="Nome completo"
          placeholder="Digite seu nome completo"
          type="text"
          value={formik.values.full_name || user.full_name}
          disabled
        />
        <Input
          name="email"
          label="Email"
          placeholder="Digite seu email"
          type="text"
          value={formik.values.email || user.email}
          disabled
        />
        <Input
          name="cpf"
          label="CPF"
          placeholder="Digite seu cpf"
          type="text"
          mask="999.999.999-99"
          value={formik.values.cpf || user.cpf}
          disabled
        />
        <Input
          name="cell_phone"
          label="Celular"
          placeholder="Ex.: +55 (11)99456-9859"
          type="text"
          mask="+99 (99)99999-9999"
          value={formik.values.cell_phone}
          onChange={formik.handleChange}
          error={shouldShowError('cell_phone', formik)}
        />
        <Select
          name="marital_status"
          label="Estado civil"
          options={dataMaritalStatus}
          onChange={formik.handleChange}
          error={shouldShowError('marital_status', formik)}
          value={formik.values.marital_status}
        />
        <Button
          text="Atualizar"
          type="submit"
          disabled={shouldDisableButton(formik)}
        />
      </form>
    </BoxWithTitle>
  )
}

PersonalInfo.propTypes = {
  formatedUserValues: PropTypes.shape({
    cell_phone: PropTypes.string,
    marital_status: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  progressForms: PropTypes.shape({
    progress: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  setHasErrors: PropTypes.func.isRequired,
  user: PropTypes.shape({
    cell_phone: PropTypes.string.isRequired,
    cpf: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    full_name: PropTypes.string.isRequired,
    marital_status: PropTypes.string.isRequired,
  }).isRequired,
}

PersonalInfo.defaultProps = {
  formatedUserValues: {
    cell_phone: null,
    marital_status: null,
  },
  progressForms: {
    progress: 0,
  },
}

export default PersonalInfo
