/* eslint-disable */
import React from 'react'
import { motion } from 'framer-motion'

import styles from './styles.module.css'

const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: 'rgba(0, 73, 255, 0)',
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: 'rgba(0, 73, 255, 1)',
  },
}

const Wave = () => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 890 413"
    className={styles.wave}
  >
    <motion.path
      d="M890 16.8008V443.3H0v-156c603 36 572.5-374.0003 890-270.4992z"
      variants={icon}
      initial="hidden"
      animate="visible"
      transition={{
        default: { duration: 1, ease: 'easeIn' },
        fill: { duration: 2, ease: [1, 0, 0.8, 1] },
      }}
    />
  </motion.svg>
)

export default Wave
