import React from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'

import { Button, Input, Select } from 'components'
import { dataStates } from 'data'
import shouldShowError from 'utils/helpers/form/shouldShowError'
import shouldDisableButton from 'utils/helpers/form/shouldDisableButton'
import BoxWithTitle from '../BoxWithTitle/index'
import styles from '../../styles.module.css'
import ResidentialSchema from './schema'

function ResidentialInfo({
  formatedUserValues,
  onSubmit,
  progressForms,
  setHasErrors,
}) {
  const formik = useFormik({
    initialValues: {
      address: formatedUserValues.address || '',
      city: formatedUserValues.city || '',
      complement: formatedUserValues.complement || '',
      neighborhood: formatedUserValues.neighborhood || '',
      number: formatedUserValues.number || '',
      postal_code: formatedUserValues.postal_code || '',
      state: formatedUserValues.state || '',
    },
    onSubmit: (values) => onSubmit(values, setHasErrors),
    validationSchema: ResidentialSchema,
  })

  function postalCodeHandleChange(input) {
    formik.handleChange(input)
  }

  return (
    <BoxWithTitle
      progress={progressForms.progress}
      title="Informações residenciais"
    >
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <Input
          name="postal_code"
          label="CEP"
          placeholder="Digite seu CEP"
          type="text"
          mask="99999-999"
          value={formik.values.postal_code}
          onChange={postalCodeHandleChange}
          error={shouldShowError('postal_code', formik)}
        />
        <Input
          name="address"
          label="Endereço"
          placeholder="Digite seu endereço"
          type="text"
          value={formik.values.address}
          onChange={formik.handleChange}
          error={shouldShowError('address', formik)}
        />
        <div className={styles.two_columns_input_second_big_one}>
          <Input
            name="number"
            label="Número"
            placeholder="Digite o número"
            type="number"
            value={formik.values.number}
            onChange={formik.handleChange}
            error={shouldShowError('number', formik)}
          />
          <Input
            name="complement"
            label="Complemento"
            placeholder="Digite o complemento do endereço"
            type="text"
            value={formik.values.complement}
            onChange={formik.handleChange}
            error={shouldShowError('complement', formik)}
          />
        </div>
        <Input
          name="neighborhood"
          label="Bairro"
          placeholder="Digite o bairro do endereço"
          type="text"
          value={formik.values.neighborhood}
          onChange={formik.handleChange}
          error={shouldShowError('neighborhood', formik)}
        />
        <div className={styles.two_columns_input_first_big_one}>
          <Input
            name="city"
            label="Cidade"
            placeholder="Digite o cidade do endereço"
            type="text"
            value={formik.values.city}
            onChange={formik.handleChange}
            error={shouldShowError('city', formik)}
          />
          <Select
            name="state"
            label="Estado"
            options={dataStates}
            onChange={formik.handleChange}
            error={shouldShowError('state', formik)}
            value={formik.values.state}
          />
        </div>
        <Button
          text="Atualizar"
          type="submit"
          disabled={shouldDisableButton(formik)}
        />
      </form>
    </BoxWithTitle>
  )
}

ResidentialInfo.propTypes = {
  formatedUserValues: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    complement: PropTypes.string,
    neighborhood: PropTypes.string,
    number: PropTypes.string,
    postal_code: PropTypes.string,
    state: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  progressForms: PropTypes.shape({
    progress: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  setHasErrors: PropTypes.func.isRequired,
}

ResidentialInfo.defaultProps = {
  formatedUserValues: {
    address: '',
    city: '',
    complement: '',
    neighborhood: '',
    number: '',
    postal_code: '',
    state: '',
  },
  progressForms: {
    progress: 0,
  },
}

export default ResidentialInfo
