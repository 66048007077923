export default function arrayToCommas(data) {
  if (!data) return null

  const toComma = (acc, curr) => {
    const key = curr.skill_title ? 'skill_title' : 'title'

    return acc.concat(curr[key] ? curr[key] : curr)
  }
  const result = data.reduce(toComma, [])

  return result.join(', ')
}
