import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.css'
import ErrorIcon from '../../assets/img/error.svg'
import WarningIcon from '../../assets/img/warning.svg'
import InfoIcon from '../../assets/img/info.svg'
import SuccessIcon from '../../assets/img/success.svg'

const icon = {
  error: ErrorIcon,
  info: InfoIcon,
  success: SuccessIcon,
  warning: WarningIcon,
}

function Alert({ children, className, severity }) {
  return (
    <div className={classNames(styles.alert, styles[severity], className)}>
      <img className={styles.icon} src={icon[severity]} alt="Logo de alerta" />
      <p className={classNames(styles.text, styles[severity])}>{children}</p>
    </div>
  )
}

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']).isRequired,
}

Alert.defaultProps = {
  className: null,
}

export default memo(Alert)
