import { POST } from '../../utils/helpers/request'

const auth = {
  refresh_token: (token) => {
    const endpoint = 'auth/refresh-token/'
    const payload = {
      token,
    }
    const config = {
      body: JSON.stringify(payload),
    }

    return POST(endpoint, config)
  },
  reset_password: (payload) => {
    const endpoint = 'auth/reset-password/'
    const config = {
      body: JSON.stringify(payload),
    }

    return POST(endpoint, config)
  },
  reset_password_confirm: (payload) => {
    const endpoint = 'auth/reset-password/confirm/'
    const config = {
      body: JSON.stringify(payload),
    }

    return POST(endpoint, config)
  },
  reset_password_validate_token: (payload) => {
    const endpoint = 'auth/reset-password/validate_token/'
    const config = {
      body: JSON.stringify(payload),
    }

    return POST(endpoint, config)
  },
  token: (username, password) => {
    const endpoint = 'auth/token/'
    const payload = {
      password,
      username,
    }
    const config = {
      body: JSON.stringify(payload),
    }

    return POST(endpoint, config)
  },
}

export default auth
