import React from 'react'
import ReactDOM from 'react-dom'
import Rollbar from 'rollbar'

import './index.css'
import Routes from './routes'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

import { LoadingProvider } from './context/loading'
import { UsernameProvider } from './context/username'
import { FeedbackProvider } from './context/feedback'

import 'bootstrap/dist/css/bootstrap.min.css'

if (
  process.env.NODE_ENV === 'production'
  || process.env.NODE_ENV === 'homolog'
) {
  // eslint-disable-next-line no-new
  new Rollbar({
    accessToken: '7df7a31b58154d7192d3e9522329591f',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.NODE_ENV,
    },
  })
}

ReactDOM.render(
  <React.StrictMode>
    <UsernameProvider>
      <LoadingProvider>
        <FeedbackProvider>
          <Routes />
        </FeedbackProvider>
      </LoadingProvider>
    </UsernameProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
