import * as Yup from 'yup'

import messages from 'utils/yup/messages'

const schema = {
  email: Yup.string()
    .min(5, messages.string.min(5))
    .email(messages.string.email)
    .required(messages.string.required),
}

const yupValidator = Yup.object(schema)

export default yupValidator
