import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.css'

function Box({ children, className }) {
  return (
    <div
      className={classNames(styles.box, className)}
      tabIndex="0"
      role="button"
    >
      {children}
    </div>
  )
}

Box.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
}

Box.defaultProps = {
  className: null,
}

export default memo(Box)
