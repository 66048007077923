import React from 'react'
import { Link } from 'react-router-dom'
import routesNames from 'routes/routesNames'
import { ReactComponent as IconUser } from 'assets/img/icon-user-gray.svg'
import { ReactComponent as IconCifrao } from 'assets/img/icon-cifrao-gray.svg'
// import { ReactComponent as IconClock } from 'assets/img/icon-clock-gray.svg'
import items from '../Nav/items'
import restrictedItems from '../Nav/restricted-items' // Apenas usuários internos podem acessar.
import styles from './styles.module.css'
import { useUsername } from '../../../../context/username'

function DropdownMenu() {
  const { state } = useUsername()

  return (
    <nav className={styles.dropdown_menu}>
      <div className={styles.dropdown_menu_aditional}>
        {items.map((item) => (
          <Link
            to={item.link}
            key={item.name}
            className={styles.dropdown_menu_link}
          >
            <span className={styles.dropdown_menu_icon}>
              <item.icon />
            </span>
            {item.name}
          </Link>
        ))}
        {state?.user?.is_internal && restrictedItems.map((item) => (
          <Link
            to={item.link}
            key={item.name}
            className={styles.dropdown_menu_link}
          >
            <span className={styles.dropdown_menu_icon}>
              <item.icon />
            </span>
            {item.name}
          </Link>
        ))}
      </div>
      {/* // ------ */}
      <Link
        to={routesNames.experts.profilePersonalData}
        className={styles.dropdown_menu_link}
      >
        <span className={styles.dropdown_menu_icon}>
          <IconUser />
        </span>
        Dados pessoais
      </Link>
      <Link
        to={routesNames.experts.profileProfessionalData}
        className={styles.dropdown_menu_link}
      >
        <span className={styles.dropdown_menu_icon}>
          <IconCifrao />
        </span>
        Informações profissionais
      </Link>
      {/* <Link
      to={routesNames.experts.home}
      className={styles.dropdown_menu_link}
    >
      <span className={styles.dropdown_menu_icon}>
        <IconClock />
      </span>
      Disponibilidade
    </Link> */}
    </nav>
  )
}

export default DropdownMenu
