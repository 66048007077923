import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import routesNames from 'routes/routesNames'

import {
  Button, FormBox, FormError, Input, 
} from 'components'

import shouldShowError from 'utils/helpers/form/shouldShowError'
import shouldDisableButton from 'utils/helpers/form/shouldDisableButton'
import { ReactComponent as ArrowRightMini } from 'assets/img/arrow-right-mini.svg'

import styles from './styles.module.css'

function ForgotPasswordTemplate({ formik, hasErrors }) {
  return (
    <div className={styles.page}>
      <main className={styles.main}>
        <FormError
          className={styles.alert}
          hasErrors={hasErrors}
          message="Houve um problema ao enviar o email. Tente novamente mais tarde."
        />
        <FormBox
          title="Esqueceu sua senha?"
          desc="Insira o endereço de email associado à sua conta para enviarmos um link de redefinição de senha"
        >
          <form onSubmit={formik.handleSubmit} className={styles.form}>
            <Input
              name="email"
              label="E-mail cadastrado"
              type="email"
              placeholder="email@example.com"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={shouldShowError('email', formik)}
            />
            <Button
              text="Enviar link de redefinição"
              type="submit"
              disabled={shouldDisableButton(formik)}
              expand="x"
            />
          </form>
        </FormBox>
        <div className={styles.links}>
          <p className={styles.text}>
            Já possui acesso?
            {' '}
            <Link to={routesNames.experts.login} className={styles.link}>
              Faça login
              <ArrowRightMini />
            </Link>
          </p>
          <p className={styles.text}>
            Novo por aqui?
            {' '}
            <Link
              to={routesNames.experts.createAccount}
              className={styles.link}
            >
              Crie sua conta
              <ArrowRightMini />
            </Link>
          </p>
        </div>
      </main>
    </div>
  )
}

ForgotPasswordTemplate.propTypes = {
  formik: PropTypes.func.isRequired,
  hasErrors: PropTypes.bool.isRequired,
}

export default ForgotPasswordTemplate
