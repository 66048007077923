import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import routesNames from 'routes/routesNames'

import {
  Button, Dashboard, FormError, Input, 
} from 'components'

import shouldShowError from 'utils/helpers/form/shouldShowError'
import shouldDisableButton from 'utils/helpers/form/shouldDisableButton'

import styles from './styles.module.css'
import Schema from './schema'

function AccountChangePassword({ onExit, onSubmit, user }) {
  const [hasErrors, setHasErrors] = useState(false)
  const formik = useFormik({
    initialValues: {
      confirm_new_password: '',
      new_password: '',
      old_password: '',
    },
    onSubmit: (values) => onSubmit(values, setHasErrors),
    validationSchema: Schema,
  })

  return (
    <Dashboard
      backTo={routesNames.experts.account}
      onExit={onExit}
      title="Alterar senha"
      type="experts"
      username={user.full_name}
    >
      <div className={styles.wrapper}>
        <FormError
          hasErrors={hasErrors}
          message="Houve um problema ao alterar a senha. Tente novamente mais tarde."
        />
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <Input
            name="old_password"
            label="Digite sua senha atual"
            type="password"
            value={formik.values.old_password}
            onChange={formik.handleChange}
            error={shouldShowError('old_password', formik)}
          />
          <Input
            name="new_password"
            label="Digite sua nova senha"
            type="password"
            value={formik.values.new_password}
            onChange={formik.handleChange}
            error={shouldShowError('new_password', formik)}
          />
          <Input
            name="confirm_new_password"
            label="Confirme sua nova senha"
            type="password"
            value={formik.values.confirm_new_password}
            onChange={formik.handleChange}
            error={shouldShowError('confirm_new_password', formik)}
          />
          <Button
            text="Concluir"
            type="submit"
            disabled={shouldDisableButton(formik)}
          />
        </form>
        <p className={styles.disclaimer}>
          A senha deve ter, no mínimo, 8 caracteres.
        </p>
      </div>
    </Dashboard>
  )
}

AccountChangePassword.propTypes = {
  onExit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
}

export default AccountChangePassword
