import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import routesNames from 'routes/routesNames'
import Condition from 'components/Condition'
import { ReactComponent as ArrowRightMini } from 'assets/img/arrow-right-mini.svg'
import styles from './styles.module.css'

function Breadcrumbs({ data }) {
  return (
    <div className={styles.breadcrumbs}>
      <Link className={styles.breadcrumbs_link} to={routesNames.experts.home}>
        home
      </Link>
      <ArrowRightMini />
      {data?.map((item, index) => (
        <Condition
          key={item.text}
          condition={item.to}
          elseCondition={(
            <>
              <span className={styles.breadcrumbs_text}>{item.text}</span>
              <Condition condition={index + 1 < data.length}>
                <ArrowRightMini />
              </Condition>
            </>
          )}
        >
          <Link className={styles.breadcrumbs_link} to={item.to}>
            {item.text}
          </Link>
          <ArrowRightMini />
        </Condition>
      ))}
    </div>
  )
}

Breadcrumbs.propTypes = {
  data: PropTypes.arrayOf().isRequired,
}

export default Breadcrumbs
