import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import styles from './styles.module.css'

function List({ dataSource = [], renderElse, renderItem }) {
  const items = dataSource.map((item, index) => renderItem(item, index))
  const hasItems = items.length > 0

  return (
    <div className={styles.list} key={`listkey-${Math.random()}`}>
      {hasItems ? items : renderElse}
    </div>
  )
}

function ListItem({
  key, link, status, title, 
}) {
  return (
    <div key={key} className={styles.list_item}>
      <div className={styles.list_subitem}>
        <p className={styles.list_title}>
          <Link to={link} className={styles.list_link}>
            {title}
          </Link>
        </p>
      </div>
      <div className={styles.list_subitem}>{status}</div>
    </div>
  )
}

List.Item = ListItem

List.propTypes = {
  // children: PropTypes.node.isRequired,
  dataSource: PropTypes.shape().isRequired,
  renderElse: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
}

ListItem.propTypes = {
  key: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default List
