import React, { Fragment, memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.css'
import selectStyles from '../styles.module.css'

function DefaultSelect({
  error,
  id,
  name,
  onChange,
  options,
  placeholder = 'Escolha uma opção',
  value,
}) {
  return (
    <>
      <div className={styles.select}>
        <select
          id={id}
          className={classNames(styles.standard_select, error && styles.error)}
          onChange={onChange}
          name={name}
          value={value}
        >
          <option disabled value={0}>
            {placeholder}
          </option>
          {options.map((item) => (
            <option key={`${item.value}-${item.value}`} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <span className={styles.focus}></span>
      </div>
      <span className={selectStyles.error_message}>{error}</span>
    </>
  )
}

DefaultSelect.propTypes = {
  error: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
}

DefaultSelect.defaultProps = {
  error: null,
  id: null,
  placeholder: null,
  value: null,
}

export default memo(DefaultSelect)
