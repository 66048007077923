import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

import { Card, Dashboard } from '../../../components'
import Task from './Task'
import styles from './styles.module.css'

function Home({
  currentSprintTasks = [],
  lastSprintTasks = [],
  loading,
  onExit,
  username,
}) {
  return (
    <Dashboard onExit={onExit} username={username} type="customers">
      <div className={styles.wrapper}>
        <Card title="Último sprint">
          {loading ? (
            <Skeleton height={500} />
          ) : (
            lastSprintTasks.map((task) => <Task key={task} task={task} />)
          )}
        </Card>
        <Card title="Sprint atual">
          {loading ? (
            <Skeleton height={500} />
          ) : (
            currentSprintTasks.map((task) => <Task key={task} task={task} />)
          )}
        </Card>
      </div>
    </Dashboard>
  )
}

Home.propTypes = {
  currentSprintTasks: PropTypes.arrayOf().isRequired,
  lastSprintTasks: PropTypes.arrayOf().isRequired,
  loading: PropTypes.bool.isRequired,
  onExit: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
}

export default Home
