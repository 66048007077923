import { POST, PUT } from 'utils/helpers/request'

const account = {
  active: (token) => {
    const endpoint = 'experts/active/'
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return POST(endpoint, config)
  },
  changePassword: (payload, token) => {
    const endpoint = 'auth/change-password/'
    const config = {
      body: JSON.stringify(payload),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return PUT(endpoint, config)
  },
  inactive: (payload, token) => {
    const endpoint = 'experts/inactive/'
    const config = {
      body: JSON.stringify(payload),
      headers: {
        Authorization: `JWT ${token}`,
      },
    }

    return POST(endpoint, config)
  },
}

export default account
