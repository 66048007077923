import expertService from 'services/experts'
import { getAuthToken } from 'utils/local-storage'
import formatOptionsFromRequest from 'utils/helpers/formatOptionsFromRequest'
import makeRequestMergingPagination from 'utils/helpers/request/makeRequestMergingPagination'

const makeGetDomainAreasRequest = async () => {
  const token = getAuthToken()
  const requestToDo = () => expertService.skills.domain_areas(token)

  const result = await makeRequestMergingPagination(requestToDo)
  const formatedResult = await formatOptionsFromRequest(result)

  return formatedResult
}

export default makeGetDomainAreasRequest
