import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.css'

function Card({
  children, className, headerLine, title, 
}) {
  return (
    <div className={classNames(styles.card, className)}>
      <div
        className={classNames(styles.header, {
          [styles.header_line]: headerLine,
        })}
      >
        <p className={styles.title}>{title}</p>
      </div>
      {children}
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
  headerLine: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

Card.defaultProps = {
  className: null,
  headerLine: true,
}

export default memo(Card)
