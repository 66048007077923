import * as Yup from 'yup'

import messages from 'utils/yup/messages'

const schema = {
  password: Yup.string()
    .min(8, messages.string.min(8))
    .required(messages.string.required),
  password_confirm: Yup.string()
    .min(8, messages.string.min(8))
    .required(messages.string.required)
    .oneOf([Yup.ref('password'), null], messages.string.equal_fields('senha')),
}

const yupValidator = Yup.object(schema)

export default yupValidator
