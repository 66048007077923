import * as Yup from 'yup'

import messages from 'utils/yup/messages'

const schema = {
  how_long: Yup.string().required(messages.string.required),
  reason: Yup.string().required(messages.string.required),
}

const yupValidator = Yup.object(schema)

export default yupValidator
