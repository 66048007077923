/* eslint-disable */
import React from 'react'
import Logo from './components/Logo'
import Nav from './components/Nav'
import NavUser from './components/NavUser'
import styles from './styles.module.css'

const HeaderLoggedin = ({ onExit, state }) => (
  <header className={styles.header}>
    <div className={styles.header_container}>
      <div className={styles.header_section}>
        <Logo />
      </div>
      <div className={styles.header_section}>
        <Nav />
      </div>
      <div className={styles.header_section}>
        <NavUser onExit={onExit} state={state} />
      </div>
    </div>
  </header>
)

export default HeaderLoggedin
