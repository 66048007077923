import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Condition from 'components/Condition'
import Buttons from '../Buttons'
import Progress from '../Progress'

import styles from './styles.module.css'

const checkActive = (step, index) => step === index && styles.active

function Steps({
  dataSteps, getStepCurrent, handleSetStep, size, 
}) {
  return (
    <div
      className={classNames(
        styles.steps,
        getStepCurrent > 0 && styles.steps_flex,
      )}
    >
      <div>
        <Condition condition={getStepCurrent > 0}>
          <Progress getStepCurrent={getStepCurrent} size={size} />
        </Condition>
        {dataSteps?.map((dataStep, index) => (
          <div
            key={dataStep.id}
            data-step={index}
            className={classNames(
              styles.step,
              checkActive(getStepCurrent, index),
            )}
          >
            <div className={styles.hold}>
              <h2
                className={styles.title}
                /* eslint-disable react/no-danger */
                dangerouslySetInnerHTML={{
                  __html: dataStep.title,
                }}
              />
              <p
                className={styles.desc}
                dangerouslySetInnerHTML={{
                  __html: dataStep.desc,
                }}
              />
              <Buttons items={dataStep.buttons} handleSetStep={handleSetStep} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

Steps.propTypes = {
  dataSteps: PropTypes.arrayOf(
    PropTypes.shape({
      buttons: PropTypes.arrayOf,
      desc: PropTypes.string,
      id: PropTypes.bool,
      title: PropTypes.string,
    }),
  ).isRequired,
  getStepCurrent: PropTypes.number.isRequired,
  handleSetStep: PropTypes.func.isRequired,
  size: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default Steps
