const expertsBase = '/experts/'
const customersBase = '/customers/'

const routesNames = {
  root: '/',
  customers: {
    agreements: `${customersBase}acordos`,
    default: customersBase,
    home: `${customersBase}home`,
    login: `${customersBase}login`,
    selectProject: `${customersBase}selecionar-projeto`,
    tasks: `${customersBase}tarefas`,
  },
  experts: {
    account: `${expertsBase}conta`,
    accountActivate: `${expertsBase}conta/ativar-conta/`,
    accountChangePassword: `${expertsBase}conta/alterar-senha/`,
    accountDeactivate: `${expertsBase}conta/declarar-indisponibilidade/`,
    createAccount: `${expertsBase}cadastrar-conta`,
    createAccountSuccess: `${expertsBase}cadastrar-conta/sucesso`,
    default: expertsBase,
    forgotPassword: `${expertsBase}esqueceu-sua-senha`,
    home: `${expertsBase}home`,
    login: `${expertsBase}login`,
    myProject: `${expertsBase}projeto`,
    myProjects: `${expertsBase}meus-projetos`,
    profilePersonalData: `${expertsBase}perfil/dados-pessoais`,
    profileProfessionalData: `${expertsBase}perfil/informacoes-profissionais`,
    projectDetails: `${expertsBase}detalhe-project`,
    referExpert: `${expertsBase}indicar-expert`,
    viewReferrals: `${expertsBase}ver-indicacoes`,
    resetPassword: `${expertsBase}redefinir-senha`,
    resetPasswordSuccess: `${expertsBase}redefinir-senha/sucesso`,
    showcaseOpportunities: `${expertsBase}oportunidades`,
    updateAccount: `${expertsBase}atualizar-informacoes`,
    updateAvailability: `${expertsBase}atualizar-disponibilidade`,
  },
}

export default routesNames
