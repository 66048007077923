import React from 'react'
import Hero from 'components/Hero'
import LoggedInTemplate from 'templates/LoggedInTemplate'
import { ReactComponent as IllustraIndicate } from 'assets/img/illustra-indicate.svg'
import {
  Button, Condition, FormError, Grid, Input, Textarea, 
} from 'components'

import shouldShowError from 'utils/helpers/form/shouldShowError'
import shouldDisableButton from 'utils/helpers/form/shouldDisableButton'

import CheckboxGroup from './components/CheckboxGroup'
import SuccessSent from './components/SuccessSent'

import useIndicateExpert from './hooks'
import styles from './styles.module.css'

function IndicateExpert() {
  const {
    domainArea,
    expertSent,
    formik,
    handleToggleSent,
    hasErrors,
  } = useIndicateExpert()

  return (
    <LoggedInTemplate>
      <Hero
        title="Indicação de Experts"
        desc="Se você conhece alguém que pode se interessar
        em ser um expert, informe os dados abaixo."
        image={IllustraIndicate}
      />
      <main className={styles.main}>
        <div className={styles.container}>
          <Condition
            condition={!expertSent}
            elseCondition={<SuccessSent handleToggleSent={handleToggleSent} />}
          >
            <FormError
              hasErrors={hasErrors}
              message="Houve um problema ao tentar indicar um Expert. Tente novamente mais tarde."
            />
            <form onSubmit={formik.handleSubmit} className={styles.form}>
              <Grid column="two">
                <Input
                  name="name"
                  label="Nome completo"
                  placeholder="Nome completo"
                  type="text"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={shouldShowError('name', formik)}
                  required
                />
                <Input
                  name="cell_phone"
                  label="Celular"
                  placeholder="Ex.: +55 (00)00000-0000"
                  type="text"
                  mask="+99 (99)99999-9999"
                  value={formik.values.cell_phone}
                  onChange={formik.handleChange}
                  error={shouldShowError('cell_phone', formik)}
                  required
                />
              </Grid>
              <Grid column="two">
                <Input
                  name="email"
                  label="E-mail"
                  placeholder="seuemail@domain.com"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={shouldShowError('email', formik)}
                  required
                />
                <Input
                  name="linkedin"
                  label="Linkedin"
                  placeholder="https://www.linkedin.com/nome"
                  type="text"
                  value={formik.values.linkedin}
                  onChange={formik.handleChange}
                  error={shouldShowError('linkedin', formik)}
                  required
                />
              </Grid>
              <CheckboxGroup
                label="Área de Domínio"
                name="domain_area"
                description="Selecione 1 ou mais opções."
                options={domainArea}
                onChange={formik.setFieldValue}
                error={shouldShowError('domain_area', formik)}
                required
              />
              <Textarea
                name="comment"
                label="Por que esse profissional deve integrar a nossa comunidade? (opcional)"
                placeholder="Porque essa pessoa..."
                onChange={formik.handleChange}
                error={shouldShowError('comment', formik)}
                value={formik.values.comment}
              />
              <div className={styles.cta}>
                <Button
                  className={styles.btn}
                  text="Enviar"
                  type="submit"
                  disabled={shouldDisableButton(formik)}
                />
              </div>
            </form>
          </Condition>
        </div>
      </main>
    </LoggedInTemplate>
  )
}

export default IndicateExpert
