/* eslint-disable */
import React from 'react'
import { motion } from 'framer-motion'
import { ReactComponent as WelcomeVitrine } from '../../../../assets/img/welcome-vitrine.svg'

import styles from './styles.module.css'

const Vitrine = () => (
  <motion.div
    className={styles.vitrine}
    initial={{
      opacity: 0,
      y: -100,
    }}
    animate={{
      opacity: 1,
      y: 0,
    }}
  >
    <WelcomeVitrine />
  </motion.div>
)

export default Vitrine
