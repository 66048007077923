import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Condition from 'components/Condition'
import styles from './styles.module.css'

function FormBox({ children, desc, title }) {
  return (
    <div className={styles.form_box}>
      <h1 className={styles.title}>{title}</h1>
      <Condition condition={!!desc}>
        <p className={styles.desc}>{desc}</p>
      </Condition>
      {children}
    </div>
  )
}

FormBox.propTypes = {
  children: PropTypes.node.isRequired,
  desc: PropTypes.string,
  title: PropTypes.string.isRequired,
}

FormBox.defaultProps = {
  desc: null,
}

export default memo(FormBox)
