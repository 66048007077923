import React from 'react'
import LogoIcon from 'assets/img/logo.svg'
import styles from './styles.module.css'

function HeaderOut() {
  return (
    <nav className={styles.header_out}>
      <img
        className={styles.header_logo}
        src={LogoIcon}
        alt="Logo da Cognitivo escrito cognitivo"
      />
      <div className={styles.header_text}>Plataforma experts</div>
    </nav>
  )
}

export default HeaderOut
