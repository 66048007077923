import React, { lazy, useEffect, useState } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'

import DefaultRoute from './types/DefaultRoute'
import ProtectedRoute from './types/ProtectedRoute'
import { Login } from '../pages'
// import { NotFound } from '../containers'
import { Maintenance } from '../components'
import {
  getAuthToken,
  removeAuthToken,
  setIsAuth,
  setAuthToken,
  setAuthSessionCreated,
} from '../utils/local-storage'
import killSession from '../utils/helpers/killSession'
import verifyLastLogin from './verifyLastLogin'
import * as services from '../services'
import routesNames from './routesNames'
import { useUsername } from '../context/username'
import { useLoading } from '../context/loading'
import ExpertsMyProject from '../pages/Experts/MyProject'

const CustomersAgreements = lazy(() => import('../pages/Customers/Agreements'))
const CustomersHome = lazy(() => import('../pages/Customers/Home'))
const CustomersSelectProject = lazy(() => import('../pages/Customers/SelectProject'))
const CustomersTasks = lazy(() => import('../pages/Customers/Tasks'))
const ExpertsAccount = lazy(() => import('../pages/Experts/Account'))
const ExpertsAccountActivate = lazy(() => import('../pages/Experts/AccountActivate'))
const ExpertsAccountChangePassword = lazy(() => import('../pages/Experts/AccountChangePassword'))
const ExpertsAccountDeactivate = lazy(() => import('../pages/Experts/AccountDeactivate'))
const ExpertsCreateAccount = lazy(() => import('../pages/Experts/CreateAccount'))
const ExpertsCreateAccountSuccess = lazy(() => import('../pages/Experts/CreateAccountSuccess'))
const ExpertsForgotPassword = lazy(() => import('../pages/Experts/ForgotPassword'))
const ExpertsHome = lazy(() => import('../pages/Experts/Home'))
const ExpertsIndicateExpert = lazy(() => import('../pages/Experts/IndicateExpert'))
// const ExpertsMyProject = lazy(() => import('../pages/Experts/MyProject'))
const ExpertsMyProjects = lazy(() => import('../pages/Experts/MyProjects'))
const ExpertsViewReferrals = lazy(() => import('../pages/Experts/ViewReferrals'))
const ExpertsOpportunities = lazy(() => import('../pages/Experts/Opportunities'))
const ExpertsProfilePersonalData = lazy(() => import('../pages/Experts/ProfilePersonalData'))
const ExpertsProfileProfessionalData = lazy(() => import('../pages/Experts/ProfileProfessionalData'))
const ExpertsResetPassword = lazy(() => import('../pages/Experts/ResetPassword'))
const ExpertsResetPasswordSuccess = lazy(() => import('../pages/Experts/ResetPasswordSuccess'))
const ExpertsUpdateAccount = lazy(() => import('../pages/Experts/UpdateAccount'))
const ExpertsUpdateAvailability = lazy(() => import('../pages/Experts/UpdateAvailability'))
const ExpertsProjectDetails = lazy(() => import('../pages/Experts/ProjectDetails'))

function refreshTheToken() {
  const intervalTime = (process.env.REACT_APP_API_TOKEN_EXPIRES_IN / 5) * 1000

  setInterval(async () => {
    const token = getAuthToken()

    if (token) {
      try {
        const response = await services.auth.refresh_token(token)
        if (response.access_token) {
          removeAuthToken()
          setIsAuth(true)
          setAuthToken(response.access_token)
          setAuthSessionCreated(new Date())
        } else {
          killSession()
        }
      } catch (err) {
        killSession()
      }
    }
  }, intervalTime)
}

function getRouteBaseName(routeUrl = '') {
  const routeBaseName = routeUrl.split('/')[1]

  return routeBaseName
}

const getUsername = async (
  userDispatch,
  loadingDispatch,
  setIsLoading,
  pathname,
) => {
  const token = getAuthToken()
  const type = getRouteBaseName(pathname)

  if (token) {
    loadingDispatch({
      payload: true,
      type: 'set',
    })

    const userResult = await services[type].me(token)

    if (userResult) {
      userDispatch({
        payload: {
          ...userResult,
        },
        type: 'set',
      })

      loadingDispatch({
        payload: false,
        type: 'set',
      })

      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  } else {
    setIsLoading(false)
  }
}

function Root() {
  const [isLoading, setIsLoading] = useState(true)
  const { dispatch: userDispatch } = useUsername()
  const { dispatch: loadingDispatch } = useLoading()
  const history = useHistory()

  useEffect(() => {
    const makeRequestRefreshToken = async () => {
      await refreshTheToken()
    }
    const makeRequestsForValidSession = async (pathname) => {
      getUsername(userDispatch, loadingDispatch, setIsLoading, pathname)
    }

    const authSessionCreated = verifyLastLogin()

    if (authSessionCreated && history.location.pathname !== '/') {
      makeRequestsForValidSession(history.location.pathname)
    } else {
      setIsLoading(false)
    }

    makeRequestRefreshToken()
  }, [loadingDispatch, userDispatch, history.location.pathname])

  function returnExpertsLoginComponent(props) {
    return <Login {...props} type="experts" />
  }

  function returnCustomersLoginComponent(props) {
    return <Login {...props} type="customers" />
  }

  return (
    !isLoading && (
      <Switch>
        <ProtectedRoute
          path={routesNames.experts.home}
          component={ExpertsHome}
          type="experts"
        />
        <ProtectedRoute
          path={routesNames.experts.updateAccount}
          component={ExpertsUpdateAccount}
          type="experts"
        />
        <ProtectedRoute
          path={routesNames.experts.updateAvailability}
          component={ExpertsUpdateAvailability}
          type="experts"
        />
        <ProtectedRoute
          path={routesNames.experts.showcaseOpportunities}
          component={ExpertsOpportunities}
          type="experts"
        />
        <ProtectedRoute
          path={routesNames.experts.profilePersonalData}
          component={ExpertsProfilePersonalData}
          type="experts"
        />
        <ProtectedRoute
          path={routesNames.experts.profileProfessionalData}
          component={ExpertsProfileProfessionalData}
          type="experts"
        />
        <ProtectedRoute
          path={routesNames.experts.accountChangePassword}
          component={ExpertsAccountChangePassword}
          type="experts"
        />
        <ProtectedRoute
          path={routesNames.experts.accountDeactivate}
          component={ExpertsAccountDeactivate}
          type="experts"
        />
        <ProtectedRoute
          path={routesNames.experts.accountActivate}
          component={ExpertsAccountActivate}
          type="experts"
        />
        <ProtectedRoute
          path={routesNames.experts.account}
          component={ExpertsAccount}
          type="experts"
        />
        <ProtectedRoute
          path={routesNames.experts.referExpert}
          component={ExpertsIndicateExpert}
          type="experts"
        />
        <ProtectedRoute
          path={`${routesNames.experts.myProject}/:id`}
          component={ExpertsMyProject}
          type="experts"
        />
        <ProtectedRoute
          path={routesNames.experts.myProjects}
          component={ExpertsMyProjects}
          type="experts"
        />
        <ProtectedRoute
          path={routesNames.experts.viewReferrals}
          component={ExpertsViewReferrals}
          type="experts"
        />
        <ProtectedRoute
          path={`${routesNames.experts.projectDetails}/:id`}
          component={ExpertsProjectDetails}
          type="experts"
        />
        <Route
          path={routesNames.experts.login}
          render={returnExpertsLoginComponent}
        />
        <Route
          path={routesNames.experts.createAccount}
          component={ExpertsCreateAccount}
          exact
        />
        <Route
          path={routesNames.experts.createAccountSuccess}
          component={ExpertsCreateAccountSuccess}
        />

        <Route
          path={routesNames.experts.forgotPassword}
          component={ExpertsForgotPassword}
        />
        <Route
          path={routesNames.experts.resetPasswordSuccess}
          component={ExpertsResetPasswordSuccess}
        />
        <Route
          path={routesNames.experts.resetPassword}
          component={ExpertsResetPassword}
        />

        <Route
          path={routesNames.customers.login}
          render={returnCustomersLoginComponent}
        />
        <ProtectedRoute
          path={routesNames.customers.home}
          component={CustomersHome}
          type="customers"
        />
        <ProtectedRoute
          path={routesNames.customers.selectProject}
          component={CustomersSelectProject}
          type="customers"
        />
        <ProtectedRoute
          path={routesNames.customers.agreements}
          component={CustomersAgreements}
          type="customers"
        />
        <ProtectedRoute
          path={routesNames.customers.tasks}
          component={CustomersTasks}
          type="customers"
        />
        <DefaultRoute path={routesNames.experts.default} type="experts" />
        <DefaultRoute path={routesNames.customers.default} type="customers" />
        <Route path="/manutencao">
          <Maintenance />
        </Route>
        <DefaultRoute path="*" type="experts" />
      </Switch>
    )
  )
}

// Para Erro 404 use o seguinte formato:
// <Route path="*">
//   <NotFound />
// </Route>

export default Root
