/* eslint-disable */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as ExitIcon } from 'assets/img/exit.svg'
import styles from './styles.module.css'

function Modal({ children, onHide, show }) {
  function blockModalFromClose(event) {
    event.stopPropagation()
  }

  return show ? (
    <div className={styles.modal} onClick={onHide}>
      <div className={styles.modal_wrapper} onClick={blockModalFromClose}>
        <button
          type="button"
          className={styles.modal_exit_button}
          onClick={onHide}
        >
          <ExitIcon />
        </button>
        <div className={styles.modal_content}>{children}</div>
      </div>
    </div>
  ) : null
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

export default Modal
