const formatOptionsFromRequest = (results = []) => {
  const formatedResult = results.map((item) => ({
    label: item.title,
    value: item.id,
  }))

  return formatedResult
}

export default formatOptionsFromRequest
